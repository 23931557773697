import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Accordion, Alert, Button } from 'react-bootstrap';
import { SubmissionError } from 'redux-form';
import api from '../helpers/apiClient';
import Loader from '../components/Loader';
import _ from 'lodash'
import Moment from 'moment';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const orderNamePlaceholder = window.location.hostname === 'seller.giftr.my' ? 'GTR12345' : 'GSR1234'
const postDest = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

class OrderTracking extends Component {

    constructor(props) {
        super(props);
        let email = ''
        let orderName = ''
        let loading = false;
        let orderNotFound = false;
        let emailInvalid = false;
        let orderNumberInvalid = false;

        this.state = {
            email,
            orderName,
            loading,
            orderNotFound,
            emailInvalid,
            orderNumberInvalid,
        };
    }

    componentWillMount(){
    }

    componentDidMount() {
        window.addEventListener("message", e => {
            if(e.origin !== postDest){
                return
            }
            if(e.data && e.data.email) {
                this.setState({ email: e.data.email });
            }
            if(e.data && e.data.id) {
                this.setState({ orderName: 'GTR' + e.data.id });
            }
        })
    }

    handleTrack = () => {

        const { email, orderName, parentMessageEvent } = this.state;

        if(!email){
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }

        if(!orderName){
            this.setState({ orderNumberInvalid : true });
        } else {
            this.setState({ orderNumberInvalid: false });
        }

        if (!email || !orderName) {
            return;
        }

        // this.setState({ loading: true, orderNotFound: false, result: undefined }, () => {sendResizeToParentWindow(parentMessageEvent)});

        this.setState({ loading: true, orderNotFound: false, result: undefined });

        return api('/track_order', {
            method: 'POST',
            body: JSON.stringify({
                email,
                orderName
            })
        }).then(result => {

            this.setState({ loading: false });

            if(result){
                this.setState({ result })

                let lalamoveDeliveries = []
                _.each(Object.values(result), line_items => {
                    _.each(line_items, i => {
                        if (i.delivery && _.includes(i.tracking_url, 'lalamove') && !_.includes(lalamoveDeliveries, i.tracking_url)) {
                            lalamoveDeliveries.push(i.tracking_url)
                        }
                    })
                })
                if (lalamoveDeliveries.length > 0) {
                    window.parent.postMessage({ lalamove: true, lalamoveDeliveries }, postDest);
                }
            }
            else{
                this.setState({orderNotFound: true})
            }

            // if(result){
            //     this.setState({ result }, () => {
            //         sendResizeToParentWindow(parentMessageEvent);
            //     });
            // } else {
            //     this.setState({orderNotFound: true}, () => {sendResizeToParentWindow(parentMessageEvent)});
            // }
        }).catch(error => {
            console.log(error)
        })
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    handleOrderNumberChange = (event) => {
        this.setState({ orderName: event.target.value });
    }

    trackingIcon = (status_type) => {
        if (status_type === "delivered") {
            return (
                <FontAwesomeIcon icon={solid('check')} size="lg" />
            )
        }
    }

    handleVendorContact = (item) => {
        window.parent.postMessage({ vendor_contact: true, item }, postDest);
    }

    renderLineItem(items, fulfilled) {

        return _.map(items, (item, i) => {
            if (item.tracking_events) {
                const titleStyle = {
                    "fontWeight": "300",
                    "fontFamily": "Varela Round, Arial, sans-serif",
                }

                return (
                <div key={i} className="track-order-line-item pb-3">
                    <img src={item.image} alt={item.title} height="100px"/>
                    <div className="text-primary"><strong>{item.title} - {item.variant_title} x {item.quantity}</strong></div>
                    {item.vendor && <div className="text-secondary small">Fulfilled by: {item.vendor}</div>}
                    <div style={{color: '#81d8d0', cursor: 'pointer'}} className="small" onClick={() => this.handleVendorContact(item)}>
                        <i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize: '22px', float: 'left', paddingRight: '3px'}}></i>
                        <span>Ask Vendor a Question</span>
                    </div>
                    <hr className="my-2" style={{color:'#b2b2b2'}} />
                    {item.ready_to_ship && !fulfilled && item.delivery && item.is_self_pickup && <div>Self Pick-up Date: {item.delivery}</div> }
                    {item.delivery && !item.is_self_pickup && <div><div>Delivery Date: {item.delivery}</div><div>Delivery will commence from 10am to 5pm. (Unless specific time slot selected)</div></div>}
                    {item.delivery && item.is_self_pickup && <div>Self Pick-up Date: {item.delivery}</div>}
                    {item.est_delivery && <div>Est. Delivery Date: {item.est_delivery}</div>}
                    {item.pickup_address && <div>Pick-up Info: {item.pickup_address}</div>}
                    {item.delivery && item.fulfilled_at && <div>{item.is_self_pickup ? 'Collected on' : 'Delivered on'}: {Moment(item.fulfilled_at).format("dddd, MMMM Do YYYY")}</div>}
                    {!item.delivery && item.fulfilled_at && <div>Shipped on: {Moment(item.fulfilled_at).format("dddd, MMMM Do YYYY")}</div>}
                    {item.tracking_url && <div>Tracking link: <a href={item.tracking_url} target="_blank">{item.tracking_url}</a></div>}
                    {item.tracking_number && <div>Tracking number: {item.tracking_number}</div>}
                    <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow" defaultActiveKey="0">
                        <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
                            <Accordion.Header><span>Tracking Events</span></Accordion.Header>
                            <Accordion.Body>
                                <Timeline>
                                    {
                                        item.tracking_events.map((event, i) => {
                                            return (
                                                <TimelineEvent
                                                    title={event.location}
                                                    key={`${i}_${event.date_time}`}
                                                    createdAt={event.date_time}
                                                    icon={this.trackingIcon(event.status_type)}
                                                    iconColor="#81d8d0"
                                                    bubbleStyle={{background: '#fff', borderColor: '#80d7cf'}}
                                                    titleStyle={titleStyle}
                                                >
                                                    <div style={{whiteSpace: "pre-line"}}>
                                                        {event.status}
                                                    </div>
                                                </TimelineEvent>
                                            )
                                        })
                                    }
                                </Timeline>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                )
            }

            return (
                <div key={i} className="track-order-line-item pb-3">
                    <img src={item.image} alt={item.title} height="100px"/>
                    <div className="text-primary"><strong>{item.title} - {item.variant_title} x {item.quantity}</strong></div>
                    {item.vendor && <div className="text-secondary small">Fulfilled by: {item.vendor}</div>}
                    <div style={{color: '#81d8d0', cursor: 'pointer'}} className="small" onClick={() => this.handleVendorContact(item)}>
                        <i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize: '22px', float: 'left', paddingRight: '3px'}}></i>
                        <span>Ask Vendor a Question</span>
                    </div>
                    <hr className="my-2" style={{color:'#b2b2b2'}} />
                    {!item.ready_to_ship && !fulfilled && !item.delivery && <div>Status: Your order is being processed. 🎁 </div> }
                    {item.ready_to_ship && !fulfilled && item.delivery && Moment().format("DD/MM/YYYY") !== item.delivery && !item.is_self_pickup && <div>Yay! Order accepted for delivery on {item.delivery}</div> }
                    {!item.ready_to_ship && !fulfilled && item.delivery && Moment().format("DD/MM/YYYY") !== item.delivery && <div>Status: Your order is being processed. 🎁 </div> }
                    {item.ready_to_ship && !fulfilled && item.delivery && Moment().format("DD/MM/YYYY") === item.delivery && Moment().hour() < 10 && !item.is_self_pickup && <div>Yay! Order accepted for delivery on {item.delivery}</div> }
                    {!item.ready_to_ship && !fulfilled && item.delivery && Moment().format("DD/MM/YYYY") === item.delivery && Moment().hour() < 10 && !item.is_self_pickup && <div>Status: Your order is being processed. 🎁 </div> }
                    {!fulfilled && item.delivery && Moment().format("DD/MM/YYYY") === item.delivery && Moment().hour() >= 10 && !item.is_self_pickup && <div>Status: Your delivery order will commence by today. 🤗</div> }
                    {item.ready_to_ship && !fulfilled && item.delivery && item.is_self_pickup && <div>Self Pick-up Date: {item.delivery}</div> }
                    {item.ready_to_ship && !fulfilled && item.est_delivery && <div>Status: Yay! Order accepted & tracking number will be sent via email once shipped.</div> }

                    {fulfilled && item.delivery && !item.is_self_pickup && <div>Status: ✅ Order has been delivered successfully.</div> }
                    {fulfilled && item.est_delivery && <div>Status: Woohoo! Your order has been shipped out. 🚚</div> }

                    {item.delivery && !item.is_self_pickup && <div><div>Delivery Date: {item.delivery}</div><div>Delivery will commence from 10am to 5pm. (Unless specific time slot selected)</div></div>}
                    {item.delivery && item.is_self_pickup && <div>Self Pick-up Date: {item.delivery}</div>}
                    {item.est_delivery && <div>Est. Delivery Date: {item.est_delivery}</div>}
                    {item.pickup_address && <div>Pick-up Info: {item.pickup_address}</div>}
                    {item.delivery && item.fulfilled_at && <div>{item.is_self_pickup ? 'Collected on' : 'Delivered on'}: {Moment(item.fulfilled_at).format("dddd, MMMM Do YYYY")}</div>}
                    {!item.delivery && item.fulfilled_at && <div>Shipped on: {Moment(item.fulfilled_at).format("dddd, MMMM Do YYYY")}</div>}
                    {item.tracking_url && <div>Tracking link: <a href={item.tracking_url} target="_blank">{item.tracking_url}</a></div>}
                    {item.tracking_company && <div>Delivered via: {item.tracking_company}</div>}
                    {item.tracking_number && <div>Tracking number: {item.tracking_number}</div>}
                    {item.pod && <div>POD: <a href={item.pod} target="_blank">{item.pod}</a></div>}
                </div>
            )
        })
    }

    handleEnter = e => {
        if(e.key === "Enter"){
            this.handleTrack();
        }
    }

    render() {
        const { error } = this.props
        const { loading, result, orderNotFound, emailInvalid, orderNumberInvalid, email, orderName } = this.state;

        return (
            <div className="container order-tracking label-strong" >
                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            {
                                error &&
                                <Alert variant="danger">
                                    <div>{error}</div>
                                </Alert>
                            }
                            <h1>Order Tracking</h1>
                            <div className="d-grid gap-3 my-4">
                                <div>
                                    <label className="sub-header">Email</label>
                                    <input
                                        className="form-control"
                                        placeholder="example@gmail.com"
                                        type="email"
                                        onChange={this.handleEmailChange}
                                        onFocus={(e) => e.target.select()}
                                        value={email}
                                        onKeyPress={this.handleEnter}
                                    />
                                    { emailInvalid && <div className="text-danger">*Please enter email</div> }
                                </div>
                                <div>
                                    <label className="sub-header">Order Number</label>
                                    <input
                                        className="form-control"
                                        required
                                        placeholder={orderNamePlaceholder}
                                        type="text"
                                        value={orderName}
                                        onChange={this.handleOrderNumberChange}
                                        onFocus={(e) => e.target.select()}
                                        onKeyPress={this.handleEnter}
                                    />
                                    { orderNumberInvalid && <div className="text-danger">*Please enter order number</div> }
                                </div>
                                <div>
                                    <Button className="btn-info w-100 shadow-sm" onClick={this.handleTrack}>Track Order</Button>
                                </div>
                            </div>
                            {
                                loading &&
                                <Loader />
                            }
                            <hr style={{color:'#b2b2b2'}}/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {
                            orderNotFound &&
                            <div className="text-danger">
                                <p>Order not found</p>
                            </div>
                        }
                        {
                            !loading && result &&
                            <div>
                                {result.unfulfilled_items.length > 0 && <h4>To Ship:</h4>}
                                {this.renderLineItem(result.unfulfilled_items, false)}
                                {result.fulfilled_items.length > 0 && <h4>Shipped:</h4>}
                                {this.renderLineItem(result.fulfilled_items, true)}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state, props) => {

    return {

    };
})(OrderTracking);
