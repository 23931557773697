import _ from 'lodash';
import moment from 'moment';

export async function generateAttr(item, dayOptions, closed, predefinedTags, match){
  //split then trim tags
  let tagsSplit = item.tags.split(',')
  tagsSplit = _.map(tagsSplit, tag => _.trim(tag))
  
  // all predefined tags
  let locationTags = predefinedTags['Location']
  let occasionTags = predefinedTags['Occasions']
  let forWhoTags = predefinedTags['For Who']
  let flowerTypeTags = predefinedTags['Flower Type']

  // find handled tags and set state values
  const newBia = _.find(tagsSplit, tag => /^bia\-\d{1}\-\w{1,2}$/ig.test(tag))
  const newUbia = _.find(tagsSplit, tag => /^ubia\-\d{1}\-\w{1,2}$/ig.test(tag))
  const same_day_tag = _.find(tagsSplit, tag => /^same-day-\d{1,2}[ap]m$/ig.test(tag))
  const ubo = _.filter(tagsSplit, tag => /^ubo-\d{8}$/ig.test(tag))
  const bo = _.filter(tagsSplit, tag => /^bo-\d{8}$/ig.test(tag))
  const self = _.some(tagsSplit, tag => /^self/i.test(tag))
  const block_em = _.some(tagsSplit, tag => /^block-em$/i.test(tag))
  const ordered_fields = _.find(tagsSplit, tag => /^ordered-fields/i.test(tag))
  const closedTags = _.filter(tagsSplit, tag => /^closed-\d{1}/i.test(tag))
  const halalStatus = _.find(tagsSplit, tag => {
    if(tag){
      return _.some(['alcohol-pork-free', 'logo_halal', 'non-halal'], hal => tagReTest(tag, hal))
    }
    return false
  })
  const shippingStart = _.find(tagsSplit, tag => /^ss-\d{8}$/i.test(tag))

  const locations = _.filter(locationTags, loc => _.some(tagsSplit, tag => {
    return tagReTest(loc.tag, tag)
  }))
  const occasions = _.filter(occasionTags, occ => _.some(tagsSplit, tag => {
    return tagReTest(occ.tag, tag)
  }))
  const forWho = _.filter(forWhoTags, fw => _.some(tagsSplit, tag => {
    return tagReTest(fw.tag, tag)
  }))
  const flowerTypes = _.filter(flowerTypeTags, ft => _.some(tagsSplit, tag => {
    return tagReTest(ft.tag, tag)
  }))

  let conditionalTags = _.filter(tagsSplit, tag => /^cond/i.test(tag))
  let vConditionalTags = _.filter(tagsSplit, tag => /^vcond/i.test(tag))

  let customizeFields = [], conditionalFields = [], vConditionalFields = []
  let fieldsTags = _.filter(tagsSplit, tag => /^textfield|^textarea|^dropdown|^imageupload|^multiupload|^timeslot|^checkbox|^audioupload|^logoupload/i.test(tag))
  _.each(fieldsTags, tag => {
    let fieldSplit = tag.split('_')
    let tagSplit = fieldSplit[0].split('#')

    let tagType = _.lowerCase(tagSplit[0])

    let field = {}
    switch(tagType){
      case 'textfield':
        field = {
          tagType: 'textfield',
          title: 'Text Field',
          primaryTitle: fieldSplit[1],
          maxChar: fieldSplit[2],
          secondTitle: fieldSplit[3] || '',
          required: _.lowerCase(fieldSplit[4]) === 'required',
          seq: tagSplit[1],
        };
        break;

      case 'textarea':
        field = {
          tagType: 'textarea',
          title: 'Text Area',
          primaryTitle: fieldSplit[1],
          maxChar: fieldSplit[2],
          secondTitle: fieldSplit[3] || '',
          seq: tagSplit[1],
        };
        break;

      case 'dropdown':
        let misc, secondTitle, required;
        if (/^\@/.test(fieldSplit[fieldSplit.length-1])) {
          misc = fieldSplit[fieldSplit.length-1]
          fieldSplit = fieldSplit.slice(0, -1);
        }

        if (misc) {
          const miscSplit = misc.split('@')
          secondTitle = miscSplit[1]
          required = miscSplit[2]
        }
        field = {
          tagType: 'dropdown',
          title: 'Dropdown',
          primaryTitle: fieldSplit[1],
          options: _.map(fieldSplit.slice(2), option => {
            return {
              label: option,
              value: option
            }
          }),
          secondTitle: secondTitle || '',
          required: required || '',
          seq: tagSplit[1],
        };
        break;

      case 'imageupload':
        field = {
          tagType: 'imageupload',
          title: 'Image Upload',
          primaryTitle: fieldSplit[1],
          required: _.lowerCase(fieldSplit[2]) === 'required',
          seq: tagSplit[1],
        };
        break;

      case 'multiupload':
        field = {
          tagType: 'multiupload',
          title: 'Multiple Images Upload',
          primaryTitle: fieldSplit[1],
          limit: fieldSplit[2],
          required: _.lowerCase(fieldSplit[3]) === 'required',
          seq: tagSplit[1],
        }
        break;

      case 'timeslot':
        field = {
          tagType: 'timeslot',
          title: 'Preferred Time Slot',
          primaryTitle: fieldSplit[1],
          options: _.map(fieldSplit.slice(2), option => {
            return {
              label: option,
              value: option
            }
          }),
          seq: tagSplit[1],
        }
        break;

      case 'checkbox':
        field = {
          tagType: 'checkbox',
          title: 'Checkbox',
          primaryTitle: fieldSplit[1],
          limit: fieldSplit[2],
          options: _.map(fieldSplit.slice(3), option => {
            return {
              label: option,
              value: option
            }
          }),
          seq: tagSplit[1],
        }
        break;

      case 'audioupload':
        field = {
          tagType: 'audioupload',
          title: 'Audio Upload',
          primaryTitle: fieldSplit[1],
          seq: tagSplit[1],
        }
        break;

      case 'logoupload':
        field = {
          tagType: 'logoupload',
          title: 'Corporate Logo Upload',
          primaryTitle: fieldSplit[1],
        };
        break;

      default:
        break;
    }

    let isConditional = false
    _.each(conditionalTags, conditional => {
      let condSplit = conditional.split('_')
      if (fieldSplit[1] == condSplit[1]) {
        isConditional = true
      }
    })

    let vConditional = _.find(vConditionalTags, vConditional => {
      let vCondSplit = vConditional.split('_')
      return fieldSplit[1] == vCondSplit[1]
    })

    if (isConditional) {
      conditionalFields.push(field)
    } else if (vConditional) {
      vConditionalFields.push(field)
    } else {
      customizeFields.push(field)
    }
  })

  let excludeDaysSelected = []
  if(newBia){
    switch (true){
      case /s{1}/i.test(newBia):
        excludeDaysSelected.push(_.find(dayOptions, day => day.value == 'sun'))
        break;
      case /n{1}/i.test(newBia):
        excludeDaysSelected.push(..._.filter(dayOptions, day => day.value == 'sun' || day.value == 'sat'))
        break;
      default:
        break;
    }
  }

  _.each(closedTags, closedDay => {
    const day = _.find(Object.entries(closed), entry => entry[1] === closedDay.toUpperCase())

    if (!day) return;

    excludeDaysSelected.push(_.find(dayOptions, dayOption => dayOption.value === day[0]));
  })

  const delivery_days = _.filter([...dayOptions], day => {
    return !_.some(excludeDaysSelected, exclude => exclude.value === day.value)
  })

  let locationsSelected = _.map(locations, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let occasionsSelected = _.map(occasions, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let forWhoSelected = _.map(forWho, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let flowerTypesSelected = _.map(flowerTypes, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let not_halal
  let have_halal_cert
  let halal_cert = ''
  if(halalStatus){
    not_halal = halalStatus == 'non-halal' ? 'yes' : 'no';
    have_halal_cert = (item.halal_cert || _.find(tagsSplit, tag => tag === 'have_halal_cert')) ? 'yes' : 'no';

    let certUrl = item.body_html && item.body_html.match(/https:.*halalcert.*\.\w{3}/gi)
    halal_cert = (item.halal_cert || (certUrl && certUrl[0])) || '';
  }

  let initialMetafieldAddOns = [], initialMetafieldInputs = []
  if(item.metafields){
    _.each(item.metafields, metafield => {
      let { value, id, key, namespace } = metafield

      let fieldSplit = value.split('_')
      let tagSplit = fieldSplit[0].split('#')

      let tagType = _.lowerCase(tagSplit[0])

      let field = {}
      switch(tagType){
        case 'ddaddon':
          field = {
            tagType: 'ddaddon',
            id,
            key,
            namespace,
            title: 'Customised Add-On (Dropdown)',
            primaryTitle: fieldSplit[1],
            options: _.map(fieldSplit.slice(2), option => {
              return {
                label: option,
                value: option
              }
            }),
            seq: tagSplit[1],
          };
          break;

        case 'cbaddon':
          field = {
            tagType: 'cbaddon',
            id,
            key,
            namespace,
            title: 'Customised Add-On (Checkbox)',
            primaryTitle: fieldSplit[1],
            limit: fieldSplit[2],
            options: _.map(fieldSplit.slice(3), option => {
              return {
                label: option,
                value: option
              }
            }),
            seq: tagSplit[1],
          };
          break;

        case 'dropdown':
          let misc, secondTitle, required;
          if (/^\@/.test(fieldSplit[fieldSplit.length-1])) {
            misc = fieldSplit[fieldSplit.length-1]
            fieldSplit = fieldSplit.slice(0, -1);
          }

          if (misc) {
            const miscSplit = misc.split('@')
            secondTitle = miscSplit[1]
            required = miscSplit[2]
          }
          field = {
            tagType: 'dropdown',
            id,
            key,
            namespace,
            title: 'Dropdown',
            primaryTitle: fieldSplit[1],
            options: _.map(fieldSplit.slice(2), option => {
              return {
                label: option,
                value: option
              }
            }),
            secondTitle: secondTitle || '',
            required: required || '',
            seq: tagSplit[1],
          };
          break;

        default:
          break;
      }

      if (namespace === 'add_ons') {
        initialMetafieldAddOns.push(field)
      } else if (namespace === 'inputs') {
        initialMetafieldInputs.push(field)
      }

      let isConditional = false
      _.each(conditionalTags, conditional => {
        let condSplit = conditional.split('_')
        if (fieldSplit[1] == condSplit[1]) {
          isConditional = true
        }
      })

      let vConditional = _.find(vConditionalTags, vConditional => {
        let vCondSplit = vConditional.split('_')
        return fieldSplit[1] == vCondSplit[1]
      })

      if (isConditional) {
        conditionalFields.push(field)
      } else if (vConditional) {
        vConditionalFields.push(field)
      } else {
        customizeFields.push(field)
      }
    })
  }

  _.each(conditionalTags, tag => {
    let condSplit = tag.split('_')

    let foundConditionalField = _.find(conditionalFields, field => {
      return field.primaryTitle == condSplit[1]
    })

    let foundField = _.find(customizeFields, field => {
      return field.primaryTitle == condSplit[2]
    })


    if (foundField && foundConditionalField) {
      foundConditionalField.valueOptions = foundField.options
      foundConditionalField.selected = condSplit[3].split('#').map(option => {
        return {
          label: option,
          value: option
        }
      })
      if (foundField.conditionalFields) {
        foundField.conditionalFields.push(foundConditionalField)
      } else {
        foundField.conditionalFields = [foundConditionalField]
      }
    }
  })

  _.each(vConditionalTags, tag => {
    let vCondSplit = tag.split('_')

    let foundConditionalField = _.find(vConditionalFields, field => {
      return field.primaryTitle == vCondSplit[1]
    })

    let foundOption = _.find(item.options, option => option.position - 1 == vCondSplit[2])

    if (foundOption && foundConditionalField) {
      foundConditionalField.valueOptions = foundOption.values.map(val => {
        return {
          value: val,
          label: val
        }
      })
      foundConditionalField.selected = vCondSplit[3].split('#').map(option => {
        return {
          label: option,
          value: option
        }
      })
      if (foundOption.conditionalFields) {
        foundOption.conditionalFields.push(foundConditionalField)
      } else {
        foundOption.conditionalFields = [foundConditionalField]
      }
    }
  })

  let delivery_type = 'courier'

  if(newBia){
    delivery_type = 'd2d'
  }

  if(newUbia){
    delivery_type = 'seasonal'
  }

  let uboDates = []
  _.each(ubo, tag => {
    const date = tag.split('-')[1]
    uboDates.push(moment(date, 'DDMMYYYY'))
  })

  let boDates = []
  _.each(bo, tag => {
    const date = tag.split('-')[1]
    boDates.push(moment(date, 'DDMMYYYY'))
  })

  let cutOffTime = same_day_tag ? same_day_tag.toLowerCase().replace('same-day-', '') : '12pm';

  // return state values
  return {
    item,
    attributes: {
      bia: {
        ...(newBia && {
          days_in_advance: newBia.split('-')[1],
          exclude_days: _.upperCase(newBia.split('-')[2]),
        }),
      },
      ubia: {
        ...(newUbia && {
          days_in_advance: newUbia.split('-')[1],
          exclude_days: _.upperCase(newUbia.split('-')[2]),
        }),
      },
      cutOffTime,
      self,
      block_em,
      delivery_days,
      delivery_type: _.includes(match.path, 'create') ? '' : delivery_type,
      uboDates,
      boDates: _.uniq(boDates),
      customizeFields,
      closedTags,
      locationsSelected,
      occasionsSelected,
      forWhoSelected,
      flowerTypesSelected,
      not_halal,
      have_halal_cert,
      halal_cert,
      ordered_fields: !!ordered_fields,
      pre_order: !!shippingStart,
      shippingStart: shippingStart ? moment(shippingStart.split('-')[1], 'DDMMYYYY') : null
    },
    initialMetafieldAddOns,
    initialMetafieldInputs,
  }
}

export function generateAttrBulkEdit(tags, predefinedTags){
  let tagsSplit = tags.split(', ')

  // all predefined tags
  let locationTags = predefinedTags['Location']
  let occasionTags = predefinedTags['Occasions']
  let forWhoTags = predefinedTags['For Who']
  let flowerTypeTags = predefinedTags['Flower Type']

  // find handled tags and set state values
  const newBia = _.find(tagsSplit, tag => /^bia\-\d{1}\-\w{1,2}$/ig.test(tag))
  const newUbia = _.find(tagsSplit, tag => /^ubia\-\d{1}\-\w{1,2}$/ig.test(tag))
  const same_day_tag = _.find(tagsSplit, tag => /^same-day-\d{1,2}[ap]m$/ig.test(tag))
  const ubo = _.filter(tagsSplit, tag => /^ubo-\d{8}$/ig.test(tag))
  const bo = _.filter(tagsSplit, tag => /^bo-\d{8}$/ig.test(tag))
  const self = _.some(tagsSplit, tag => /^self/i.test(tag))
  const block_em = _.some(tagsSplit, tag => /^block-em$/i.test(tag))
  const ordered_fields = _.find(tagsSplit, tag => /^ordered-fields/i.test(tag))
  const halalStatus = _.find(tagsSplit, tag => {
    if(tag){
      return _.some(['alcohol-pork-free', 'logo_halal', 'non-halal'], hal => tagReTest(tag, hal))
    }
    return false
  })

  const locations = _.filter(locationTags, loc => _.some(tagsSplit, tag => {
    return tagReTest(loc.tag, tag)
  }))
  const occasions = _.filter(occasionTags, occ => _.some(tagsSplit, tag => {
    return tagReTest(occ.tag, tag)
  }))
  const forWho = _.filter(forWhoTags, fw => _.some(tagsSplit, tag => {
    return tagReTest(fw.tag, tag)
  }))
  const flowerTypes = _.filter(flowerTypeTags, ft => _.some(tagsSplit, tag => {
    return tagReTest(ft.tag, tag)
  }))

  let locationsSelected = _.map(locations, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let occasionsSelected = _.map(occasions, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let forWhoSelected = _.map(forWho, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let flowerTypesSelected = _.map(flowerTypes, tag => {
    return {
      label: tag.desc,
      value: tag.tag
    }
  })

  let not_halal
  let have_halal_cert
  let halal_cert = ''
  if(halalStatus){
    not_halal = halalStatus == 'non-halal' ? 'yes' : 'no';
  }

  let delivery_type = 'courier'

  if(newBia){
    delivery_type = 'd2d'
  }

  if(newUbia){
    delivery_type = 'seasonal'
  }

  let uboDates = []
  _.each(ubo, tag => {
    const date = tag.split('-')[1]
    uboDates.push(moment(date, 'DDMMYYYY'))
  })

  let boDates = []
  _.each(bo, tag => {
    const date = tag.split('-')[1]
    boDates.push(moment(date, 'DDMMYYYY'))
  })

  let cutOffTime = same_day_tag ? same_day_tag.toLowerCase().replace('same-day-', '') : '12pm';

  // return state values
  return {
    bia: {
      ...(newBia && {
        days_in_advance: newBia.split('-')[1],
        exclude_days: _.upperCase(newBia.split('-')[2]),
      }),
    },
    ubia: {
      ...(newUbia && {
        days_in_advance: newUbia.split('-')[1],
        exclude_days: _.upperCase(newUbia.split('-')[2]),
      }),
    },
    cutOffTime,
    self,
    block_em,
    uboDates,
    boDates: _.uniq(boDates),
    locationsSelected,
    occasionsSelected,
    forWhoSelected,
    flowerTypesSelected,
    not_halal,
    ordered_fields: ordered_fields ? true : false,
  }
}

export function convertAttr(attr, item, initialMetafieldAddOns, initialMetafieldInputs){
  const { bia: { exclude_days, days_in_advance }, ubia: { days_in_advance: days_in_advance_seasonal }, customizeFields, self, block_em, closedTags, locationsSelected, occasionsSelected, not_halal, have_halal_cert, halal_cert, flowerTypesSelected, forWhoSelected, delivery_type, uboDates, boDates, cutOffTime, pre_order, shippingStart } = attr

  let attrTags = []

  if(not_halal == 'yes'){
    attrTags.push('non-halal')
  } else if(not_halal == 'no'){
    if(have_halal_cert == 'yes'){
      attrTags.push('have_halal_cert')
    }

    if(have_halal_cert == 'yes' && halal_cert){
      attrTags.push('logo_halal')
    } else {
      attrTags.push('alcohol-pork-free')
    }
  }

  if(delivery_type && delivery_type === 'd2d'){
    attrTags.push(`BIA-${days_in_advance || 0}-${exclude_days}`)

    if(cutOffTime && cutOffTime !== '12pm' && days_in_advance === "0"){
      attrTags.push('same-day-' + cutOffTime)
    }
  }

  if(delivery_type && delivery_type === 'seasonal'){
    attrTags.push(`UBIA-${days_in_advance_seasonal || 0}-W`)

    if(cutOffTime && cutOffTime !== '12pm' && days_in_advance_seasonal === "0"){
      attrTags.push('same-day-' + cutOffTime)
    }
  }

  _.each(uboDates, date => {
    attrTags.push(`UBO-${date.format('DDMMYYYY')}`)
  })

  _.each(boDates, date => {
    attrTags.push(`BO-${date.format('DDMMYYYY')}`)
  })

  if(closedTags){
    attrTags = [...attrTags, ...closedTags];
  }

  if(self){
    attrTags.push('SELF')
  }

  if(block_em){
    attrTags.push('block-em', 'remove-nationwide')
  }

  if (pre_order && shippingStart) {
    attrTags.push(`SS-${shippingStart.format('DDMMYYYY')}`)
  }

  _.each(locationsSelected, loc => {
    attrTags.push(loc.value)
  })

  _.each(occasionsSelected, occasion => {
    attrTags.push(occasion.value)
  })

  _.each(forWhoSelected, forWho => {
    attrTags.push(forWho.value)
  })

  _.each(flowerTypesSelected, flower => {
    attrTags.push(flower.value)
  })

  let metafields = []
  let addonCount = 1
  let inputCount = 1

  let fields = []
  _.each(customizeFields, field => {
    if (field.conditionalFields && field.conditionalFields.length > 0) {
      _.each(field.conditionalFields, condField => {
        if (!condField.selected) {
          return
        }
        let selected = condField.selected.map(selected => selected.value)
        attrTags.push(`COND_${condField.primaryTitle}_${field.primaryTitle}_${selected.join('#')}`);

        fields.push(condField)
      })
    }
    fields.push(field)
  })

  _.each(item.options, option => {
    if (option.conditionalFields && option.conditionalFields.length > 0) {
      _.each(option.conditionalFields, condField => {
        if (!condField.selected) {
          return
        }
        let selected = condField.selected.map(selected => selected.value)
        attrTags.push(`VCOND_${condField.primaryTitle}_${option.position - 1}_${selected.join('#')}`);

        fields.push(condField)
      })
    }
  })

  _.each(fields, field => {
    let { primaryTitle, maxChar, secondTitle, limit, required, seq } = field;
    let options = _.map(field.options, opt => opt.value)
    let validAddOnOptions = _.filter(options, opt => {
      let optSplit = opt.split('#')
      return optSplit.length === 2 && !_.some(optSplit, str => str === '')
    })

    let addOnId, addOnKey = `add_ons_${addonCount}`, inputKey = `dropdown${inputCount}`, foundInitial
    switch(field.tagType){
      case 'textfield':
        if(primaryTitle && maxChar){
          attrTags.push(`TEXTFIELD${seq ? '#'+seq : ''}_${primaryTitle}_${maxChar}_${secondTitle || ''}_${required ? 'required' : ''}`);
        }
        break;

      case 'textarea':
        if(primaryTitle && maxChar){
          attrTags.push(`TEXTAREA${seq ? '#'+seq : ''}_${primaryTitle}_${maxChar}${secondTitle ? `_${secondTitle}` : ''}`);
        }
        break;

      case 'dropdown':
        if(primaryTitle && options.length > 0){
          let dropdownStr = `DROPDOWN${seq ? '#'+seq : ''}_${primaryTitle}_${options.join('_')}_@${secondTitle || ''}@${required ? 'required' : ''}`

          const foundMetafield = _.find(initialMetafieldInputs, input => {
            return field.key && input.key === field.key
          })

          if (foundMetafield) {
            inputCount += 1

            metafields.push({
              id: foundMetafield.id,
              key: inputKey,
              value: dropdownStr,
              namespace: 'inputs'
            })
          } else if (dropdownStr.length > 255){
            inputCount += 1

            metafields.push({
              key: inputKey,
              value: dropdownStr,
              namespace: 'inputs'
            })
          } else {
            attrTags.push(dropdownStr);
          }
        }
        break;

      case 'imageupload':
        if(primaryTitle){
          attrTags.push(`IMAGEUPLOAD${seq ? '#'+seq : ''}_${primaryTitle}${required ? '_required' : ''}`);
        }
        break;

      case 'multiupload':
        if(primaryTitle && limit){
          attrTags.push(`MULTIUPLOAD${seq ? '#'+seq : ''}_${primaryTitle}_${limit}${required ? '_required' : ''}`);
        }
        break;

      case 'timeslot':
        if(primaryTitle && options.length > 1){
          attrTags.push(`TIMESLOT${seq ? '#'+seq : ''}_${primaryTitle}_${options.join('_')}`)
        }
        break;

      case 'checkbox':
        if(primaryTitle && limit && options.length > 1){
          attrTags.push(`CHECKBOX${seq ? '#'+seq : ''}_${primaryTitle}_${limit}_${options.join('_')}`)
        }
        break;

      case 'audioupload':
        if(primaryTitle){
          attrTags.push(`AUDIOUPLOAD${seq ? '#'+seq : ''}_${primaryTitle}`);
        }
        break;

      case 'logoupload':
        if(primaryTitle){
          attrTags.push(`LOGOUPLOAD_${primaryTitle}`);
        }
        break;

      case 'ddaddon':
        addonCount += 1
        foundInitial = _.find(initialMetafieldAddOns, addon => {
          return addon.key === addOnKey
        })

        if (foundInitial) {
          addOnId = foundInitial.id
        }

        metafields.push({
          ...(addOnId && { id: addOnId }),
          key: addOnKey,
          value: `DDADDON${seq ? '#'+seq : ''}_${primaryTitle}_${validAddOnOptions.join('_')}`,
          namespace: 'add_ons'
        })
        break;

      case 'cbaddon':
        addonCount += 1
        foundInitial = _.find(initialMetafieldAddOns, addon => {
          return addon.key === addOnKey
        })

        if (foundInitial) {
          addOnId = foundInitial.id
        }

        metafields.push({
          ...(addOnId && { id: addOnId }),
          key: addOnKey,
          value: `CBADDON${seq ? '#'+seq : ''}_${primaryTitle}_${limit}_${validAddOnOptions.join('_')}`,
          namespace: 'add_ons'
        })
        break;

      default:
        break;
    }
  })

  let metafieldsToRemove = _.map(_.filter(initialMetafieldAddOns, addon => !_.some(metafields, m => m.id === addon.id)), "id")

  attrTags = _.join(_.uniq(attrTags), ', ');

  return { attributeTags: attrTags, metafields, metafieldsToRemove };
}

function tagReTest(item, tag){
  const reg = new RegExp(item.replace(/(?=[()])/g, '\\'), 'i')
  return reg.test(tag)
}

export function generateTagsString(item, predefinedTags, attributeTags){
  let tagsSplit = item.tags.split(', ')
  const inputRe = /^bia|^ubia|^same-day-|^ubo-|^bo-|^self$|^block-em$|^remove-nationwide$|^textfield|^textarea|^dropdown|^imageupload|^multiupload|^timeslot|^checkbox|^audioupload|^logoupload|^closed-|^cond|^vcond|^ss-|^$|^@$/i

  let locationTags = _.map(predefinedTags['Location'], 'tag')
  let occasionTags = _.map(predefinedTags['Occasions'], 'tag')
  let forWhoTags = _.map(predefinedTags['For Who'], 'tag')
  let flowerTypeTags = _.map(predefinedTags['Flower Type'], 'tag')

  let nonAttrTags = _.filter(tagsSplit, tag => !(inputRe.test(tag)))
  nonAttrTags = _.filter(nonAttrTags, tag => !_.some([
    ...locationTags,
    ...occasionTags,
    ...forWhoTags,
    ...flowerTypeTags,
    'alcohol-pork-free',
    'logo_halal',
    'non-halal',
  ], predefinedTag => {
    const reg = new RegExp(predefinedTag.replace(/(?=[()])/g, '\\'), 'i')
    return reg.test(tag)
  }))

  return (nonAttrTags.length > 0 ? (nonAttrTags.join(', ') + ', ') : "") + attributeTags;
}

export function getDeliveryTags (product) {

  const now = moment().utcOffset(8);

  // let product = flatResult[i]

  let tags = _.map(_.split(product.tags, ','), _.trim);
  let bia = _.find(tags, function (tag) {
    return _.startsWith(_.lowerCase(tag), 'bia');
  });

  if (!bia) {
    bia = _.find(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'ubia');
    });
  }

  let deliveryDateTags = _.filter(tags, function (tag) {
    return _.startsWith(_.lowerCase(tag), 'dd');
  });

  //When Product Type and Vendor = Others,
  //auto remove tag Type_Product Type and Vendor_vendor's name ?
  let isDeprecatedProduct = product.vendor === 'Others' || product.product_type === 'Others'

  if (!isDeprecatedProduct && bia) {
    let biaSplits = _.split(bia, '-')

    let hasSameDayDelivery = _.includes(tags, 'Same Day Delivery');
    if (hasSameDayDelivery) {
      _.pull(tags, 'Same Day Delivery');
    }

    let hasNextDayDelivery = _.includes(tags, 'Next Day Delivery');
    if (hasNextDayDelivery) {
      _.pull(tags, 'Next Day Delivery');
    }

    let hasMin2DaysBooking = _.includes(tags, 'Min. 2 Days Booking');
    if (hasMin2DaysBooking) {
      _.pull(tags, 'Min. 2 Days Booking');
    }

    let hasMin3DaysDelivery = _.includes(tags, 'Min. 3 Days Booking');
    if (hasMin3DaysDelivery) {
      _.pull(tags, 'Min. 3 Days Booking');
    }

    if (deliveryDateTags) {
      _.each(deliveryDateTags, function (ddTag) {
        _.pull(tags, ddTag);
      })
    }

    let deliverOnSunday = true
    let deliverOnSaturday = true
    let bookingDaysAdvance = 99;

    if (biaSplits[2] && _.toLower(biaSplits[2]) === 'nw') {
      deliverOnSaturday = false
    }

    if (biaSplits[2] && (_.toLower(biaSplits[2]) === 'nw' || _.toLower(biaSplits[2]) === 'ws')) {
      deliverOnSunday = false
    }

    if (biaSplits[1]) {
      let number = _.toNumber(biaSplits[1]);
      if (_.isNumber(number)) {
        bookingDaysAdvance = number;
      }
    }

    let boTags = _.filter(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'bo');;
    });

    let uboTags = _.filter(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'ubo');;
    });

    let closedTags = _.filter(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'closed');;
    });

    let boDates = [];
    if (_.lowerCase(biaSplits[0]) === 'bia' && boTags) {
      boDates = _.map(boTags, function (bo) {
        let boSplits = _.split(bo, '-')
        let date = boSplits[1]

        return moment(date, "DDMMYYYY");
      })
    } else if (_.lowerCase(biaSplits[0]) === 'ubia') {
      for (let i = 0; i < 8; i++) {
        const potentialBo = moment(now).add(i, 'd');

        if (!_.includes(uboTags, `UBO-${potentialBo.format('DDMMYYYY')}`)) {
          boDates.push(potentialBo)
        }
      }
    }

    let closedDays;
    if (closedTags) {
      closedDays = _.map(closedTags, function (tag) {
        let tagSplits = _.split(tag, '-')

        return tagSplits[1];
      })
    }

    const isTodayOff = getIsTodayOff(boDates, closedDays, now, tags)
    const isTomorrowOff = getIsTomorrowOff(boDates, closedDays, now)

    // Same Day Delivery (BIA-0, no blackout on current date)
    // Next Day Delivery (BIA-0, BIA-1, no blackout on current date)
    // Min. 2 Days Booking (BIA-0, BIA-1, BIA-2, no blackout on current date)
    // Min. 3 Days Booking (BIA-0, BIA-1, BIA-2, BIA-3, no blackout on current date)
    // For all above, check if current = Sat or Sun, then add on to exclude BIA-X-NS or BIA-X-S

    //- BIA-0
    // - BIA-1
    // - no blackout on current_date + 1
    // - if current_day+1 = Sat or Sun and if contains BIA-X-NW, then dont tag.
    // - if current_day+1 = Sun and if contains BIA-X-WS, then dont tag.
    // - if weeknumber(current_day +1) = closed-day, then dont tag.

    if (bookingDaysAdvance === 0) {
      if ((now.day() !== 0 && now.day() !== 6 && !isTodayOff) ||
        (now.day() === 0 && deliverOnSunday && !isTodayOff) ||
        (now.day() === 6 && deliverOnSaturday && !isTodayOff)) {
        tags.push("Same Day Delivery")
        tags.push(`DD-${now.format('D-M-YY (ddd)')}`)
      }
    }

    if (bookingDaysAdvance <= 1) {

      if ((now.day() !== 0 && now.day() !== 6 && !isTomorrowOff) ||
        (moment(now).add(1, 'd').day() === 0 && deliverOnSunday && !isTomorrowOff) ||
        (moment(now).add(1, 'd').day() === 6 && deliverOnSaturday && !isTomorrowOff)) {
        tags.push("Next Day Delivery")
      }
    }

    if (bookingDaysAdvance <= 2) {
      if ((now.day() !== 0 && now.day() !== 6 && !isTodayOff) ||
        (now.day() === 0 && deliverOnSunday && !isTodayOff) ||
        (now.day() === 6 && deliverOnSaturday && !isTodayOff)) {
        tags.push("Min. 2 Days Booking")
      }
    }

    if (bookingDaysAdvance <= 3) {
      if ((now.day() !== 0 && now.day() !== 6 && !isTodayOff) ||
        (now.day() === 0 && deliverOnSunday && !isTodayOff) ||
        (now.day() === 6 && deliverOnSaturday && !isTodayOff)) {
        tags.push("Min. 3 Days Booking")
      }
    }

    let i = 1;
    if (bookingDaysAdvance > 1) {
      i = bookingDaysAdvance
    }

    for (; i < 8; i++) {
      const isNextXDaysOff = getIsNextXDaysOff(boDates, closedDays, i)

      const whichDay = moment(now).add(i, 'd').day()

      if ((whichDay !== 0 && whichDay !== 6 && !isNextXDaysOff) || (whichDay === 0 && deliverOnSunday && !isNextXDaysOff) || (whichDay === 6 && deliverOnSaturday && !isNextXDaysOff)) {
        tags.push(`DD-${moment(now).add(i, 'd').format('D-M-YY (ddd)')}`)
      }
    }
  } else if (!isDeprecatedProduct && !bia) {

    _.each(tags, function(tag){
      if(_.startsWith(_.lowerCase(tag), 'edd') || _.startsWith(_.lowerCase(tag), 'wdd')){
        _.pull(tags, tag);
      }
    })

  }

  let is12pm = moment().utcOffset(8).hour() == 12

  const timeCapital = now.format('hA')
  const timeSmall = now.format('ha')
  let todayDate = moment().format('D-M-YY (ddd)')

  let hasSameDay = _.find(tags, function (tag) {
    if (is12pm) {
      return /^same-day-/ig.test(tag)
    }
    return tag === `same-day-${timeSmall}` || tag === `SAME-DAY-${timeCapital}`
  });

  let shouldRemoveDeliveryTag = false;
  if(hasSameDay){
    shouldRemoveDeliveryTag = true
  }

  if(is12pm && !hasSameDay){
    shouldRemoveDeliveryTag = true
  }

  if (!isDeprecatedProduct && bia && shouldRemoveDeliveryTag) {

    let hasSameDayDelivery = _.includes(tags, 'Same Day Delivery');
    if (hasSameDayDelivery) {
      _.pull(tags, 'Same Day Delivery');
    }

    let ddTags = _.filter(tags, function (tag) {
      return _.startsWith(tag, 'DD-')
    })

    _.each(ddTags, function (tag) {
      let split = tag.substr(3)
      if (split && moment(split, 'D-M-YY (ddd)').isBefore(moment())) {
        _.pull(tags, tag);
      }
    })

    let hasDeliveryDate = _.includes(tags, `DD-${todayDate}`);
    if (hasDeliveryDate) {
      _.pull(tags, `DD-${todayDate}`);
    }

    const yesterday = moment().subtract(1, 'days');
    let tagsToBePulled = []
    _.each(tags, function (tag) {
      if (_.startsWith(_.lowerCase(tag), 'bo')) {
        const boSplit = _.split(tag, '-')
        if (boSplit[1]) {
          if (yesterday.isAfter(moment(boSplit[1], 'DDMMYYYY'))) {
            tagsToBePulled.push(tag)
          }
        }
      }
    })

    if (tagsToBePulled) {
      _.each(tagsToBePulled, function (tagToBePulled) {
        _.pull(tags, tagToBePulled)
      })
    }
  }

  product.tags = _.join(tags, ', ')

  return product
}

function getIsTodayOff(boDates, closedDays, now, tags) {
  let isTodayOff = false

  if (boDates && boDates.length > 0) {
    _.each(boDates, function (boDate) {
      if (now.isSame(boDate, 'day')) {
        isTodayOff = true
      }
    })
  }

  if (closedDays && closedDays.length > 0) {
    _.each(closedDays, function (day) {
      if (now.day().toString() === day) {
        isTodayOff = true
      }
    })
  }

  if(now.day() === 6){

    let blockSat = _.find(tags, function (tag) {
      return _.lowerCase(tag) === 'block sat';
    });

    if(blockSat){
      isTodayOff = true
    }
  }

  if(now.day() === 0){

    let blockSun = _.find(tags, function (tag) {
      return _.lowerCase(tag) === 'block sun';
    });

    if(blockSun){
      isTodayOff = true
    }
  }

  return isTodayOff;
}

function getIsTomorrowOff(boDates, closedDays, now) {
  let isTomorrowOff = false

  if (boDates && boDates.length > 0) {
    _.each(boDates, function (boDate) {
      if (moment(now).add(1, 'd').isSame(boDate, 'day')) {
        isTomorrowOff = true
      }
    })
  }

  if (closedDays && closedDays.length > 0) {
    _.each(closedDays, function (day) {
      if (moment(now).add(1, 'd').day().toString() === day) {
        isTomorrowOff = true
      }
    })
  }

  return isTomorrowOff
}

function getIsNextXDaysOff(boDates, closedDays, nextXDays) {

  const now = moment().utcOffset(8)

  let isNextXDaysOff = false;

  if (boDates && boDates.length > 0) {
    _.each(boDates, function (boDate) {
      if (moment(now).add(nextXDays, 'd').isSame(boDate, 'day')) {
        isNextXDaysOff = true
      }
    })
  }

  if (isNextXDaysOff) {
    return true
  }

  if (closedDays && closedDays.length > 0) {
    _.each(closedDays, function (day) {
      if (moment(now).add(nextXDays, 'd').day().toString() === day) {
        isNextXDaysOff = true
      }
    })
  }

  return isNextXDaysOff
}
