import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Tabs, Tab, Card, Col, Button, Alert, Container, Badge} from 'react-bootstrap';
import Loader from '../components/Loader';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import api from '../helpers/apiClient';
import moment from 'moment';
import VendorSelection from '../components/VendorSelection'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Form } from 'react-bootstrap';
import TabHeader from '../components/TabHeader';
import ManualSyncModal from '../components/ManualSyncModal';
import BulkManualSyncModal from '../components/BulkManualSyncModal';
import DeliveryFinePrintsModal from '../components/DeliveryFinePrintsModal';
import Dropzone from 'react-dropzone'
import uploadIcon from '../images/upload-icon.svg'
import ProductEditModal from '../components/ProductEditModal';
import * as taggingsActionCreators from '../redux/actions/taggings';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
const oppositeGiftrDomain = window.location.hostname === 'seller.giftr.my' ? 'giftr.sg' : 'giftr.my'

class ProductImport extends Component {
  constructor(props) {
    super(props);
    const { location, user } = props;

    let vendorList = [];
    let vendor = 'any';

    if(user.merchantId){
      vendorList = user.merchantId.split('#,');
      vendor = vendorList[0]
    }

    if (location.search) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.vendor) {
        vendor = queryString.vendor;
      } else if (vendorList.length > 1 && !queryString.vendor){
        vendor = vendorList[0]
      }
    }

    this.state = {
      vendor,
      vendorList,
      loading: false,
      items: [],
      selectedIds: [],
      apiKey: '',
      password: '',
      storeUrl: '',
      linked_store: '',
      displayProductImportAlert: false,
      successMessage: 'Product(s) imported successfully',
      provider: 'shopify',
      linkedMerchants: [],
      importing: false,
      error: "",
      products: [],
      showManualSyncModel: false,
      showBulkManualSyncModel: false,
      loadingDeliveryFinePrints: false,
      file: null,
      page: 1
    }
  }

  async componentDidMount() {
    const { provider, vendor } = this.state
    const { user, fetchTaggings } = this.props;

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    if (isAdmin) {
      const result = await api(`/merchants/provider/linked/${provider}`, {
        method: 'GET'
      })

      this.setState({
        linkedMerchants: result
      })
    } else if (vendor) {
      const result = await api(`/merchants/provider/${provider}?merchantId=${encodeURIComponent(vendor)}`, {
        method: 'GET'
      })

      if(provider === 'shopify'){
        this.setState({ password: result.shopify_api_password, storeUrl: result.shopify_store_url })
      } else if (provider === 'woo_commerce') {
        this.setState({ storeUrl: result.woo_commerce_store_url, apiKey: result.woo_commerce_consumer_key, password: result.woo_commerce_consumer_secret })
      } else if (provider === 'shopcada') {
        this.setState({ storeUrl: result.shopcada_store_url, apiKey: result.shopcada_api_key, password: result.shopcada_authentication_token, username: result.shopcada_username })
      } else if (provider === 'linked_store') {
        this.setState({ linked_store: result.linked_store })
      }

      const linkedMerchants = await api(`/merchants/provider/linked/${provider}`, {
        method: 'GET'
      })

      const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
        method: 'GET'
      })

      this.setState({
        linkedMerchants,
        products
      })
    }

    await fetchTaggings()
    await this.setSelectOptions()
  }

  setSelectOptions = async () => {

    const { taggings: { items: predefinedTags } } = this.props;

    function createOptions(taggings) {
      return _.map(taggings, tag => {
        return {
          label: tag.desc,
          value: tag.tag
        }
      })
    }

    const dayOptions = [
      { value: 'sun', label: 'Sunday' },
      { value: 'mon', label: 'Monday' },
      { value: 'tue', label: 'Tuesday' },
      { value: 'wed', label: 'Wednesday' },
      { value: 'thu', label: 'Thursday' },
      { value: 'fri', label: 'Friday' },
      { value: 'sat', label: 'Saturday' },
    ]

    this.setState({
      selectOptions: {
        dayOptions,
        locationOptions: createOptions(predefinedTags['Location'])
      }
    })
  }

  handleOnClickListProducts = async () => {

    let { username, storeUrl, password, provider, apiKey, linked_store, page } = this.state;

    let body = {
      username, storeUrl, password, provider, apiKey, linked_store, page
    }

    this.setState({
      loading: true,
      items: [],
      error: "",
      importType: 'providers',
      selectedIds: []
    })

    const result = await api(`/products/import/list`, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    if(!result ||result.length === 0){
      this.setState({
        loading: false,
        error: "No result found"
      })
    } else {
      this.setState({
        loading: false,
        items: result
      })
    }
  }

  handleOnClickBulkEditLink = () => {
    this.setState({
      showBulkManualSyncModel: true
    })
  }

  handleOnCloseBulkEditLink = () => {
    this.setState({
      showBulkManualSyncModel: false
    })
  }

  handleSyncProducts = async () => {

    let { vendor, importType } = this.state;

    if(importType === 'csv'){
      this.setState({
        showProductEditModal: true
      })
    } else {

      this.setState({ loadingDeliveryFinePrints : true })

      //get delivery fine prints from API
      let result = await api(`/merchants/${vendor}/delivery_fine_prints`)

      this.setState({ loadingDeliveryFinePrints : false })

      if(result && result.length > 0) {

        if(result.length === 1) {
          let deliveryFinePrints = result[0].content
          this.syncProducts(deliveryFinePrints, result[0]._id)
        } else {
          //show modal to select delivery fine prints
          this.setState({ showDeliveryFinePrintsModal: true, deliveryFinePrintsOptions: result })
        }
      } else {
        this.syncProducts()
      }
    }
  }

  handleOnCloseDeliveryFinePrintsModal = () => {
    this.setState({ showDeliveryFinePrintsModal: false })
  }

  syncProducts = async (deliveryFinePrints, finePrintId) => {
    
    let { selectedIds, storeUrl, username, password, vendor, provider, apiKey, linked_store, items } = this.state;
    this.setState({ importing: true })

    let ids = _.map(selectedIds, (selected) => {
      return selected.id
    })

    let body = {
      ids,
      storeUrl,
      username,
      password,
      vendor,
      provider,
      apiKey,
      linked_store,
    }
    if (deliveryFinePrints) {
      body.delivery_fine_prints = deliveryFinePrints
    }
    if (finePrintId) {
      body.fine_print_id = finePrintId
    }

    const result = await api(`/products/sync`, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    //sleep for 1 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));

    const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
      method: 'GET'
    })

    let tempItems = items
    items = []

    if(result.errors){
      this.setState({
        displayProductImportAlert: true,
        importing: false,
        productImportSuccess: false,
        successMessage: 'Fail to sync product(s)',
        items
      })
    } else {
      this.setState({
        displayProductImportAlert: true,
        importing: false,
        productImportSuccess: true,
        successMessage: `Product(s) imported successfully. Please go to <a href=/products>Product Listing</a> to update the remaining details.`,
        items
      })
    }

    this.setState({ items: tempItems, products, selectedIds: [], showDeliveryFinePrintsModal: false})
  }

  handleManualSyncProduct = async (sourceId, selectedId) => {

    let { provider, vendor, items } = this.state;
    this.setState({ importing: true })

    const result = await api(`/products/${selectedId}/sync`, {
      method: 'POST',
      body: JSON.stringify({sourceId, provider})
    })

    //sleep for 1 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));

    const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
      method: 'GET'
    })

    let tempItems = items
    items = []

    if(result.errors){
      this.setState({ items, products, displayProductImportAlert: true, importing: false, productImportSuccess: false, successMessage: 'Fail to sync product(s)', showManualSyncModel: false })
    } else {
      this.setState({ items, products, displayProductImportAlert: true, importing: false, productImportSuccess: true, successMessage: 'Product manually synced successfully', showManualSyncModel: false })
    }

    //this is a hack to force the table to re-render
    this.setState({ items: tempItems })
  }

  handleBulkSyncProduct = async (updateMapping) => {

    let { provider, vendor, items } = this.state;
    this.setState({ importing: true })

    const result = await api(`/products/bulk/sync`, {
      method: 'POST',
      body: JSON.stringify({update_mapping: updateMapping, provider})
    })

    this.refreshProducts(items, vendor, result.errors)
  }

  handleUnsyncProduct = async (sourceId, selectedId) => {

    let { provider, vendor, items } = this.state;
    this.setState({ importing: true })

    const result = await api(`/products/${selectedId}/sync`, {
      method: 'DELETE',
      body: JSON.stringify({sourceId, provider})
    })

    this.refreshProducts(items, vendor, result.errors)
  }

  handleBulkUnsyncProduct = async (productIds) => {

    let { provider, vendor, items } = this.state;
    this.setState({ importing: true })

    const result = await api(`/products/bulk/sync`, {
      method: 'DELETE',
      body: JSON.stringify({
        productIds, provider})
    })

    this.refreshProducts(items, vendor, result.errors)
  }

  refreshProducts = async (items, vendor, errors) => {

    //sleep for 1 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));

    const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
      method: 'GET'
    })

    let tempItems = items
    items = []

    if(errors){
      this.setState({ items, products, displayProductImportAlert: true, importing: false, productImportSuccess: false, successMessage: 'Fail to sync product(s)', showBulkManualSyncModel: false, selectedIds: [] })
    } else {
      this.setState({ items, products, displayProductImportAlert: true, importing: false, productImportSuccess: true, successMessage: 'Products manually synced successfully', showBulkManualSyncModel: false, selectedIds: [] })
    }

    //this is a hack to force the table to re-render
    this.setState({ items: tempItems })
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      let synced = this.isSynced(row.id)
      let matchedName = this.isMatchedName(row)

      let syncedOrMatchedId = null

      if(synced && synced.length > 0){
        syncedOrMatchedId = synced[0].id
      } else if(matchedName && matchedName.length > 0){
        syncedOrMatchedId = matchedName[0].id
      }

      selectedIds.push({id: row.id, title: row.title, is_update: row.is_update, images: row.images, preselectedId: syncedOrMatchedId})
    } else {
      selectedIds = _.reject(selectedIds, {id: row.id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {
    if(isSelected) {
      this.setState({ selectedIds: _.map(rows, row => { return {id: row.id, title: row.title, is_update: row.is_update} })})
      return false
    }

    this.setState({ selectedIds: [] })
  }

  handleOnChange = (e) => {

    this.setState({ [e.target.name]: e.target.value })
  }

  handleVendorChange = async (e) => {

    const { provider } = this.state
    let vendor = e.target.value
    this.setState({ vendor, apiKey: "", password: "", storeUrl: "" })

    const result = await api(`/merchants/provider/${provider}?merchantId=${encodeURIComponent(e.target.value)}`, {
      method: 'GET'
    })

    if(provider === 'shopify'){
      this.setState({ password: result.shopify_api_password, storeUrl: result.shopify_store_url })
    } else if (provider === 'woo_commerce') {
      this.setState({ storeUrl: result.woo_commerce_store_url, apiKey: result.woo_commerce_consumer_key, password: result.woo_commerce_consumer_secret })
    } else if (provider === 'linked_store'){
      this.setState({ linked_store: result.linked_store })
    } else if (provider === 'shopcada'){
      this.setState({ storeUrl: result.shopcada_store_url, apiKey: result.shopcada_api_key, password: result.shopcada_authentication_token, username: result.shopcada_username })
    }

    const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
      method: 'GET'
    })

    this.setState({
      products
    })
  }

  handleProviderChange = async (e) => {

    const provider = e.target.value
    this.setState({ provider: e.target.value, password: "", storeUrl: "", apiKey: "" })

    const result = await api(`/merchants/provider/linked/${e.target.value}`, {
      method: 'GET'
    })

    this.setState({
      linkedMerchants: result
    })

    const { vendor } = this.state

    //url escape vendor
    const vendorEscaped = encodeURIComponent(vendor)

    const keypass = await api(`/merchants/provider/${provider}?merchantId=${vendorEscaped}`, {
      method: 'GET'
    })

    if(!keypass) {
      return
    }

    if(provider === 'shopify'){
      this.setState({ password: keypass.shopify_api_password, storeUrl: keypass.shopify_store_url })
    } else if (provider === 'woo_commerce') {
      this.setState({ storeUrl: keypass.woo_commerce_store_url, apiKey: keypass.woo_commerce_consumer_key, password: keypass.woo_commerce_consumer_secret })
    } else if (provider === 'shopcada') {
      this.setState({ storeUrl: keypass.shopcada_store_url, apiKey: keypass.shopcada_api_key, password: keypass.shopcada_authentication_token, username: keypass.shopcada_username })
    } else if (provider === 'linked_store') {
      this.setState({ linked_store: keypass.linked_store })
    }
  }

  handleDismissAlert = () => {
    this.setState({
      displayProductImportAlert: false
    })
  }

  showImage = (cell, row) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].src}/>;
    }
  }

  showIsUpdate = (cell, row) => {
    if(cell){
      return <Badge bg="secondary">{row.id}</Badge>
    } else {
      return <Badge bg="success">New</Badge>
    }
  }

  renderCSVImages = (cell, row) => {
    const images = _.map(cell, img => img.src)

    return images
  }

  renderCSVInventory = (cell, row) => {
    let output = this.calcInventory(cell, row)
    if(typeof output !== 'string'){
      output = (output.props && output.props.dangerouslySetInnerHTML && output.props.dangerouslySetInnerHTML.__html) || ''
    }

    return output.replace('<br>', ',')
  }

  renderSKUs = (cell, row) => {

  
    let skus = ''
    _.each(cell, (variant, index) => {

      if(!variant.sku){
        return
      }

      if(skus.length > 0){
        skus = skus.concat(', ')
      }
      skus = skus.concat(variant.sku)
    })

    return skus
  }

  renderVariants = cell => {

    if(!cell){
      return ''
    }

    let outputString = ''
    for (let i = 0; i < cell.length; i++){

      if(cell[i].title){
        outputString = outputString.concat(`${cell[i].title} - RM${parseInt(cell[i].price).toFixed(2)}<br>`)
      }
    }

    const returnContent = outputString.replace(/<br>$/m, '');

    return <p style={{whiteSpace: 'pre-line', margin: '0'}} dangerouslySetInnerHTML={{__html: returnContent}}/>
  }

  calcInventory = cell => {
    let tracked = [], untracked = []

    if(!cell){
      return ''
    }

    for (let i = 0; i < cell.length; i++){
      if(cell[i].inventory_management == null){
        untracked.push(true)
        continue
      }

      tracked.push(cell[i].inventory_quantity)

    }

    let outputString = ''
    if(untracked.length > 0){
      outputString = outputString.concat('available<br>')
    }

    if(tracked.length > 0){
      const inStock = _.filter(tracked, i => i > 0)
      const outOfStock = _.filter(tracked, i => i <= 0)

      if(inStock.length > 0){
        outputString = outputString.concat(`${inStock.reduce((s, i) => s + i)} ${inStock.length > 1 ? `in ${inStock.length} variants` : 'in 1 variant'}<br>`)
      }

      if(outOfStock.length > 0){
        if(outputString.length > 0){
          outputString = outputString.concat(`out of stock ${outOfStock.length > 1 ? `in ${outOfStock.length} variants` : 'in 1 variant'}`)
        } else {
          outputString = outputString.concat('out of stock')
        }
      }
    }

    const returnContent = outputString.replace(/<br>$/m, '');

    return <p style={{whiteSpace: 'pre-line', margin: '0'}} dangerouslySetInnerHTML={{__html: returnContent}}/>
  }

  renderDate = (cell, row) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  isSynced = (cell) => {
    const { products, provider } = this.state

    //filter products where id is integer
    let syncedProduct = _.filter(products, product => {
      return !isNaN(product.id)
    })

    //remove underscore from provider
    const providerName = provider.replace('_', '')

    //find product in products where tags contains "sync_{provider}_{cell}"
    const match = _.filter(syncedProduct, product => {
      return _.includes(product.tags, `sync_${providerName}_${cell}`)
    })

    return match
  }

  isPending = (cell) => {
    const { products, provider } = this.state

    //filter products where id start with "P"
    let pendingProduct = _.filter(products, product => {
      return product.id.toString().startsWith('P')
    })

    //remove underscore from provider
    const providerName = provider.replace('_', '')

    //find product in products where tags contains "sync_{provider}_{cell}"
    const match = _.filter(pendingProduct, product => {
      return _.includes(product.tags, `sync_${providerName}_${cell}`)
    })

    return match
  }

  isMatchedName = (row) => {

    const { products } = this.state

    //find product in products where name matches row.name
    const matchName = _.filter(products, product => {
      let productTitle = _.toLower(_.trim(product.title, '0123456789 '))
      let rowTitle = _.toLower(_.trim(row.title, '0123456789 '))

      return _.includes(productTitle, rowTitle) || _.includes(rowTitle, productTitle)
    })

    return matchName

  }

  renderSynced = (cell, row) => {

    let matched = this.isSynced(cell)
    let matchedName = this.isMatchedName(row)
    let isPending = this.isPending(cell)

    //if match display synced
    if(matched && matched.length > 0){
      //render badge synced
      return <Badge bg="success">Linked</Badge>
    }

    if(isPending && isPending.length > 0){
      //render badge synced
      return <Badge bg="warning">Pending</Badge>
    }

    if(matchedName && matchedName.length > 0){
      //render badge synced
      return <Badge bg="warning">Matched</Badge>
    }

    return <Badge bg="danger">Unlinked</Badge>
  }

  sortSynced = (cell, row) => {
    let matched = this.isSynced(cell)
    let matchedName = this.isMatchedName(row)
    let isPending = this.isPending(cell)

    if(matched && matched.length > 0){
      return 'Linked'
    }

    if(isPending && isPending.length > 0){
      return 'Pending'
    }

    if(matchedName && matchedName.length > 0){
      return 'Matched'
    }

    return 'Unlinked'
  }

  renderManualSync = (cell, row) => {

    let synced = this.isSynced(cell)
    let matchedName = this.isMatchedName(row)

    let syncedOrMatchedId = null
    let cta = 'Edit Link'

    if(synced && synced.length > 0){
      syncedOrMatchedId = synced[0].id
    } else if(matchedName && matchedName.length > 0){
      syncedOrMatchedId = matchedName[0].id
      cta = 'Confirm Link'
    }

    return <Button
        id={`${row.id}`}
        onClick={() => {this.handleManualSyncClicked(row, syncedOrMatchedId)}}
      >
      { cta }
    </Button>
  }

  renderExpandedComponent = (row) => {

    return (
      <div>
        <p>{row.body}</p>
      </div>
    )
  }

  handleManualSyncClicked = (manualSyncProduct, preselectedId) => {

    this.setState({
      showManualSyncModel: true,
      manualSyncProduct,
      preselectedId
    })
  }

  handleManualSyncClosed = () => {
    this.setState({
      showManualSyncModel: false
    })
  }

  handleOnDrop = (files) => {
    if(files && files.length > 0){
      this.setState({
        uploadingFile: true
      });

      var formData = new FormData();
      formData.append('products', files[0]);

      api('/products/import/list/csv' , {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'}).then(data => {
        this.setState({
          uploadingFile: false,
          items: data,
          importType: "csv",
          file: files[0]
        });
      }).catch(err =>{
        this.setState({
          uploadingFile: false
        });
        console.log(err)
      })
    }
  }

  handleOnCloseEditProductModal = async (shouldProceed, formData) => {

    if(!shouldProceed){
      this.setState({ showProductEditModal: false });
      return
    }

    const { file, selectedIds } = this.state;

    if(file){
      this.setState({
        importing: true
      });

      let ids = _.map(selectedIds, product => product.id)

      var importData = new FormData();
      importData.append('products', file);
      importData.append('ids', JSON.stringify(ids));

      let result = await api('/products/import/csv' , {
        method: 'POST',
        body: importData
      }, {'Accept': 'application/json'}).catch(err => {
        console.log(err);
      })

      if(result.errors){
        this.setState({
          productImportSuccess: false,
          successMessage: 'Fail to sync product(s)',
          displayProductImportAlert: true
        })
      } else {

        let pendingProducts = _.map(result.new_products_ids, (id) => {
          return { id }
        })

        this.setState({
          productImportSuccess: true,
        })

        if(pendingProducts && pendingProducts.length > 0){
          //perform bulk edit
          formData.set('products', JSON.stringify(pendingProducts));      
          await api('/products/bulk/edit', {
            method: 'POST',
            body: formData
          }, {'Accept': 'application/json'}).catch(err => {
            console.log(err);
          })
        }

        ids = _.map(pendingProducts, (product) => {
          return product.id
        })

        _.each(result.updated_products_ids, id => {
          ids.push(id)
        })

        this.setState({ showProductEditModal: false });
    
        ids = ids.join(',')
        //redirect to bulk edit page
        window.location.href = `/products/bulk-edit?ids=${ids}`
      }
  
      this.setState({ selectedIds: [], importing: false })
    }
  }

  handlePageChange = async (e) => {    
    let page = e.target.value
    this.setState({ page })
  }

  render() {
    const { user, match } = this.props;
    const { vendor, items, selectedIds, loading, displayProductImportAlert, successMessage, linkedMerchants, 
      password, storeUrl, vendorList, importing, provider, apiKey, error, productImportSuccess, linked_store, 
      showManualSyncModel, showBulkManualSyncModel, products, manualSyncProduct, preselectedId, username, deliveryFinePrintsOptions, 
      showDeliveryFinePrintsModal, loadingDeliveryFinePrints, file, importType, showProductEditModal, selectOptions,
      pendingProducts, page } = this.state;

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'is_update',
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        sort: true,
        formatter: this.showIsUpdate,
        hidden: importType !== "csv",
        text: 'Product ID',
      },
      {
        dataField: 'images',
        text: 'Image',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        align: 'center',
        formatter: this.showImage,
        csvFormatter: this.renderCSVImages,
        csvText: 'Image Src'
      },
      {
        dataField: 'handle',
        text: 'Handle',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'title',
        headerStyle: {
          width: '400px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Title',
      },
      {
        dataField: 'variants',
        headerStyle: {
          width: '240px',
          resize: 'horizontal'
        },
        text: 'Variants',
        sort: true,
        formatter: this.renderVariants
      },
      {
        dataField: 'variants',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Inventory',
        sort: true,
        formatter: this.calcInventory,
        csvFormatter: this.renderCSVInventory,
      },
      {
        dataField: 'product_type',
        text: 'Product Type',
      },
      {
        dataField: 'type',
        text: 'Product Type',
        hidden: importType === "csv"
      },
      {
        dataField: 'variants',
        text: 'SKUs',
        formatter: this.renderSKUs,
        csvFormatter: this.renderSKUs,
      },
      {
        dataField: 'updated_at',
        text: 'Updated At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: importType === "csv"
      },
      {
        dataField: 'created_at',
        text: 'Created At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: importType === "csv"
      },
      {
        dataField: 'id',
        text: 'Active/Sold Out Status',
        formatter: this.renderSynced,
        sort: true,
        sortValue: this.sortSynced,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: importType === "csv"
      },
      {
        dataField: 'id',
        text: 'Action',
        sort: false,
        formatter: this.renderManualSync,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: importType === "csv"
      }
    ];

    return (
      <div>
        <TabHeader title="Import Products" tab_id="import_products" user={user} />
        { 
          (!importType || importType === "csv") &&
          <div className="row mt-3 mb-4 justify-content-between">
            <Col xs={12}>
              <Card className="shadow-sm" style={{border: 'none', borderRadius:'10px'}}>
                <Card.Header className="my-2 mx-4 px-0" style={{background: '#fff',}}>
                  <h5 className="mb-0">Import products by Excel Sheet</h5>
                </Card.Header>
                <Card.Body className="grey-label label-strong mx-3">
                  <div>
                    <label>Attach a .xlxs file. <a href="/Import Product Template.xlsx" download>Download Template</a></label>
                    <Dropzone
                      className='dropzone-area'
                      onDrop={this.handleOnDrop}
                      multiple={false}
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'>
                      <div className="dropzone-text">
                        <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                        <span>Drag and drop files here, or click to upload</span>
                      </div>
                    </Dropzone><br />
                    { file &&
                      <label className="my-auto">Current file: {file.name}</label>
                    }
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        }
        { 
          (!importType || importType !== "csv") &&
          <div className="row mt-3 mb-4 justify-content-between">
            <Col xs={12}>
              <Card className="shadow-sm" style={{border: 'none', borderRadius:'10px'}}>
                <Card.Header className="my-2 mx-4 px-0" style={{background: '#fff',}}>
                  <h5 className="mb-0">Import products from your store</h5>
                </Card.Header>
                <Card.Body className="grey-label label-strong mx-3">
                  <div className="d-grid gap-2">
                    <div className="d-grid gap-1">
                      <label htmlFor="provider">Platform</label>
                      <Form.Select name="provider" className="form-control" onChange={this.handleProviderChange} >
                        <option selected key="shopify" value="shopify">Shopify</option>
                        <option key="woo" value="woo_commerce">Woo Commerce</option>
                        <option key="shopcada" value="shopcada">Shopcada</option>
                        <option key="linked_store" value="linked_store">Linked {country === "MY" ? "SG" : "MY"} Store</option>
                      </Form.Select>
                    </div>
                    <div className="d-grid gap-1">
                    {
                      isAdmin &&
                      <VendorSelection handleOnVendorChange={this.handleVendorChange} selectedVendor={vendor} rootClass={"."} decorator=" - [LINKED]" decorateOptions={linkedMerchants}/>
                    }
                    { //For dual vendor
                      vendorList.length > 1 &&
                        <div>
                          <label style={{marginBottom: '5px'}}>Vendor</label>
                          <Form.Select className="form-control" onChange={this.handleVendorChange} value={vendor}>
                            {
                              vendorList.map((option, ind) => {

                                let found = _.includes(linkedMerchants, option)

                                return (
                                  <option key={ind} value={option}>{option}{found ? " - [LINKED]" : ""}</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                    }
                    </div>
                    {
                      (provider === 'woo_commerce' || provider === 'shopcada') &&
                        <div className="d-grid gap-1">
                          <label htmlFor="key">{ provider === 'woo_commerce' ? 'Consumer Key' : 'X-Shopcada-API-Key'}</label>
                          <input className="form-control" name="apiKey" type="text" onChange={this.handleOnChange} value={apiKey}/>
                        </div>
                    }
                    {
                      provider === 'shopcada' &&
                      <div className="d-grid gap-1">
                        <label htmlFor="key">Username</label>
                        <input className="form-control" name="username" type="text" onChange={this.handleOnChange} value={username}/>
                      </div>
                    }
                    {
                      (provider === 'shopify' || provider === 'woo_commerce' || provider === 'shopcada') &&
                      <div className="d-grid gap-1">
                        <label htmlFor="password">{provider === 'shopify' ? 'Admin API Access Token' : (provider === 'shopcada' ? 'Auth Token' : 'Consumer Secret')}</label>
                        <input className="form-control" type="password" onChange={this.handleOnChange} value={password} name="password"/>
                      </div>
                    }
                    {
                      (provider === 'shopify' || provider === 'woo_commerce' || provider === 'shopcada') &&
                      <div className="d-grid gap-1">
                        <label htmlFor="storeUrl">Store Url</label>
                        <input className="form-control" type="text" onChange={this.handleOnChange} value={storeUrl} name="storeUrl" placeholder={provider === "shopify" ? "mystore.myshopify.com" : "www.mystore.com"}/>
                      </div>
                    }
                    {
                      provider === 'linked_store' &&
                      <div className="d-grid gap-1">
                        <label htmlFor="storeUrl">Linked Store</label>
                        <input className="form-control" disabled type="text" onChange={this.handleOnChange} value={linked_store} name="linked_store" placeholder=""/>
                        <small className="text-muted">Please contact your account manager to link your store on {oppositeGiftrDomain}</small>
                      </div>
                    }
                    {
                      (provider === 'woo_commerce') &&
                      <div className="d-grid gap-1">
                        <label htmlFor="page">Page</label>
                        <Form.Select className="form-control" onChange={this.handlePageChange} value={page}>
                          <option key="1" value="1">1</option>
                          <option key="2" value="2">2</option>
                          <option key="3" value="3">3</option>
                          <option key="4" value="4">4</option>
                          <option key="5" value="5">5</option>
                          <option key="6" value="6">6</option>
                          <option key="7" value="7">7</option>
                          <option key="8" value="8">8</option>
                          <option key="9" value="9">9</option>
                          <option key="10" value="10">10</option>
                        </Form.Select>
                      </div>
                    }

                    <Button
                          className="btn-br-6 mt-3 mx-auto w-100"
                          variant="primary"
                          disabled={(!password || !storeUrl) && !linked_store}
                          onClick={this.handleOnClickListProducts}
                        >List
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        }
        <div>
        {
          error &&
          <span>{error}</span>
        }
        {
          items.length > 0 &&
          <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
            <Col sm="auto">
              <Button
                variant="primary"
                className="w-100 btn-br-6"
                disabled={importing || selectedIds.length < 1}
                onClick={this.handleSyncProducts}
              >{ loadingDeliveryFinePrints ? "Loading..." : `Import ${importType === 'csv' ? '' : '& Sync'} ${selectedIds.length} New Product${selectedIds.length > 1 ? 's' : ''}` }</Button>
            </Col>
            { 
              importType !== "csv" && 
              <Col sm="auto">
                <Button
                  variant="primary"
                  className="w-100 btn-br-6"
                  disabled={ selectedIds.length < 1}
                  onClick={this.handleOnClickBulkEditLink}
                >Bulk Edit {selectedIds.length} Link(s) </Button>
              </Col>
            }
          </Container>
        }
        {
          displayProductImportAlert &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant={productImportSuccess ? "success" : "danger"} dismissible onClose={this.handleDismissAlert}>
                <span dangerouslySetInnerHTML={{__html: successMessage}}></span>
              </Alert>
            </div>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading && items.length > 0 &&
          <ToolkitProvider
              keyField='id'
              data={items}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover 
                      expandableRow={() => true}
                      expandComponent={this.renderExpandedComponent}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created_at",
                        sortOrder: "desc"
                      }}
                    />
                </>
            )
          }
          </ToolkitProvider>
        }
        </div>

      {
        showManualSyncModel &&
        <ManualSyncModal  products={products} sourceProduct={manualSyncProduct} updating={importing} preselectedId={preselectedId} onClickSave={this.handleManualSyncProduct} onClickUnlink={this.handleUnsyncProduct} onClose={this.handleManualSyncClosed} title="Edit Sync"/>
      }
      {
        showBulkManualSyncModel &&
        <BulkManualSyncModal products={products} sourceProducts={selectedIds} updating={importing} onClickSave={this.handleBulkSyncProduct} onClose={this.handleOnCloseBulkEditLink} onBulkUnsyncProduct={this.handleBulkUnsyncProduct} title="Bulk Edit Sync"/>
      }
      {
        showDeliveryFinePrintsModal &&
        <DeliveryFinePrintsModal deliveryFinePrints={deliveryFinePrintsOptions} onClose={this.handleOnCloseDeliveryFinePrintsModal} onSaved={this.syncProducts} />
      }
      {
        showProductEditModal &&
        <ProductEditModal isImportFlow selectedProducts={selectedIds} onClose={this.handleOnCloseEditProductModal} selectOptions={selectOptions}/>
      }
      </div>
    )
  }
}

const defaultOptions = [];

export default connect((state, props) => {
  return {
    taggings: state.taggings,
    error: state.error,
    user: state.auth.user,
    merchantsOptions: (state.merchants && state.merchants.items) ? state.merchants.items.map(merchant => {
      return {
        key: merchant,
        value: merchant,
        name: merchant,
      }
    }) : defaultOptions,
  };
}, {
  ...errorActionCreators,
  ...taggingsActionCreators
})(ProductImport);

