import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Badge, ButtonToolbar, Row, Col, Form, Alert } from 'react-bootstrap';
import LineItems from '../components/LineItems';
import Fulfillment from '../components/Fulfillment';
import TrackingInfoModal from '../components/TrackingInfoModal';
import EditDeliveryModal from '../components/EditDeliveryModal';
import AdjustmentModal from '../components/AdjustmentModal';
import GTRAlert from '../components/GTRAlert';
import OverlayLoader from '../components/OverlayLoader';
import ShippingModal from '../components/ShippingModal';
import ShippingModalSG from '../components/ShippingModalSG';
import ConfirmModal from '../components/ConfirmModal';
import RemarkModal from '../components/RemarkModal';
import api from '../helpers/apiClient';
import _ from 'lodash'
import * as orderDetailActionCreators from '../redux/actions/orderDetail';
import * as errorActionCreators from '../redux/actions/error';
import config from '../config'
import Clipboard from 'clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import Profile from './Profile';
import TabHeader from '../components/TabHeader';
import EmailSenderModal from '../components/EmailSenderModal';
import WhatsappSenderModal from '../components/WhatsappSenderModal';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';
import EditDeliveryInfoModal from '../components/EditDeliveryInfoModal';
import MobileDetect from "mobile-detect";
import EditCustomisationModal from '../components/EditCustomisationModal';
import ActivityLogModal from '../components/ActivityLogModal'

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const shopifyAdminDomain = window.location.hostname === 'seller.giftr.my' ? 'https://helpr-asia.myshopify.com/admin' : 'https://giftr-singapore.myshopify.com/admin'
const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddFulfillmentModal: false,
      isShowEditFulfillmentModal: false,
      selectedUnfulfilledItemIds: [],
      selectedFulfillableIds: [],
      selectedFulfillmentId: null,
      adjustmentLineItem: null,
      isShowConfirmCancelFulfillmentModal: false,
      showShippingModal: false,
      showEditDeliveryModal: false,
      showRemarkModal: false,
      showAdjustmentModal: false,
      showEditDeliveryInfoModal: false,
      internalNote: '',
      showActivityLogModal: false
    };
  }

  componentDidMount() {
    const { fetchOrderDetail, match } = this.props;
    fetchOrderDetail(match.params.orderId);
    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {

      e.trigger.innerText = 'COPIED'
      e.clearSelection();
    });

    if(country === 'SG'){
      this.fetchMerchantCountry()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { orderDetail: { item } } = nextProps;
    const { internalNote } = this.state
    if (item) {
      let foundNote = _.find(item.note_attributes, note => note.name === 'Internal Note');
      if (foundNote && foundNote.value !== internalNote) {
        this.setState({ internalNote: foundNote.value });
      }
    }
  }

  fetchMerchantCountry = async () => {
    const result = await api(`/profile/merchant_country`);
    this.setState({merchantCountry: result})
  }

  handleOnClickShowShippingModal = () => {
    const { selectedFulfillableIds } = this.state;
    const { orderDetail: { item }, user } = this.props;

    let line_items = item.line_items.filter(lineItem => { return selectedFulfillableIds.includes(lineItem.id.toString())})
    line_items = _.map(line_items, lineItem => {
      return {
        ...lineItem,
        order_id: item.id,
        order_name: item.name,
      }
    })

    let weight = _.reduce(line_items, (acc, lineItem) => {
      return acc + lineItem.grams;
    }, 0);

    let value = _.reduce(line_items, (acc, lineItem) => {
      return acc + parseFloat(lineItem.price);
    }, 0)

    let vendor = _.find(line_items, line_item => line_item.vendor).vendor

    let shipping_fee = 0;
    if(item.shipping_lines && item.shipping_lines[0]) {
      if(item.shipping_lines[0].breakdown) {
        let foundBreakdown = _.find(item.shipping_lines[0].breakdown, function (shippingBreakdown) {
          return vendor === shippingBreakdown.vendor
        })
        if (foundBreakdown) {
          shipping_fee = foundBreakdown.shipping_fee
        }
      } else {
        shipping_fee = item.shipping_lines[0].price
      }
    }

    let parcel = {
      order_id: item.id,
      order_name: item.name,
      shipping_address: item.shipping_address,
      shipping_lines: item.shipping_lines,
      shipping_fee: shipping_fee,
      weight: weight,
      line_items: line_items,
      value: value,
      vendor: vendor,
    }

    this.setState({
      showShippingModal: true,
      parcel: parcel
    });
  }

  handleOnCloseShowShippingModal = () => {
    this.setState({ showShippingModal: false });
  }

  handleOnClickShowAddFulfillImentModal = () => {
    this.setState({ isShowAddFulfillmentModal: true });
  }

  handleOnCloseAddFulfillmentModal = () => {
    this.setState({ isShowAddFulfillmentModal: false });
  }

  handleOnClickShowRemarkModal = () => {
    this.setState({ showRemarkModal: true });
  }

  handleOnCloseRemarkModal = () => {
    this.setState({ showRemarkModal: false });
  }

  handleOnClickShowAdjustmentModal = (lineItem) => {
    this.setState({ showAdjustmentModal: true, adjustmentLineItem: lineItem });
  }

  handleOnCloseAdjustmentModal = () => {
    this.setState({ showAdjustmentModal: false });
  }

  handleOnClickShowEditDeliveryModal = () => {
    this.setState({ showEditDeliveryModal: true });
  }

  handleOnCloseEditDeliveryModal = () => {
    this.setState({ showEditDeliveryModal: false });
  }

  handleOnClickShowEditCustomisationModal = () => {
    const { selectedUnfulfilledItemIds } = this.state;
    const { orderDetail: { item } } = this.props;

    let lineItem = _.find(item.line_items, lineItem => {
      return selectedUnfulfilledItemIds[0] == lineItem.id.toString()
    })

    let properties = _.filter(lineItem.properties, property => {
      return property.name !== 'Fulfilled by' && property.name !== 'Delivery' && property.name !== 'Message on Card' && property.name !== 'Audio/Video Message'
    })

    _.each(properties, property => {
      let note = _.find(item.note_attributes, note => _.includes(note.name, `customisation_${selectedUnfulfilledItemIds[0]}_${property.name}`))
      if (note && note.value) {
        property.value = note.value
      }
    })

    this.setState({ editProperties: properties, showEditCustomisationModal: true });
  }

  handleOnCloseEditCustomisationModal = () => {
    this.setState({ showEditCustomisationModal: false });
  }

  handleSaveCustomisation = (entries) => {
    if (entries.length == 0) {
      this.setState({ showEditCustomisationModal: false });
      return
    }

    const { selectedUnfulfilledItemIds } = this.state;

    let customisation = []
    for (let entry of entries) {
      customisation.push({name: `customisation_${selectedUnfulfilledItemIds[0]}_${entry[0]}`, value: entry[1]})
    }

    this.handleOnSaveNoteAttributes(customisation)
    this.setState({ showEditCustomisationModal: false });
  }

  handleOnClickShowEditFulfillmentModal = (fulfillmentId) => {
    this.setState({
      isShowEditFulfillmentModal: true,
      selectedFulfillmentId: fulfillmentId
    });
  }

  handleOnCloseEditFulfillmentModal = () => {
    this.setState({ isShowEditFulfillmentModal: false });
  }

  handleOnClickShowConfirmCancelFulfillmentModal = (fulfillmentId) => {
    this.setState({
      isShowConfirmCancelFulfillmentModal: true,
      selectedFulfillmentId: fulfillmentId
    });
  }

  handleOnCloseConfirmCancelFulfillmentModal = () => {
    this.setState({ isShowConfirmCancelFulfillmentModal: false });
  }

  // handleOnCheckUnfulfilledItem = (e) => {
  //   const { orderDetail: { item: { note_attributes, line_items } } } = this.props;
  //   let { selectedUnfulfilledItemIds, selectedFulfillableIds } = this.state;
  //   const { checked, value } = e.target;

  //   const shipment = _.find(note_attributes, note => note.name === `shipment_${value}`)

  //   let values = [value];

  //   const currLineItem =  _.find(line_items, line_item => line_item.id.toString() === value)

  //   if (currLineItem.aoid) {
  //     const linkedLineItem = _.find(line_items, line_item => {
  //       return line_item.id.toString() !== value && line_item.aoid && line_item.aoid === currLineItem.aoid
  //     })

  //     if (linkedLineItem) {
  //       values.push(linkedLineItem.id.toString());
  //     }
  //   }

  //   if (checked) {
  //     if(!shipment || (shipment && shipment.value === "")){
  //       selectedFulfillableIds.push(...values)
  //     }
  //     this.setState({
  //       selectedUnfulfilledItemIds: [...selectedUnfulfilledItemIds, ...values],
  //       selectedFulfillableIds: selectedFulfillableIds
  //     });
  //   }
  //   else {
  //     this.setState({
  //       selectedUnfulfilledItemIds: selectedUnfulfilledItemIds.filter(id => !_.includes(values, id)),
  //       selectedFulfillableIds: _.filter(selectedFulfillableIds, id => !_.includes(values, id))
  //     });
  //   }
  // }

  handleOnCheckUnfulfilledItem = (selected) => {
    const { orderDetail: { item: { note_attributes } } } = this.props;

    let selectedFulfillableIds = []
    _.each(selected, selectedId => {
      const shipment = _.find(note_attributes, note => note.name === `shipment_${selectedId}`)

      if(!shipment || (shipment && shipment.value === "")){
        selectedFulfillableIds.push(selectedId)
      }
    })

    this.setState({
      selectedUnfulfilledItemIds: selected,
      selectedFulfillableIds,
    });
  }

  handleOnClickMarkAsReady = () => {
    const { selectedUnfulfilledItemIds } = this.state;
    const { fetchOrderDetail, match } = this.props;

    return api(`/orders/${match.params.orderId}/ready_to_ship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemIds: selectedUnfulfilledItemIds
      })
    }).then(() => {
      this.setState({
        selectedUnfulfilledItemIds: []
      })
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  handleAdjustment = async (lineItemId, itemPriceAdjustment, shippingAdjustment, adjustmentRemarks) => {
    const { fetchOrderDetail, match, orderDetail: { item } } = this.props;

    let result = await api(`/orders/${match.params.orderId}/adjustment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "line_item_id": lineItemId,
        "item_price": itemPriceAdjustment,
        "shipping_price": shippingAdjustment,
        "remarks": adjustmentRemarks,
        "order_name": item.name,
      })
    })

    this.setState({
      showAdjustmentModal: false,
    })

    fetchOrderDetail(match.params.orderId);
  }

  handleDeliveryDateChange = (date) => {

    const { selectedUnfulfilledItemIds } = this.state;
    const { fetchOrderDetail, match } = this.props;
    // console.log('edit delivey', date, selectedUnfulfilledItemIds)

    return api(`/orders/${match.params.orderId}/delivery/edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemIds: selectedUnfulfilledItemIds,
        date: date.format("D/M/YYYY")
      })
    }).then(() => {
      this.setState({
        showEditDeliveryModal: false,
        selectedUnfulfilledItemIds: []
      })
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          showEditDeliveryModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  handleOnClickFulfill = async (deliveryType, trackingCompany, trackingNumber, trackingUrl, file) => {
    const { fetchOrderDetail, match, orderDetail: { item } } = this.props;
    const { selectedUnfulfilledItemIds } = this.state;

    const email = item.customer.email;
    const orderStatusUrl = item.order_status_url;
    const name = item.name

    let file_url = ''
    if(file){
      file_url = await this.uploadFile(file)
    }

    return api(`/orders/${match.params.orderId}/fulfill`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deliveryType,
        itemIds: selectedUnfulfilledItemIds,
        trackingCompany,
        trackingNumber,
        trackingUrl,
        email,
        orderStatusUrl,
        name,
        file_url
      })
    }).then(fulfillment => {
      this.setState({
        isShowAddFulfillmentModal: false,
        selectedUnfulfilledItemIds: []
      })

      if(file_url){
        let body = {
          note_attributes: [
            ...item.note_attributes,
          ]
        }

        if (fulfillment.ids && fulfillment.ids.length) {
          _.each(fulfillment.ids, (id) => {
            body.note_attributes.push({
              "name": `proof_of_delivery_${id}`,
              "value": file_url
            })
          })
        }

        return api(`/orders/${match.params.orderId}/note_attribute`, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(body)
        })
      }

    }).then(() => {
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowAddFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  handleOnClickEditFulfillment = async (deliveryType, trackingCompany, trackingNumber, trackingUrl, file) => {
    const { fetchOrderDetail, match, orderDetail: { item } } = this.props;
    const { selectedFulfillmentId } = this.state;

    if(file) {
      const file_url = await this.uploadFile(file)

      let body = {
        note_attributes: [
          ...item.note_attributes,
          {
            "name": `proof_of_delivery_${selectedFulfillmentId}`,
            "value": file_url
          }
        ]
      }

      await api(`/orders/${match.params.orderId}/note_attribute`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
    }

    return api(`/orders/${match.params.orderId}/fulfillments/${selectedFulfillmentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        trackingCompany,
        trackingNumber,
        trackingUrl
      })
    }).then(() => {
      this.setState({
        isShowEditFulfillmentModal: false,
        selectedFulfillmentId: null
      })
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowEditFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  uploadFile = async file => {
    let formData = new FormData();
    formData.append('file', file)
    const data = await api('/file/upload/proof_of_delivery', {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'})

    return data.file_short_url
  }

  handleOnClickCancelFulfillment = () => {
    const { fetchOrderDetail, match } = this.props;
    const { selectedFulfillmentId } = this.state;
    return api(`/orders/${match.params.orderId}/fulfillments/${selectedFulfillmentId}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      this.setState({
        isShowConfirmCancelFulfillmentModal: false,
        selectedFulfillmentId: null
      })
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowConfirmCancelFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  printOrder = () => {
    window.print();
  }

  handleOnSaveNoteAttributes = async (notes, is_remark = false) => {

    const { match, orderDetail: { item }, fetchOrderDetail } = this.props

    let note_attributes = [...item.note_attributes]

    _.each(notes, note => {
      const { name, value } = note;
      const foundAttribute = _.find(note_attributes, n => n.name === name)

      if(foundAttribute){
        note_attributes = _.map(note_attributes, (attribute) => {
          return {
            name: attribute.name,
            value: attribute.name === name ? value : attribute.value
          }
        })
      } else {
        note_attributes.push({ name, value })
      }
    })

    let body = {
      note_attributes,
      is_remark,
      order_name: item.name,
    }

    return api(`/orders/${match.params.orderId}/note_attribute`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(() => {
      this.setState({
        showRemarkModal: false,
        selectedFulfillmentId: null,
        selectedUnfulfilledItemIds: [],
        selectedFulfillableIds: [],
      })
      fetchOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          showRemarkModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });

  }

  handleOnClickPrintMessage = (line_item_id) => {
    const { orderDetail: { item } } = this.props;

    api(`/log/print/vm`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ line_items: [{
        order_id: item.id,
        order_name: item.name,
        line_item_id
      }]})
    })

    window.open(location.origin + `/messages?ids=${item.id}-${line_item_id}`);
  };

  handleOnClickPrintInvoice = (order_id) => {

    window.open(location.origin + `/invoice?id=${order_id}`);
  };

  handleConfirmShipment = async (shipments, pickupDate, service) => {
    const { parcel } = this.state;

    let shipment_notes = []

    if (service === 'dhl') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        shipment_notes.push({ name: `shipment_${id}`, value: `${shipments[0].dhlShipmentId}${pickupDate ? `_${pickupDate}` : ''}` })
      })
    } else if (service === 'lalamove') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        shipment_notes.push({ name: `shipment_${id}`, value: shipments.shareLink })
      })

      shipment_notes.push({ name: `lalamove_${parcel.line_items[0].id}`, value: shipments.priceBreakdown.total })
    } else if (service === 'easyparcel') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        if (shipments[0] && shipments[0].parcel[0] && shipments[0].parcel[0].awb) {
          shipment_notes.push({ name: `shipment_${id}`, value: `${shipments[0].parcel[0].awb}${pickupDate ? `_${pickupDate}` : ''}` })
        }
      })
    }

    this.handleOnSaveNoteAttributes(shipment_notes)
  }

  onClickEmailSender = () => {
    this.setState({ showEmailSenderModal: true });
  }

  handleOnCloseEmailSenderModal = () => {
    this.setState({ showEmailSenderModal: false });
  }

  onClickWhatsappSender = () => {
    this.setState({ showWhatsappSenderModal: true });
  }

  handleOnCloseWhatsappSenderModal = () => {
    this.setState({ showWhatsappSenderModal: false });
  }

  onClickEditDeliveryInfo = () => {
    this.setState({ showEditDeliveryInfoModal: true });
  }

  handleOnCloseEditDeliveryInfo = () => {
    this.setState({ showEditDeliveryInfoModal: false });
  }

  handleSavedDeliveryInfo = () => {
    const { match, fetchOrderDetail } = this.props

    this.setState({ showEditDeliveryInfoModal: false });
    fetchOrderDetail(match.params.orderId);
  }

  onClickEditInternalNote = () => {
    this.setState({ showEditInternalNoteModal: true });
  }

  handleOnCloseInternalNoteModal = () => {
    this.setState({ showEditInternalNoteModal: false });
  }

  handleOnChangeInternalNote = (e) => {
    this.setState({ internalNote: e.target.value });
  }

  handleEditInternalNote = () => {
    const { internalNote } = this.state;

    this.handleOnSaveNoteAttributes([{
      name: 'Internal Note',
      value: internalNote
    }])

    this.setState({ showEditInternalNoteModal: false });
  }

  handleOnCloseActivityLogModal = () => {
    this.setState({ showActivityLogModal: false });
  }

  onClickActivityLog = () => {
    this.setState({ showActivityLogModal: true });
  }

  render() {
    const { orderDetail: { loading, loaded, item }, error, user } = this.props;
    const { isShowAddFulfillmentModal, selectedUnfulfilledItemIds, selectedFulfillableIds, 
      isShowEditFulfillmentModal, isShowConfirmCancelFulfillmentModal, showShippingModal, 
      showRemarkModal, showEditDeliveryModal, showAdjustmentModal, adjustmentLineItem, parcel, 
      showEmailSenderModal, showWhatsappSenderModal, showEditDeliveryInfoModal, showEditInternalNoteModal, 
      internalNote, merchantCountry, showEditCustomisationModal, editProperties, showActivityLogModal } = this.state;
    const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;

    let remark;

    if(item && item.note_attributes){
      remark = item.note_attributes.filter((attribute) => {
        return attribute.name === 'remark'
      })
    }

    let fullAddress = ''
    if(item && item.shipping_address){

      if(item.shipping_address.company){
        fullAddress = item.shipping_address.company ? `${item.shipping_address.company}
` : ''
      }

      fullAddress += `${item.shipping_address.address1}
`

      if(item.shipping_address.address2){
        fullAddress += item.shipping_address.address2 ? `${item.shipping_address.address2}
` : ''
      }

      fullAddress += `${item.shipping_address.zip} ${item.shipping_address.city}
${item.shipping_address.province}
${item.shipping_address.country}`
    }

    const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

    let merchantTotalItemPrice = 0, merchantShippingFeeBreakdown = [], merchantShippingFeeTotal = 0;
    if (item && user.merchantId){
      let merchantIds = user.merchantId.split('#,')

      merchantTotalItemPrice = _.reduce(item.line_items, (sum, lineItem) => {
        return sum + Number(lineItem.price * lineItem.quantity)
      }, 0)
      if (item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].breakdown) {
        _.each(item.shipping_lines[0].breakdown, breakdown => {
          if (breakdown && (breakdown.vendor === merchantIds[0] || breakdown.vendor === merchantIds[1])) {
            merchantShippingFeeBreakdown.push(breakdown)
          }
        })
      }

      merchantShippingFeeTotal = _.sumBy(merchantShippingFeeBreakdown, breakdown => breakdown.shipping_fee)
    }

    const hasShippingFee = item && item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].breakdown

    return (
      <>
        {
          loading &&
          <OverlayLoader />
        }
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        {
          !loading && item &&
          <>
            <TabHeader
              title="Order Detail"
              tab_id="order_detail"
              user={user}
              children={
                <>
                  {
                    isAdmin &&
                    <Button variant="primary" onClick={this.onClickEditInternalNote} className="btn-br-6">Edit Internal Note</Button>
                  }
                  { isAdmin &&
                    <Button variant="primary" onClick={this.onClickActivityLog} className="btn-br-6">Activity Log</Button>
                  }
                </>
              }
            />
            {
              internalNote && isAdmin &&
              <Alert variant="info" onClick={this.onClickEditInternalNote} className="cursor-pointer">
                <p
                  style={{
                    margin: '0',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                  dangerouslySetInnerHTML={{ __html: `Internal Note: ${internalNote.replaceAll('\n', '<br/>')}` }}
                />
              </Alert>
            }
          <div className='d-grid gap-4'>
            <div className="d-none d-print-block">⚠️ KINDLY DO NOT PASS THIS ORDER SLIP TO RECEIVER</div>
            <Row>
              <Col sm={6} md={5} lg={4}>
              <Card className="dashboard-card shadow-sm p-2">
                <Card.Body>
                    <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.name}</h4>
                    <p className="d-print-none my-3">placed on {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                    {
                      item.cancelled_at &&
                      <Badge bg="danger" style={{lineHeight: '1.5em'}}>CANCELLED</Badge>
                    }
                  <div className="d-grid gap-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr)'}}>
                    {
                      !item.cancelled_at &&
                      <Button className="btn-br-6 d-print-none" variant="primary" onClick={this.printOrder}>Print Order Slip</Button>
                    }
                    {
                      !item.cancelled_at && isAdmin &&
                      <Button variant="secondary" className="btn-br-6 d-print-none" onClick={() => this.handleOnClickPrintInvoice(item.id)}>Print Invoice</Button>
                    }
                    {
                      isAdmin &&
                      <a href={`${shopifyAdminDomain}/orders/${item.id}`} target="_blank" className="btn-br-6 d-print-none btn btn-secondary btn-br-6">View in Shopify</a>
                    }
                  </div>
                </Card.Body>
              </Card>
              </Col>
              <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
              <Col>
              <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                <Card.Header className="tealCardHeader">Order Info</Card.Header>
                <Card.Body className="grey-label">
                    <Row className="flex-row">
                      <Col lg={4} className="m-2">
                        <Row className="flex-lg-column w-100">
                          <Col xs={6} lg={12} className="d-grid mb-2">
                          <label>Financial Status</label>
                          <span className="text-uppercase">{item.financial_status}</span>
                          </Col>
                          {
                            isAdmin &&
                              <Col xs={6} lg={12} className="d-grid mb-2">
                                <label>Payment Method</label>
                                <span>{item.payment_gateway_names[0]}</span>
                              </Col>
                          }
                          <Col xs={6} lg={12} className="d-grid mb-2">
                            <label>Fulfillment Status</label>
                            <span className="text-uppercase" style={{color: item.fulfillment_status === 'fulfilled' ? '#4e8767' : '#ffc107'}}>{item.fulfillment_status}</span>
                          </Col>
                          {
                            item.cancelled_at &&
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Order Status</label>
                              <span  className="text-uppercase" style={{color: '#d15d5d'}}>Cancelled</span>
                            </Col>
                          }
                          {
                            item.note &&
                            <Col  xs={6} lg={12} className="d-grid mb-2">
                              <label>Note</label>
                              <span style={{color: '#d15d5d'}}>{item.note}</span>
                            </Col>
                          }
                        </Row>
                      </Col>
                    <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                      { isAdmin ?
                    <Col className="m-2 mt-2 my-lg-auto label-strong">
                        <Row>
                          <Col className="text-end"><label>Total Item Price</label></Col>
                          <Col>{currency} {item.current_subtotal_price}</Col>
                        </Row>
                        {
                          item.total_discounts !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Discounts</label></Col>
                            <Col>-{currency} {item.total_discounts}{item.discount_codes[0] ? ` (${item.discount_codes[0].code})` : ''}</Col>
                          </Row>
                        }
                        <Row>
                        <Col className='text-end'><label>Subtotal</label></Col>
                        <Col>{currency} {item.current_subtotal_price}</Col>
                        </Row>
                        {
                          item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].price !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Subtotal</label></Col>
                            <Col>{currency} {item.shipping_lines[0].price}</Col>
                          </Row>
                        }
                        {
                          hasShippingFee &&
                          item.shipping_lines[0].breakdown.map(breakdown => {
                            return breakdown &&
                            <Row key={breakdown.vendor} className="shipping-lines">
                              <Col className='text-end'>Shipping Fee({breakdown.vendor})</Col>
                              <Col>{currency} { parseFloat(breakdown.shipping_fee).toFixed(2)}</Col>
                            </Row>
                          })
                        }
                        <Row>
                          <Col className='text-end'><label>Total</label></Col>
                          <Col>{currency} {item.current_total_price}</Col>
                        </Row>
                        {
                          item.total_item_adjustment && item.total_item_adjustment !== '0.00' &&
                          <Row>
                          <hr/>
                            <Col className='text-end'><label>Item Price Adjustment</label></Col>
                            <Col>{currency} {item.total_item_adjustment}</Col>
                          </Row>
                        }
                        {
                          item.total_shipping_adjustment && item.total_shipping_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Adjustment</label></Col>
                            <Col>{currency} {item.total_shipping_adjustment}</Col>
                          </Row>
                        }
                    </Col>
                    :
                    <Col className="m-2 mt-2 my-lg-auto label-strong">
                        <Row>
                          <Col className="text-end"><label>Total Item Price</label></Col>
                          <Col>{currency} {merchantTotalItemPrice.toFixed(2)}</Col>
                        </Row>
                        {
                          item.merchant_discount &&
                          <Row>
                            <Col className='text-end'><label>Discounts</label></Col>
                            <Col>-{currency} {item.merchant_discount.amount} ({item.merchant_discount.code})</Col>
                          </Row>
                        }
                      { merchantShippingFeeBreakdown.length > 1 &&
                        <>
                          <Row>
                            <Col className='text-end'><label>Shipping Subtotal</label></Col>
                            <Col>{currency} {merchantShippingFeeTotal.toFixed(2)}</Col>
                          </Row>
                          {
                            merchantShippingFeeBreakdown.map(breakdown => (
                              <Row key={breakdown.vendor} className="shipping-lines">
                                <Col className='text-end'>Shipping Fee({breakdown.vendor})</Col>
                                <Col>{currency} { parseFloat(breakdown.shipping_fee).toFixed(2)}</Col>
                              </Row>
                            ))
                          }
                        </>
                      }
                      { merchantShippingFeeBreakdown.length === 1 &&
                      <Row>
                        <Col className='text-end'><label>Shipping Fee</label></Col>
                        <Col>{currency} { parseFloat(merchantShippingFeeBreakdown[0].shipping_fee).toFixed(2)}</Col>
                      </Row>
                      }
                        <Row>
                          <Col className='text-end'><label>Total</label></Col>
                          <Col>{currency} {(merchantTotalItemPrice + merchantShippingFeeTotal - (item.merchant_discount ? Number(item.merchant_discount.amount) : 0)).toFixed(2)}</Col>
                        </Row>
                        {
                          (item.total_item_adjustment || item.total_shipping_adjustment) && (item.total_item_adjustment !== '0.00' || item.total_shipping_adjustment !== '0.00') &&
                          <hr/>
                        }
                        {
                          item.total_item_adjustment && item.total_item_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Item Price Adjustment</label></Col>
                            <Col>{currency} {item.total_item_adjustment}</Col>
                          </Row>
                        }
                        {
                          item.total_shipping_adjustment && item.total_shipping_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Adjustment</label></Col>
                            <Col>{currency} {item.total_shipping_adjustment}</Col>
                          </Row>
                        }
                    </Col>
                      }
                  </Row>
                </Card.Body>
              </Card>
              </Col>
            </Row>
            <Row className="overflowX-auto">
              <Col>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                <Card.Body className="mx-2">
                  <Row>
                    {
                      item.customer && user && user.role !== 'supplier' &&
                        <Col lg={2} className="d-print-none grey-label">
                          <div className="d-grid mb-2">
                            <label>Customer Name</label>
                            <span>{item.customer.first_name} {item.customer.last_name}</span>
                          </div>
                          <div className="d-grid mb-2">
                            <label>Email</label>
                            <span style={{wordBreak: 'break-all'}}>{item.customer.email}</span>
                          </div>
                          {
                            item.customer.note &&
                            <div className="d-grid mb-2">
                              <label>Note</label>
                              <span style={{color: '#d15d5d'}}>{item.customer.note}</span>
                            </div>
                          }
                          {
                            (item.note_attributes && item.note_attributes.length && _.find(item.note_attributes, n => n.name === "Sender's phone number")) &&
                            <div className="d-grid mb-2">
                              <label>Sender's phone number</label>
                              <span>{_.find(item.note_attributes, n => n.name === "Sender's phone number").value}</span>
                            </div>
                          }
                          <div className="d-grid gap-1 mb-2">
                            <Button variant="secondary" className="btn-br-6 d-print-none" onClick={this.onClickEmailSender}>Email Sender</Button>
                          </div>
                          <div className="d-grid gap-1 mb-2">
                            <Button variant="secondary" className="btn-br-6 d-print-none" onClick={this.onClickWhatsappSender}>WhatsApp Sender</Button>
                          </div>
                        </Col>
                      }
                      {
                        (item.note || (item.note_attributes && item.note_attributes.length)) || item.shipping_address ?
                        <Col lg={6} className="mt-2 mt-lg-0">
                          <Card className="greyCard">
                            <Card.Header className="greyCardHeader">
                              Delivery Info
                              { isAdmin &&
                                <Button className="pull-right d-print-none add-remark" variant="link" onClick={this.handleOnClickShowRemarkModal}>Add Remark</Button>
                              }
                              { isAdmin &&
                                <Button className="d-print-none edit-delivery-info mx-2" variant="secondary" onClick={this.onClickEditDeliveryInfo}>Edit</Button>
                              }
                              </Card.Header>
                            <Card.Body className="label-strong d-grid gap-2 overflowX-auto">
                            {
                              item.note_attributes.map(attribute => {

                                if(attribute.name === 'remark'){
                                  return (
                                    <Row key={attribute.name}>
                                      <label className="text-uppercase">Internal {attribute.name}</label>
                                      <span className="delivery-info-value" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> {attribute.value}</span>
                                    </Row>
                                  )
                                }
                              })
                            }
                            {
                              item.shipping_address &&
                              <>
                              <Row>
                                <label>Receiver</label>
                                <span className="delivery-info-value">{item.shipping_address.name}</span>
                              </Row>
                              <Row>
                                <div className="mt-2">
                                  <label>Delivery Address</label>
                                  <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={fullAddress}>COPY ADDRESS</Button>
                                </div>
                                <span className="delivery-info-value">
                                  <address>
                                    {item.shipping_address.company}{item.shipping_address.company && <br />}
                                    {item.shipping_address.address1}<br />
                                    {item.shipping_address.address2}{item.shipping_address.address2 && <br />}
                                    {item.shipping_address.zip} {item.shipping_address.city}<br />
                                    {item.shipping_address.province}<br />
                                    {item.shipping_address.country}<br />
                                  </address>
                                </span>
                              </Row>
                              <Row>
                                <label>Contact</label>
                                <span>{item.shipping_address.phone}</span>
                              </Row>
                              </>
                            }
                            {
                              item.note &&
                              <Row>
                                <label>Note</label>
                                <span className="delivery-info-value">{item.note}</span>
                              </Row>
                            }
                            </Card.Body>
                          </Card>
                        </Col>
                      : null
                      }
                      {
                        item.billing_address && user && user.role !== 'supplier' &&
                        <Col lg={4} className="d-print-none mt-3 mt-lg-0">
                          <Card className="greyCard label-strong">
                            <Card.Header className="greyCardHeader">Billing Address</Card.Header>
                            <Card.Body>
                            <address>
                              <label>{item.billing_address.name}</label><br />
                              {item.billing_address.company}{item.billing_address.company && <br />}
                              {item.billing_address.address1}<br />
                              {item.billing_address.address2}{item.billing_address.address2 && <br />}
                              {item.billing_address.zip} {item.billing_address.city}<br />
                              {item.billing_address.province}<br />
                              {item.billing_address.country}<br />
                              {item.billing_address.phone}
                            </address>
                            </Card.Body>
                          </Card>
                        </Col>
                      }
                  </Row>
                </Card.Body>
              </Card>
              </Col>
            </Row>
            <Row className="overflowX-auto">
              <Col>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Items</Card.Header>
                <Card.Body className="mx-2 my-1">
                {
                  item.unfulfilled_items && item.unfulfilled_items.length ?
                  <>
                    <div className="mb-2">
                      <h4 className="d-inline-block mt-2" style={{color: '#FFC107'}}>UNFULFILLED</h4>
                      <ButtonToolbar className="pull-right d-print-none gap-3">
                      {
                        isAdmin &&
                        <>
                        <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickShowEditCustomisationModal}>Edit Customisation</Button>
                        <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickShowEditDeliveryModal}>Edit Delivery Date</Button>
                        </>
                      }
                      <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickMarkAsReady}>Mark As Accepted</Button>
                      {
                        (country === 'MY' || (country === 'SG' && _.some(merchantCountry, country => country == 'Malaysia' || country == 'MY') >= 0 ) || isAdmin ) &&
                        <Button className="btn-br-6" variant="primary" disabled={!selectedFulfillableIds.length} onClick={this.handleOnClickShowShippingModal}>Arrange Shipment</Button>
                      }
                      <Button className="btn-br-6" variant="primary" disabled={!selectedFulfillableIds.length} onClick={this.handleOnClickShowAddFulfillImentModal}>Mark selected items as Fulfilled</Button>
                      </ButtonToolbar>
                    </div>
                    <div className="clearfix"></div>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                      <LineItems onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} fulfillmentStatus={item.fulfillment_status} user={user} items={item.unfulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}} onCheckItem={ !item.cancelled_at && this.handleOnCheckUnfulfilledItem} cancelledAt={item.cancelled_at} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} selectedIds={selectedUnfulfilledItemIds} displaySection={"unfulfilled"} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                {
                  item.fulfilled_items && item.fulfilled_items.length ?
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#4e8767'}}>FULFILLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                    <LineItems onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} displaySection={"fulfilled"} user={user} fulfillmentStatus={item.fulfillment_status} items={item.fulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                {
                  item.cancelled_items && item.cancelled_items.length ?
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#d15d5d'}}>CANCELLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                    <LineItems user={user} onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} displaySection={"cancelled"} fulfillmentStatus={item.fulfillment_status} items={item.cancelled_items} style={{width: '100%', whiteSpace: 'nowrap'}} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                </Card.Body>
              </Card>
              </Col>
            </Row>
            {
              item.fulfillments && item.fulfillments.length ?
              <Row className="overflowX-auto">
                <Col>
                <Card className="d-print-none tealCard shadow-sm fulfillments">
                  <Card.Header className="tealCardHeader">Fulfillments</Card.Header>
                  <Card.Body className="mx-2 my-0 py-0">
                  {
                    item.fulfillments.map((fulfillment, i) => {
                      return (
                        <Fulfillment
                          key={fulfillment.id}
                          fulfillment={fulfillment}
                          order={item}
                          index={i}
                          onClickEditFulfillment={this.handleOnClickShowEditFulfillmentModal}
                          onClickCancelFulfillment={this.handleOnClickShowConfirmCancelFulfillmentModal}
                        />

                      )
                    })
                  }
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              :
              null
            }
          </div>
        </>
        }
        {
          isShowAddFulfillmentModal &&
          <TrackingInfoModal isMobile={isMobile} onClose={this.handleOnCloseAddFulfillmentModal} onClickFulfill={this.handleOnClickFulfill} />
        }
        {
          isShowEditFulfillmentModal &&
          <TrackingInfoModal onClose={this.handleOnCloseEditFulfillmentModal} onClickFulfill={this.handleOnClickEditFulfillment} Badge="Save" />
        }
        {
          isShowConfirmCancelFulfillmentModal &&
          <ConfirmModal onClickYes={this.handleOnClickCancelFulfillment} onClose={this.handleOnCloseConfirmCancelFulfillmentModal} title="Cancel Fulfillment">
            <p>Are you sure you want to cancel this fulfillment?</p>
          </ConfirmModal>
        }
        {
          showShippingModal && country === "MY" &&
            <ShippingModal user={user} parcels={[parcel]} item={item} items={item.line_items.filter(lineItem => { return selectedFulfillableIds.includes(lineItem.id.toString())})} onClose={this.handleOnCloseShowShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match} hasShippingFee={hasShippingFee}/>
        }
        {
          showShippingModal && country === "SG" &&
            <ShippingModalSG user={user} parcels={[parcel]} item={item} items={item.line_items.filter(lineItem => { return selectedFulfillableIds.includes(lineItem.id.toString())})} onClose={this.handleOnCloseShowShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match} hasShippingFee={hasShippingFee}/>
        }
        {
          showRemarkModal &&
          <RemarkModal item={item} remark={remark} onClose={this.handleOnCloseRemarkModal} onClickSave={this.handleOnSaveNoteAttributes}/>
        }
        {
          showEditDeliveryModal &&
          <EditDeliveryModal onClose={this.handleOnCloseEditDeliveryModal} onClickEdit={this.handleDeliveryDateChange} />
        }
        {
          showEditCustomisationModal &&
          <EditCustomisationModal onClose={this.handleOnCloseEditCustomisationModal} onSave={this.handleSaveCustomisation} editProperties={editProperties}/>
        }
        {
          showAdjustmentModal &&
          <AdjustmentModal adjustmentLineItem={adjustmentLineItem} onClose={this.handleOnCloseAdjustmentModal} onClickSave={this.handleAdjustment} />
        }
        {
          showEmailSenderModal &&
            <EmailSenderModal
              onClose={this.handleOnCloseEmailSenderModal}
              to={item.contact_email}
              from={user.email}
              orderName={item.name}
              orderId={item.id}
            />
        }
        {
          showWhatsappSenderModal &&
            <WhatsappSenderModal
              senderNo={_.find(item.note_attributes, attr => attr.name === "Sender's phone number")}
              billingNo={item.billing_address && item.billing_address.phone}
              onClose={this.handleOnCloseWhatsappSenderModal}
              orderName={item.name}
              orderId={item.id}
            />
        }
        { showEditDeliveryInfoModal && item.shipping_address &&
          <EditDeliveryInfoModal onClose={this.handleOnCloseEditDeliveryInfo} shippingAddress={item.shipping_address} orderName={item.name} onSaved={this.handleSavedDeliveryInfo} orderId={item.id}/>
        }
        {
          showEditInternalNoteModal &&
            <ConfirmModal onClickYes={e => this.handleEditInternalNote()} onClose={this.handleOnCloseInternalNoteModal} title="Edit Internal Note" yesBtnTitle="Save" noBtnTitle="Cancel" size="lg">
              <label htmlFor="remark" style={{fontWeight: 'normal'}}>Internal Note</label>
              <textarea className="form-control" id="remark" name="remark" placeholder="Enter remark" value={internalNote} onChange={this.handleOnChangeInternalNote} style={{resize: 'vertical', height: '200px'}}/>
            </ConfirmModal>
        }
        {
          showActivityLogModal &&
          <ActivityLogModal onClose={this.handleOnCloseActivityLogModal} orderId={item.id} logType="order"/>
        }
      </>
    );
  }
}

export default connect((state, props) => {
  return {
    orderDetail: state.orderDetail,
    error: state.error,
    user: state.auth.user
  };
}, {
  ...orderDetailActionCreators,
  ...errorActionCreators
})(OrderDetail);
