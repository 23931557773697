import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Tabs, Tab, Col, Button, Alert, Badge } from 'react-bootstrap';
import OverlayLoader from '../components/OverlayLoader';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import api from '../helpers/apiClient';
import * as merchantsActionCreators from '../redux/actions/merchants';
import * as previewProductActionCreators from '../redux/actions/previewProduct';
import * as titleDecorationsActionCreators from '../redux/actions/titleDecorations';
import ImagesModal from  '../components/ImagesModal';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import ConfirmModal from '../components/ConfirmModal';
import * as taggingsActionCreators from '../redux/actions/taggings';
import { generateTagsString, generateAttr, convertAttr, getDeliveryTags } from '../helpers/productTags';
import ProductB2C from '../components/ProductB2C';
import ProductB2B from '../components/ProductB2B';
import TabHeader from '../components/TabHeader';
import LinkProductModal from '../components/LinkProductModal';
import toast from 'react-hot-toast';
import Promise from 'bluebird';
import { Link } from 'react-router-dom';
import * as cheerio from 'cheerio';
import handleize from '../helpers/handleize';
import moment from 'moment';
import SetUnpublishModal from '../components/SetUnpublishModal'
import ActivityLogModal from '../components/ActivityLogModal'

let country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
// country = "MY"

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function generateTierTagSG(variant) {
  let { min, max, shipping, lead_time } = variant
  if (!min || !max) {
    return ''
  }

  let tag = `TIER#${min}-${max}`

  if (shipping && shipping.single) {
    tag += `_WS${shipping.single}`
  }

  if (shipping && shipping.multi) {
    tag += `_WM${shipping.multi}`
  }

  if (lead_time && lead_time.lead_min) {
    tag += `_LMIN${lead_time.lead_min}`
  }

  if (lead_time && lead_time.lead_max) {
    tag += `_LMAX${lead_time.lead_max}`
  }

  return tag
}

function generateTierTag(variant) {
  let { min, max, shipping, lead_time } = variant
  if (!min || !max) {
    return ''
  }

  let tag = `TIER#${min}-${max}`

  if (shipping && shipping.west_single) {
    tag += `_WS${shipping.west_single}`
  }

  if (shipping && shipping.west_multi) {
    tag += `_WM${shipping.west_multi}`
  }

  if (shipping && shipping.east_single) {
    tag += `_ES${shipping.east_single}`
  }

  if (shipping && shipping.east_multi) {
    tag += `_EM${shipping.east_multi}`
  }

  if (lead_time && lead_time.west_lead_min) {
    tag += `_WLMIN${lead_time.west_lead_min}`
  }

  if (lead_time && lead_time.west_lead_max) {
    tag += `_WLMAX${lead_time.west_lead_max}`
  }

  if (lead_time && lead_time.east_lead_min) {
    tag += `_ELMIN${lead_time.east_lead_min}`
  }

  if (lead_time && lead_time.east_lead_max) {
    tag += `_ELMAX${lead_time.east_lead_max}`
  }

  return tag
}

class ProductCreate extends Component {
  constructor(props) {
    super(props);
    this.toastIdRef = createRef();
    this.updateBtnRef = createRef();

    this.state = {
      loading: true,
      overlayLoading: false,
      displayProductCreateFail: false,
      displayProductCreateSuccess: false,
      showConfirmSellInBulkModal: false,
      showImagesModal: false,
      showDeleteModal: false,
      showLinkProductModal: false,
      assignImageVariant: 0,
      uploadingFile: false,
      has_variants: false,
      submitting: false,
      not_found: false,
      item: {
        title: '',
        body_html: '',
        vendor: '',
        product_type: '',
        alt_product_type: [],
        status: 'pending',
        tags: '',
        variants: [
          {
            option1: "Default Title",
            option2: null,
            option3: null,
            price: '0',
            compare_at_price: '',
            sku: '',
            inventory_management: null,
            inventory_quantity: 0,
            selected_image: {},
            requires_shipping: true,
            weight: '',
            weight_unit: 'kg',
            metafields: [{
              namespace: 'price',
              key: 'markup_type',
              value: 'fixed',
              type: 'single_line_text_field',
            }, {
              namespace: 'price',
              key: 'base_price',
              value: '0',
              type: 'number_decimal',
            }, {
              namespace: 'price',
              key: 'markup',
              value: '0',
              type: 'number_decimal',
            }],
            min: '',
            max: '',
            shipping: {},
          }
        ],
        options: [
          {
            name: "Title",
            values: ["Default Title"],
            position: 1,
          }
        ],
        images: [],
        approval_status: '',
        halal_cert: '',
        "pricing_tier": [
          {
            "min_quantity": undefined,
            "max_quantity": undefined,
            "price": undefined
          },
        ],
      },
      remarks: '',
      showReviewModal: false,
      sold_out: [false],
      shipping_delivery_included: [true],
      update_variant_image: [],
      attributes: {},
      reorder_media: false,
      delete_media: [],
      productTemplateKey: 0,
      merchantsOptions: [],
      defaultMarkup: 0,
      defaultMarkupType: '',
      removeVariants: [],
      initialMetafieldAddOns: [],
      initialMetafieldInputs: [],
      showUnpublishDateModal: false,
      displayProductTitle: '',
      actualProductTitle: '',
      prefixText: '',
      suffixText: '',
      matchedTitleDecorations: [],
      showActivityLogModal: false
    }
  }

  async componentDidMount() {
    const { match, fetchTaggings, fetchTitleDecorations } = this.props;
    let { item } = this.state;

    if(_.includes(match.path, 'create')){
      this.setState({
        item: {
          ...item,
          approval_status: 'create',
          enable_postcode: true,
          enable_addons: true,
          tags: 'enable_postcode',
        },
      })
    } else {
      await this.fetchProduct();
      await fetchTitleDecorations();
    }

    await this.fetchMerchants();
    await fetchTaggings()
    await this.setAttributes();
    this.checkForTitleDecorations()
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  // reloadProduct = async () => {
  //   this.setState({ loading: true });
  //   await this.fetchProduct();
  //   this.setState({ loading: false });
  // }

  showUpdatePrompt = () => {
    const { match } = this.props;

    if (_.includes(match.path, 'create')) {
      return
    }

    this.toastIdRef.current = toast(
      <div>
        <span>Update Product?</span>
        <Button className="ms-2 btn-br-6" variant="info" onClick={() => {
          if (this.updateBtnRef.current) {
            this.updateBtnRef.current.click();
          }
        }}>
          UPDATE
        </Button>
      </div>
      , { duration: Infinity });
  }

  setItemState = (item, showPrompt = true, callback) => {
    if (item.status === 'archived') {
      return
    }
    const approvedPendingProduct = item.approval_status == 'approved' && !isNumeric(item.id)

    if (!this.toastIdRef.current && showPrompt && !approvedPendingProduct) {
      this.showUpdatePrompt();
    }

    const { actualProductTitle } = this.checkForTitleDecorations(item.title, item.tags)

    item.title = actualProductTitle
    this.setState({ item }, callback)
  }

  fetchMerchants = async () => {
    const { user, match } = this.props;
    const isAdmin = user.role === 'admin' || user.role === 'staff'

    return api(`/merchants/product/create`, {
      method: 'GET'
    }, {'Accept': 'application/json'}).then(result => {
      if(result.length > 0){
        let newState = {
          merchants: result,
          merchantsOptions: result ? result.map(({ name }) => {
            return {
              key: name,
              value: name,
              name: name,
            }
          }) : [],
        }

        newState.item = this.state.item

        let merchant = result[0]
        //check of item has a vendor
        if(newState.item.vendor){
          let found = result.find(({ name }) => name == newState.item.vendor)
          if(found){
            merchant = found
          }
        }

        let { virtual_message, qna, exclude_free_message, name, markup, markup_type, has_delivery_fine_prints, remarks } = merchant
        markup = parseFloat(markup) || 0;
        if(markup < 0) markup = 0;

        newState.item.has_delivery_fine_prints = has_delivery_fine_prints
        newState.item.vendor_remarks = remarks

        if(_.includes(match.path, 'create') && !isAdmin){
          newState.item.vendor = name
          newState.item.enable_vm = virtual_message
          newState.item.enable_qna = qna
          newState.item.exclude_free_message = exclude_free_message
          newState.item.remove_pmg = markup && markup > 0 && markup_type

          let tagsSplit = []
          if (virtual_message) {
            tagsSplit.push('enable_vm')
          }
          if (qna) {
            tagsSplit.push('enable_qna')
          }
          if (exclude_free_message) {
            tagsSplit.push('exclude-free-message')
          }
          if (markup && markup > 0 && markup_type) {
            tagsSplit.push('remove_pmg')
          }
          newState.item.tags = tagsSplit.join(', ')
        }

        if (markup && markup > 0 && markup_type) {
          newState.defaultMarkup = markup
          newState.defaultMarkupType = markup_type

          let { variants } = this.state.item;

          let mu_index = _.findIndex(variants[0].metafields, m => m.key === 'markup')
          let mt_index = _.findIndex(variants[0].metafields, m => m.key === 'markup_type')

          let markupTypeMeta = { ...variants[0].metafields[mt_index] }

          let markupMeta = { ...variants[0].metafields[mu_index] }

          markupTypeMeta.value = markup_type

          newState.item.variants[0].metafields[mt_index] = markupTypeMeta
          newState.item.variants[0].markup_type = markup_type

          markupMeta.value = markup;

          newState.item.variants[0].markup = markup
          newState.item.variants[0].metafields[mu_index] = markupMeta
        }

        this.setState(newState)
      }
    })
  }

  handleOnPreviewProduct = () => {
    const { setPreviewProduct } = this.props;

    let { body } = this.getItemBody();

    setPreviewProduct(body)

    window.open('/products/preview', 'child-window')
  };

  setAttributes = async () => {
    const { item } = this.state;
    const { match, taggings: { items: predefinedTags } } = this.props;

    const { attributes, item: newItem, initialMetafieldAddOns, initialMetafieldInputs } = await generateAttr(item, dayOptions, closed, predefinedTags, match)

    function createOptions(taggings) {
      return _.map(taggings, tag => {
        return {
          label: tag.desc,
          value: tag.tag
        }
      })
    }

    this.setState({
      item: newItem,
      initialMetafieldAddOns,
      initialMetafieldInputs,
      attributes: {
        ...attributes,
        customizeFields: _.sortBy(attributes.customizeFields, 'seq'),
        selectOptions: {
          dayOptions,
          locationOptions: createOptions(predefinedTags['Location']),
          occasionOptions: createOptions(predefinedTags['Occasions']),
          forWhoOptions: createOptions(predefinedTags['For Who']),
          flowerTypeOptions: createOptions(predefinedTags['Flower Type']),
        }
      }
    })
  }

  fetchProduct = async () => {
    if (this.toastIdRef.current) {
      toast.dismiss(this.toastIdRef.current);
      this.toastIdRef.current = undefined;
    }
    const { match } = this.props;
    const { item } = this.state;

    const result = await api(`/products/${match.params.productId}`)

    if(result.errors){
      return this.setState({
        loading: false,
        displayProductCreateFail: true,
        displayProductCreateSuccess: false,
        errorMessage: result.message,
        not_found: true
      })
    }

    if (!result.id) {
      return this.setState({
        loading: false,
        not_found: true
      })
    }

    let sold_out = _.map(result.variants, variant => {
      return (variant.inventory_management === 'shopify' && variant.inventory_quantity <= 0) ? true : false
    })

    let shipping_delivery_included = _.map(result.variants, variant => {
      return parseFloat(variant.weight) <= 0.01
    })

    let variants = _.map(result.variants, variant => {
      const { price } = variant

      const markup = _.find(variant.metafields, m => m.key === 'markup')
      const markup_type = _.find(variant.metafields, m => m.key === 'markup_type')
      const base_price = _.find(variant.metafields, m => m.key === 'base_price')

      let result = { ...variant }
      if(!result.metafields){
        result.metafields = []
      }

      if(markup){
        result.markup = markup.value
      } else {
        result.markup = '0'
        result.metafields.push({
          namespace: 'price',
          key: 'markup',
          value: '0',
          type: 'number_decimal',
        })
      }

      if(markup_type){
        result.markup_type = markup_type.value
      } else {
        result.markup_type = 'fixed'
        result.metafields.push({
          namespace: 'price',
          key: 'markup_type',
          value: 'fixed',
          type: 'single_line_text_field',
        })
      }

      if(base_price){
        result.base_price = base_price.value
      } else {
        result.base_price = parseFloat(price) - parseFloat(result.markup)
        result.metafields.push({
          namespace: 'price',
          key: 'base_price',
          value: parseFloat(price) - parseFloat(result.markup),
          type: 'number_decimal',
        })
      }

      return result
    })

    if (variants.length < 1) {
      variants = [
        {
          option1: 'Default Title',
          option2: null,
          option3: null,
          price: '0',
          compare_at_price: '',
          sku: '',
          inventory_management: null,
          inventory_quantity: 0,
          selected_image: {},
          requires_shipping: true,
          weight: '',
          weight_unit: 'kg',
          metafields: [{
            namespace: 'price',
            key: 'markup_type',
            value: 'fixed',
            type: 'single_line_text_field',
          }, {
            namespace: 'price',
            key: 'base_price',
            value: '0',
            type: 'number_decimal',
          }, {
            namespace: 'price',
            key: 'markup',
            value: '0',
            type: 'number_decimal',
          }],
        }
      ],
      sold_out = [...sold_out, false],
      shipping_delivery_included = [...shipping_delivery_included, true]
    }

    let corporate = result.template_suffix === 'bulk' || result.tags.includes('cgift');

    //Delivery fine prints
    const $ = cheerio.load(result.body_html || '', null, false);

    let deliveryFinePrintsHtml = $('#delivery_fine_prints').html()
    if (!deliveryFinePrintsHtml || deliveryFinePrintsHtml === 'null') {
      deliveryFinePrintsHtml = ''
    }

    $('#delivery_fine_prints').remove()

    $('#pickup_info').remove()

    result.body_html = $.html()
    if (corporate) {
      result.lead_time_fine_prints_html = deliveryFinePrintsHtml
    } else {
      result.delivery_fine_prints_html = deliveryFinePrintsHtml
    }

    let tagSplit = result.tags ? result.tags.split(',') : []
    //trim tagSplit
    tagSplit = _.map(tagSplit, _.trim)
    //check if tagSplit contains any entry start with "dfp_"
    let dfp = _.find(tagSplit, function (tag) {
      return _.startsWith(tag, 'dfp_');
    });
    if (dfp) {
      result.dpfTemplateAssigned = true
    }

    delete result.has_delivery_fine_prints
    let state = {
      item: {
        ...item,
        ...result,
        variants,
      },
      sold_out,
      shipping_delivery_included,
      has_variants: result.variants.length > 1,
    }

    if (result && _.includes(match.path, 'pending')){
      state.pending = result.approval_status == 'pending'
    }

    if (result && result.tags) {

      state.item.enable_vm = result.tags.includes('enable_vm');
      state.item.enable_qna = result.tags.includes('enable_qna');
      state.item.remove_pmg = result.tags.includes('remove_pmg');
      state.item.exclude_free_message = result.tags.includes('exclude-free-message');
      state.item.enable_postcode = result.tags.includes('enable_postcode');
      state.item.enable_addons = result.tags.includes(`ADDONS_${handleize(result.vendor)}`);
      state.item.corporate = corporate

      let tagsSplit = result.tags.split(', ');
      const typeRegex = new RegExp(/^type_/, 'i')
      state.item.alt_product_type = tagsSplit.reduce((acc, tag) => {
        if (typeRegex.test(tag)) {
          let type = tag.match(/^type_(.*)/i);

          if (type && type[1] !== result.product_type){
            acc.push({
              label: type[1],
              value: type[1]
            })
          }
        }
        return acc
      }, [])

      let foundBulkLink = tagsSplit.find(tag => _.startsWith(tag, 'Link_'))
      let foundPendingBulkLink = tagsSplit.find(tag => _.startsWith(tag, 'pending_link_'))
      if (foundBulkLink) {
        state.item.bulk_link = foundBulkLink
      } else if (foundPendingBulkLink) {
        state.item.bulk_link = foundPendingBulkLink
      }

      if (corporate) {
        _.each(result.variants, (variant, i) => {
          const optionSplit = variant.option1.split(' - ')

          state.item.variants[i].min = optionSplit[0]
          state.item.variants[i].max = optionSplit[1]

          let price_compare = _.find(tagsSplit, function (tag) {
            return _.startsWith(tag, `price_compare`);
          });

          if (price_compare) {
            state.item.corporate_compare_at_price = _.split(price_compare, '_')[2]
          }

          let tier = _.find(tagsSplit, function (tag) {
            return _.startsWith(tag, `TIER#${optionSplit.join('-')}`);
          });

          if (!tier) {
            return
          }

          if (country === 'MY') {
            let west_single = tier.match(/ws\d*(\.\d*)?/i)
            if (west_single) {
              west_single = parseFloat(west_single[0].slice(2))
            }

            let west_multi = tier.match(/wm\d*(\.\d*)?/i)
            if (west_multi) {
              west_multi = parseFloat(west_multi[0].slice(2))
            }

            let east_single = tier.match(/es\d*(\.\d*)?/i)
            if (east_single) {
              east_single = parseFloat(east_single[0].slice(2))
            }

            let east_multi = tier.match(/em\d*(\.\d*)?/i)
            if (east_multi){
              east_multi = parseFloat(east_multi[0].slice(2))
            }

            let west_lead_min = tier.match(/wlmin\d*(\.\d*)?/i)
            if (west_lead_min){
              west_lead_min = parseFloat(west_lead_min[0].slice(5))
            }

            let west_lead_max = tier.match(/wlmax\d*(\.\d*)?/i)
            if (west_lead_max){
              west_lead_max = parseFloat(west_lead_max[0].slice(5))
            }

            let east_lead_min = tier.match(/elmin\d*(\.\d*)?/i)
            if (east_lead_min){
              east_lead_min = parseFloat(east_lead_min[0].slice(5))
            }

            let east_lead_max = tier.match(/elmax\d*(\.\d*)?/i)
            if (east_lead_max){
              east_lead_max = parseFloat(east_lead_max[0].slice(5))
            }

            state.item.variants[i].shipping = {
              west_single,
              west_multi,
              east_single,
              east_multi,
            }

            state.item.variants[i].lead_time = {
              west_lead_min,
              west_lead_max,
              east_lead_min,
              east_lead_max,
            }

          } else {
            let single = tier.match(/s\d*(\.\d*)?/i)
            if (single) {
              single = parseFloat(single[0].slice(1))
            }

            let multiple = tier.match(/m\d*(\.\d*)?/i)
            if (multiple) {
              multiple = parseFloat(multiple[0].slice(1))
            }

            let lead_min = tier.match(/lmin\d*(\.\d*)?/i)
            if (lead_min){
              lead_min = parseFloat(lead_min[0].slice(4))
            }

            let lead_max = tier.match(/lmax\d*(\.\d*)?/i)
            if (lead_max){
              lead_max = parseFloat(lead_max[0].slice(4))
            }

            state.item.variants[i].shipping = {
              single,
              multiple,
            }

            state.item.variants[i].lead_time = {
              lead_min,
              lead_max,
            }
          }
        })
      }
    }

    this.setState(state)
  }

  setBase64ToFile = async (base64) => {
    base64 = base64.replace('data:image/jpeg;base64,', '')

    const imageContent = atob(base64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });

    return new File([blob], 'image.jpeg', { lastModified: new Date().getTime(), type })
  }

  updateImage = async (image, mediaId, index) => {
    const { item } = this.state;
    const { match } = this.props;
    let { images, media } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    let file = await this.setBase64ToFile(image);

    if (!isLiveProduct && index >= 0) {
      var formData = new FormData();
      formData.append('product_image', file)

      const data = await api(`/products/images`, {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})

      this.setItemState({
        ...item,
        images: [
          ...images.slice(0, index),
          {
            ...images[index],
            src: data.link,
          },
          ...(images.slice(index + 1))
        ]
      })
      return true
    } else if (!isLiveProduct || !mediaId) {
      return false
    }

    try {
      var formData = new FormData();
      formData.append('media', file)
      formData.append('mediaId', mediaId)

      await api(`/products/update_image/${item.id}`, {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})

      let updateIndex = _.findIndex(media, { id: mediaId })

      this.setItemState({
        ...item,
        media: [
          ...media.slice(0, updateIndex),
          {
            ...media[updateIndex],
            preview: image,
            src: image,
          },
          ...(media.slice(updateIndex + 1))
        ]
      }, false)

      return true
    } catch(err) {
      console.log(err)
      toast.error('Something went wrong');
      return false
    }
  }

  onDropImage = async files => {
    if (!files || files.length === 0) {
      return toast.error('Invalid filetype/no file selected');
    }

    const { item } = this.state;
    const { match } = this.props;
    let { images, media } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    const upload = async (files) => {
      this.setState({
        uploadingFile: true
      })

      if (isLiveProduct) {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('media', files[i])
        }

        const result = await api(`/products/add_media/${item.id}`, {
          method: 'POST',
          body: formData
        }, {'Accept': 'application/json'})

        let newMedia = result.data.productCreateMedia.media

        let newMediaState = []
        _.each(newMedia, (mediaItem) => {
          newMediaState.push({ id: mediaItem.id })
        })

        this.setItemState({
          ...item,
          media: [
            ...media,
            ...newMediaState
          ]
        }, false)
      } else {
        let newImages = []
        for (let i = 0; i < files.length; i++) {
          var formData = new FormData();
          formData.append('product_image', files[i])

          const data = await api(`/products/images`, {
            method: 'POST',
            body: formData
          }, {'Accept': 'application/json'})

          newImages.push({ src: data.link })
        }

        this.setItemState({
          ...item,
          images: [
            ...images,
            ...newImages
          ]
        })
      }

      this.setState({ uploadingFile: false })
    }

    return Promise.map(files, (file) => {
      if (file.type.includes('image')) {

        if (files[0].size > 20971520) {
          return Promise.reject('Image file size too big');
        }

        return new Promise((resolve, reject) => {
          let imageObjectUrl = URL.createObjectURL(file);
          let image = new Image();
          image.onload = function() {

            if (this.width > 4472 || this.height > 4472) {
              URL.revokeObjectURL(imageObjectUrl);
              return reject('Image height/width too big');
            }
            URL.revokeObjectURL(imageObjectUrl);

            resolve();
          }

          image.src = imageObjectUrl;
        })
      }

      if (file.type.includes('video')) {
        if (files[0].size > 52428800) {
          return Promise.reject('Video file size too big');
        }

        let videoObjectUrl = URL.createObjectURL(file);
        const video = document.createElement('video')
        video.addEventListener('loadedmetadata', () => {

          if (video.videoWidth > 4096 || video.videoHeight > 2160) {
            video.remove();
            return reject('Video resolution too big');
          }
          URL.revokeObjectURL(videoObjectUrl);

          video.remove();
          resolve();
        });

        video.src = videoObjectUrl
      }
    }, { concurrency: 1 }).then(() => {
      return upload(files);
    }).catch((err) => {
      toast.error(err.message || err)
    });

  }

  saveYTLink = async (videoId) => {
    const { item } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      const result = await api(`/products/youtube_link/${item.id}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ id: videoId })
      })

      let newMedia = result.data.productCreateMedia.media

      let newMediaState = []
      _.each(newMedia, (mediaItem) => {
        newMediaState.push({
          id: mediaItem.id,
          isYoutube: true,
        })
      })

      this.setItemState({
        ...item,
        media: [
          ...media,
          ...newMediaState
        ]
      }, false)
    } else {
      this.setItemState({
        ...item,
        images: [
          ...images,
          {
            src: `https://youtu.be/${videoId}`,
            isYoutube: true,
            thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`,
          }
        ]
      })
    }
  }

  onClickHasVariants = e => {
    this.setState({ has_variants: e.target.checked })
  }

  onClickB2B = e => {
    const { item } = this.state

    this.setState({
      item: {
        ...item,
        b2b: e.target.checked
      }
    })
  }

  addVariant = () => {
    const { item, sold_out, shipping_delivery_included, defaultMarkup, defaultMarkupType } = this.state;
    const { variants, options } = item
    const optionsEdit = options

    for(let option of optionsEdit) {
      option.values.push("")
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants,
        {
          option1: '',
          option2: '',
          option3: '',
          price: '0',
          compare_at_price: '',
          sku: '',
          inventory_management: null,
          inventory_quantity: 0,
          selected_image: {},
          requires_shipping: true,
          weight: '',
          weight_unit: 'kg',
          metafields: [{
            namespace: 'price',
            key: 'markup_type',
            value: defaultMarkupType || 'fixed',
            type: 'single_line_text_field',
          }, {
            namespace: 'price',
            key: 'base_price',
            value: '0',
            type: 'number_decimal',
          }, {
            namespace: 'price',
            key: 'markup',
            value: defaultMarkup || '0',
            type: 'number_decimal',
          }],
          isDirty: true
        }
      ],
      options: [
        ...optionsEdit
      ],
    })

    this.setState({ shipping_delivery_included: [...shipping_delivery_included, true] })
  }

  removeTier = e => {
    const { id } = e.currentTarget;
    const index = parseInt(id.slice(-1))
    const { item } = this.state;

    let { tags, variants, options, removeVariants = [] } = item;

    let tagsSplit = tags.split(', ')
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }
    tagsSplit = _.filter(tagsSplit, function (tag) {
      return !_.startsWith(tag, `TIER#${variants[index].min}-${variants[index].max}`);
    });

    options[0].values = _.filter(options[0].values, (o) => o !== `${variants[index].min} - ${variants[index].max}`)

    variants = _.filter(variants, (variant, i) => {
      if (i == index) {
        removeVariants.push(variant.id)
      }
      return i != index
    })

    this.setItemState({
      ...item,
      variants,
      options,
      removeVariants,
      tags: tagsSplit.join(', '),
    })
  }

  removeVariant = e => {
    const { id } = e.currentTarget;
    const index = id.replace('remove_', '')
    const { item } = this.state;
    let { variants, options, removeVariants = [] } = item;

    variants = _.filter(variants, (variant, i) => {
      if (i == index) {
        removeVariants.push(variant.id)
      }
      return i != index
    })

    _.each(options, (opt, i) => {
      opt.values = _.filter(opt.values, (o, i) => i != index)
    })

    this.setItemState({
      ...item,
      removeVariants,
      variants,
      options,
    })
  }

  updateForm = e => {
    const { item, merchantsOptions, merchants } = this.state;
    let { name, value, checked } = e.target;

    if (_.includes(name, 'options')){
      const { options } = item
      const index = parseInt(name.slice(-1))

      this.setItemState({
        ...item,
        options: [
          ...options.slice(0, index),
          {
            ...options[index],
            name: value
          },
          ...options.slice(index + 1)
        ]
      })
      return
    }

    if(name === 'enable_qna' || name === 'enable_vm' || name === 'exclude_free_message' || name === 'remove_pmg' || name === 'enable_postcode' || name === 'enable_addons' || name === 'corporate'){
      value = checked
    }

    let saveItem = {
      ...item,
      [name]: value
    }

    if (name === 'status' && value === 'active') {
      saveItem.published = true
    }

    // non attributes related tags
    let tagsSplit = item.tags.split(', ')
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }

    if(name === 'vendor'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^vendor_|^$/i.test(tag)))
      tagsSplit.push(`Vendor_${value}`)
      if (_.includes(this.props.match.path, 'create')) {
        tagsSplit = _.filter(tagsSplit, tag => !(/^addons_|^$/i.test(tag)))
        if (item.enable_addons) {
          tagsSplit.push(`ADDONS_${handleize(value)}`)
        }
      }

      let merchant = merchants.find(merchant => merchant.name === value)
      let { virtual_message, qna, exclude_free_message, markup, markup_type, has_delivery_fine_prints, remarks } = merchant

      saveItem.enable_vm = Boolean(virtual_message)
      saveItem.enable_qna = Boolean(qna)
      saveItem.exclude_free_message = Boolean(exclude_free_message)
      saveItem.has_delivery_fine_prints = has_delivery_fine_prints
      saveItem.vendor_remarks = remarks

      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_vm$|^enable_qna$|^remove_pmg$|^exclude-free-message$|^$/i.test(tag)))
      if(virtual_message){
        tagsSplit.push('enable_vm')
      }
      if(qna){
        tagsSplit.push('enable_qna')
      }
      if (exclude_free_message) {
        tagsSplit.push('exclude-free-message')
      }
      if (markup && markup > 0 && markup_type) {
        saveItem.remove_pmg = true
        tagsSplit.push('remove_pmg')

        let { variants } = item;

        _.each(variants, (variant, i) => {
          const { base_price, metafields } = variant;

          let mu_index = _.findIndex(metafields, m => m.key === 'markup')
          let mt_index = _.findIndex(metafields, m => m.key === 'markup_type')

          let markupTypeMeta = { ...metafields[mt_index] }

          let markupMeta = { ...metafields[mu_index] }

          markupTypeMeta.value = markup_type

          saveItem.variants[i].metafields[mt_index] = markupTypeMeta
          saveItem.variants[i].markup_type = markup_type

          markup = parseFloat(markup) || 0;
          if(markup < 0) markup = 0;

          markupMeta.value = markup;

          saveItem.variants[i].markup = markup
          saveItem.variants[i].metafields[mu_index] = markupMeta

          if (markupTypeMeta.value === 'percentage') {
            saveItem.variants[i].price = parseFloat(base_price * markupMeta.value / 100) + parseFloat(base_price)
          } else if (markupTypeMeta.value === 'fixed') {
            saveItem.variants[i].price = parseFloat(markupMeta.value) + parseFloat(base_price)
          }
        })
      } else {
        saveItem.remove_pmg = false
      }
    }

    if(name === 'product_type' || name === 'alt_product_type'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^type_|^$/i.test(tag)))
      let altTypes = _.map(saveItem.alt_product_type, type => type.value)
      let allTypes = [saveItem.product_type, ...altTypes]
      _.each(allTypes, type => {
        tagsSplit.push(`Type_${type}`)
      })
    }
    if(name === 'enable_vm'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_vm$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_vm')
      }
    }
    if(name === 'enable_qna'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_qna$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_qna')
      }
    }
    if (name === 'exclude_free_message') {
      tagsSplit = _.filter(tagsSplit, tag => !(/^exclude-free-message$|^$/i.test(tag)))
      if (value) {
        tagsSplit.push('exclude-free-message')
      }
    }
    if(name === 'remove_pmg'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^remove_pmg$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('remove_pmg')
      }
    }
    if(name === 'enable_postcode'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_postcode$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_postcode')
      }
    }
    if(name === 'enable_addons'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^addons_|^$/i.test(tag)))
      if(value){
        tagsSplit.push(`ADDONS_${handleize(saveItem.vendor)}`)
      }
    }

    if (name === 'delivery_link') {
      tagsSplit = _.filter(tagsSplit, tag => !(/^DLINK_|^$/i.test(tag)))
      if(value){
        tagsSplit.push(value)
      }
    }

    if (name === 'corporate') {
      tagsSplit = _.filter(tagsSplit, tag => !(/^cgift$|^$/i.test(tag)))
      if (value) {
        tagsSplit = _.filter(tagsSplit, tag => !(/^bia|^ubia|^bng$|^is_addon$|^$/i.test(tag)))
        tagsSplit.push('cgift')
        saveItem.options = [{
          ...saveItem.options[0],
          name: 'Quantity',
        }]
        _.each(saveItem.variants, variant => {
          variant.option2 = ''
          variant.option3 = ''
        })
      }
    }

    if (name === 'corporate_compare_at_price') {
      tagsSplit = _.filter(tagsSplit, tag => !(/^price_compare_|^$/i.test(tag)))
      if (value && value > 0) {
        tagsSplit.push(`price_compare_${value}`)
      }
    }

    saveItem.tags = tagsSplit.join(', ')

    this.setItemState(saveItem)
  }

  updateVariants = e => {
    const { item, sold_out, shipping_delivery_included, attributes: { delivery_type } } = this.state;
    let { name, value, checked } = e.target;

    const nameSplit = _.split(name, '#')
    const { variants, options } = item;
    const variantIndex = parseInt(nameSplit[1])

    variants[variantIndex].isDirty = true

    switch (true) {
      case nameSplit[0] === 'soldout':
        this.setState({
          sold_out: [
            ...sold_out.slice(0, variantIndex),
            checked,
            ...sold_out.slice(variantIndex + 1)
          ],
        })
        this.setItemState({
          ...item,
          variants: [
            ...variants.slice(0, variantIndex),
            {
              ...variants[variantIndex],
              inventory_quantity: 0,
              inventory_management: 'shopify'
            },
            ...variants.slice(variantIndex + 1)
          ],
        });
        return

      case _.includes(name, 'tier'):
        const { pricing_tier } = item
        const index = parseInt(name.slice(-1))
        name = nameSplit[0].replace('tier_', '')
        value = parseInt(value)

        if(_.includes(name, 'max_quantity')){
          this.setItemState({
            ...item,
            pricing_tier: [
              ...pricing_tier.slice(0, index),
              {
                ...pricing_tier[index],
                max_quantity: value
              },
              ...(Boolean(pricing_tier[index+1]) && [
                {
                  ...pricing_tier[index+1],
                  min_quantity: value + 1
                },
                ...pricing_tier.slice(index + 2)
              ]),
            ]
          })
        } else {
          this.setItemState({
            ...item,
            pricing_tier: [
              ...pricing_tier.slice(0, index),
              {
                ...pricing_tier[index],
                [name]: value < 0 ? 0 : value
              },
              ...pricing_tier.slice(index + 1)
            ]
          });
        }
        return;

      case nameSplit[0] === 'shipping_delivery_included':
        let weight

        if (checked && delivery_type === 'courier') {
          if (country === 'MY') {
            weight = '0.001'
          }
          if (country === 'SG') {
            weight = '0'
          }
        }

        this.setState({
          shipping_delivery_included: [
            ...shipping_delivery_included.slice(0, variantIndex),
            checked,
            ...shipping_delivery_included.slice(variantIndex + 1)
          ],
        })
        this.setItemState({
          ...item,
          variants: [
            ...variants.slice(0, variantIndex),
            {
              ...variants[variantIndex],
              requires_shipping: true,
              ...(weight !== undefined && { weight }),
            },
            ...variants.slice(variantIndex + 1)
          ],
        })
        return;

      case nameSplit[0] === 'requires_shipping':
        value = !checked // checkbox is shipping NOT required
        break;

      case nameSplit[0] === 'inventory_management':
        value = checked === true ? 'shopify' : null
        break;

      case nameSplit[0] === 'inventory_policy':
        value = checked === true ? 'continue' : 'deny'
        break;

      case nameSplit[0] === 'inventory_quantity':
        value = Math.max(0, value)
        break;
    }

    function updateOptions(){
      const optionIndex = parseInt(nameSplit[0].slice(-1)) - 1;
      const { values } = options[optionIndex];

      return { options: [
        ...options.slice(0, optionIndex),
        {
          ...options[optionIndex],
          values: [
            ...values.slice(0, variantIndex),
            value,
            ...values.slice(variantIndex + 1)
          ]
        },
        ...options.slice(optionIndex + 1)
      ]}
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants.slice(0, variantIndex),
        {
          ...variants[variantIndex],
          [nameSplit[0]]: value
        },
        ...variants.slice(variantIndex + 1)
      ],
      ...(_.includes(name, 'option') && updateOptions())
    });
  }

  updateTiers = (e) => {
    const { item } = this.state;
    const { variants, options } = item;
    let { name, value } = e.target
    const nameSplit = _.split(name, '#')

    value = parseFloat(value) || 0;
    if(value < 0) value = 0;

    const variantIndex = parseInt(nameSplit[1])
    const variant = variants[variantIndex]
    let min = variant.min
    let max = variant.max

    let optionIndex = variantIndex

    let body = {...item}

    if (name.includes('min_qty') || name.includes('max_qty')) {
      min = name.includes('min_qty') ? value : min
      max = name.includes('max_qty') ? value : max
    } else if (name.includes('lead_min') || name.includes('lead_max')) {
      if (body.variants[variantIndex].lead_time) {
        body.variants[variantIndex].lead_time[nameSplit[0]] = value
      } else {
        body.variants[variantIndex].lead_time = {
          [nameSplit[0]]: value
        }
      }
    } else if (name.includes('single') || name.includes('multi')) {
      if (body.variants[variantIndex].shipping) {
        body.variants[variantIndex].shipping[nameSplit[0]] = value
      } else {
        body.variants[variantIndex].shipping = {
          [nameSplit[0]]: value
        }
      }
    }

    body.options[0].values[optionIndex] = `${min || ''} - ${max || ''}`
    body.variants[variantIndex].option1 = `${min || ''} - ${max || ''}`
    body.variants[variantIndex].min = min
    body.variants[variantIndex].max = max
    body.variants[variantIndex].showLimit = max * body.variants[variantIndex].base_price > 5000

    let tagsSplit = item.tags.split(', ')
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }
    tagsSplit = _.filter(tagsSplit, tag => !_.startsWith(tag, `TIER#`))
    _.each(body.variants, (variant) => {
      tagsSplit.push(country === 'sg' ? generateTierTagSG(variant) : generateTierTag(variant))
    })
    body.tags = tagsSplit.join(', ')

    return this.setItemState({ ...body }, true, this.generateLeadTimeFinePrints)
  }

  generateLeadTimeFinePrints = () => {
    const { item } = this.state;
    const { variants } = item;

    let hasEastLeadTime = _.every(variants, variant => variant.lead_time && variant.lead_time.east_lead_min && variant.lead_time.east_lead_max);
    let hasWestLeadTime = _.every(variants, variant => variant.lead_time && variant.lead_time.west_lead_min && variant.lead_time.west_lead_max);
    let hasSGLeadTime = _.every(variants, variant => variant.lead_time && variant.lead_time.lead_min && variant.lead_time.lead_max);

    if (!hasEastLeadTime && !hasWestLeadTime && !hasSGLeadTime) return;

    let html = '<p><strong>Lead Time:</strong></p>'

    if (hasWestLeadTime) {
      html += '<p><u>West Malaysia:</u></p><p>'
      _.each(variants, ({ min, max, lead_time: { west_lead_min, west_lead_max } }) => {
        html += `${min} - ${max} units: ${west_lead_min}-${west_lead_max} working days<br />`
      })
      html += '</p>'
    }

    if (hasEastLeadTime) {
      html += '<p><u>East Malaysia:</u></p><p>'
      _.each(variants, ({ min, max, lead_time: { east_lead_min, east_lead_max } }) => {
        html += `${min} - ${max} units: ${east_lead_min}-${east_lead_max} working days<br />`
      })
      html += '</p>'
    }

    if (hasSGLeadTime) {
      html += '<p><u>Singapore:</u></p><p>'
      _.each(variants, ({ min, max, lead_time: { lead_min, lead_max } }) => {
        html += `${min} - ${max} units: ${lead_min}-${lead_max} working days<br />`
      })
      html += '</p>'
    }

    html += '<p>The vendor is not responsible for delays caused by courier service truly appreciate your kind understanding.</p>'

    this.setItemState({
      ...item,
      lead_time_fine_prints_html: html,
    });
  }

  addTier = () => {
    const { item, shipping_delivery_included, defaultMarkup, defaultMarkupType } = this.state;
    const { variants, options } = item
    const optionsEdit = options

    for(let option of optionsEdit) {
      option.values.push("")
    }

    let newVariant = {
      option1: '',
      option2: '',
      option3: '',
      price: '0',
      compare_at_price: '',
      sku: '',
      inventory_management: null,
      inventory_quantity: 0,
      selected_image: {},
      requires_shipping: true,
      weight: '',
      weight_unit: 'kg',
      metafields: [{
        namespace: 'price',
        key: 'markup_type',
        value: defaultMarkupType || 'fixed',
        type: 'single_line_text_field',
      }, {
        namespace: 'price',
        key: 'base_price',
        value: '0',
        type: 'number_decimal',
      }, {
        namespace: 'price',
        key: 'markup',
        value: defaultMarkup || '0',
        type: 'number_decimal',
      }],
      isDirty: true,
      min: '',
      max: '',
      lead_time: {},
      shipping: {},
    }

    if (country === 'MY') {
      newVariant.shipping = {
        east_single: 0,
        east_multi: 0,
        west_single: 0,
        west_multi: 0
      }
    } else {
      newVariant.shipping = {
        single: 0,
        multi: 0
      }
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants,
        newVariant
      ],
      options: [
        ...optionsEdit
      ],
    })

    this.setState({ shipping_delivery_included: [...shipping_delivery_included, true] })
  }

  updateOptionsAndVariants = (variants, options, newVariants = []) => {
    let { item, shipping_delivery_included, defaultMarkup, defaultMarkupType } = this.state;

    for (let i = 0; i < newVariants.length; i++) {
      shipping_delivery_included.push(shipping_delivery_included[0]);
    }

    _.each(newVariants, (variant) => {
      let base_price = item.variants[0].price
      let price = 0
      if (defaultMarkupType === 'percentage') {
        price = parseFloat(base_price * defaultMarkup / 100) + parseFloat(base_price)
        price = _.ceil(price, 1).toFixed(2)
      } else if (defaultMarkupType === 'fixed') {
        price = (parseFloat(defaultMarkup) + parseFloat(base_price)).toFixed(2)
      }

      variants = [
        ...variants,
        {
          ...variant,
          base_price,
          price,
          compare_at_price: '',
          sku: '',
          inventory_management: item.variants[0].inventory_management,
          inventory_quantity: item.variants[0].inventory_quantity,
          selected_image: {},
          weight: item.variants[0].weight,
          weight_unit: 'kg',
          metafields: [{
            namespace: 'price',
            key: 'markup_type',
            value: defaultMarkupType || 'fixed',
            type: 'single_line_text_field',
          }, {
            namespace: 'price',
            key: 'base_price',
            value: item.variants[0].price,
            type: 'number_decimal',
          }, {
            namespace: 'price',
            key: 'markup',
            value: defaultMarkup || '0',
            type: 'number_decimal',
          }],
          new: true,
          isDirty: true
        }
      ]
    })

    let removeVariants = _.map(_.filter(variants, v => v.toRemove), v => {
      return {
        id: v.id,
        option1: v.option1,
        option2: v.option2,
        option3: v.option3
      }
    })

    let tagsSplit = item.tags.split(', ')
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }

    tagsSplit = _.filter(tagsSplit, tag => !(/^vcond_|^$/i.test(tag)))
    _.each(options, (option) => {
      if (option.conditionalFields && option.conditionalFields.length > 0) {
        _.each(option.conditionalFields, condField => {
          if (!condField.selected) {
            return
          }
          let selected = condField.selected.map(selected => selected.value)
          tagsSplit.push(`VCOND_${condField.primaryTitle}_${option.position - 1}_${selected.join('#')}`)
        })
      }
    })

    item.tags = tagsSplit.join(', ')

    this.setItemState({
      ...item,
      variants,
      options,
      removeVariants,
    }, true, () => this.updateAttrTags());

    this.setState({ shipping_delivery_included })
    if (newVariants.length) {
      toast.success(`${newVariants.length} new variant(s) created!`);
    }
  }

  handleBasePrice = e => {
    let { name, value } = e.target;

    value = parseFloat(value) || 0;
    if(value < 0) value = 0;

    const nameSplit = _.split(name, '#')
    const { variants } = this.state.item;
    const index = parseInt(nameSplit[1])

    let bp_index = _.findIndex(variants[index].metafields, m => m.key === 'base_price')

    let base_price = {
      ...variants[index].metafields[bp_index],
      value
    }

    let item = { ...this.state.item };

    item.variants[index][nameSplit[0]] = value
    item.variants[index].metafields[bp_index] = base_price
    item.variants[index].showLimit = item.variants[index].max * item.variants[index].base_price > 5000

    this.setItemState(item, true, () => this.calculateTotalPrice(index));
  }

  handleMarkup = e => {
    const { user } = this.props;
    if(user.role !== 'admin' && user.role !== 'staff'){
      return
    }

    let { name, value } = e.target;
    const nameSplit = _.split(name, '#')
    const { variants } = this.state.item;
    const index = parseInt(nameSplit[1])

    let mu_index = _.findIndex(variants[index].metafields, m => m.key === 'markup')
    let mt_index = _.findIndex(variants[index].metafields, m => m.key === 'markup_type')

    let markup_type = { ...variants[index].metafields[mt_index] }

    let markup = { ...variants[index].metafields[mu_index] }

    let item = { ...this.state.item };

    if (nameSplit[0] === 'markup_type') {
      markup_type.value = value

      item.variants[index].metafields[mt_index] = markup_type
      item.variants[index].markup_type = value
    } else if (nameSplit[0] === 'markup') {
      value = parseFloat(value) || 0;
      if(value < 0) value = 0;

      markup.value = value;

      item.variants[index].markup = value
      item.variants[index].metafields[mu_index] = markup
    }

    let tagsSplit = item.tags.split(', ')
    tagsSplit = _.filter(tagsSplit, tag => !(/^remove_pmg$|^$/i.test(tag)))

    let hasMarkup = _.some(item.variants, variant => {
      let markupMeta = _.find(variant.metafields, m => m.key === 'markup')
      let markupTypeMeta = _.find(variant.metafields, m => m.key === 'markup_type')

      return markupMeta && markupMeta.value && markupMeta.value > 0 && markupTypeMeta && markupTypeMeta.value
    })

    if (hasMarkup) {
      item.remove_pmg = true
      tagsSplit.push('remove_pmg')
    } else {
      item.remove_pmg = false
    }

    item.tags = tagsSplit.join(', ')

    this.setItemState(item, true, () => this.calculateTotalPrice(index));
  }

  calculateTotalPrice = (index) => {
    let item = { ...this.state.item };
    const { base_price, metafields } = item.variants[index];

    let markup = _.find(metafields, m => m.key === 'markup')
    let markup_type = _.find(metafields, m => m.key === 'markup_type')

    if (markup.value > 0 && markup_type.value === 'percentage') {
      let price = parseFloat(base_price * markup.value / 100) + parseFloat(base_price)
      item.variants[index].price = _.ceil(price, 1).toFixed(2)
    } else if (markup.value > 0 && markup_type.value === 'fixed') {
      item.variants[index].price = (parseFloat(markup.value) + parseFloat(base_price)).toFixed(2)
    } else {
      item.variants[index].price = parseFloat(base_price).toFixed(2)
    }

    item.variants[index].isDirty = true

    this.setItemState(item);
  }

  fixTierQty = (isMax) => {
    const { item, item: { pricing_tier } } = this.state;
    let fixed_tiers = [...pricing_tier]

    _.each(fixed_tiers, (tier, i) => {
      if(isMax){
        if (tier.max_quantity < tier.min_quantity){
          fixed_tiers[i].max_quantity = pricing_tier[i].min_quantity
          if(fixed_tiers[i+1]){
            fixed_tiers[i+1].min_quantity = pricing_tier[i].min_quantity + 1
          }
        }
      } else if (tier.min_quantity > tier.max_quantity){
        fixed_tiers[i].min_quantity = pricing_tier[i].max_quantity
      }
    })

    this.setItemState({
      ...item,
      pricing_tier: [
        ...fixed_tiers
      ]
    })
  }

  stripHtml = html => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  getItemBody = () => {
    const { item, has_variants, shipping_delivery_included, attributes: { delivery_type, self, customizeFields }, merchants, defaultMarkup, defaultMarkupType } = this.state;

    if(!this.stripHtml(item.body_html)){
      this.setState({
        submitting: false,
      })
      window.scrollTo(0, 400);
      toast.error('Please add a description')
      return
    }

    let fieldTitles = []
    for (let field of customizeFields) {
      if (_.includes(fieldTitles, field.primaryTitle)) {
        toast.error('Identical instructions detected, please provide unique instructions for each customise field')
        return
      } else {
        fieldTitles.push(field.primaryTitle)
      }
      if (field.conditionalFields) {
        for (let conditionalField of field.conditionalFields) {
          if (_.includes(fieldTitles, conditionalField.primaryTitle)) {
            toast.error('Identical instructions detected, please provide unique instructions for each customise field')
            return
          } else {
            fieldTitles.push(conditionalField.primaryTitle)
          }
        }
      }
    }
    for (let option of item.options) {
      if (option.conditionalFields) {
        for (let conditionalField of option.conditionalFields) {
          if (_.includes(fieldTitles, conditionalField.primaryTitle)) {
            toast.error('Identical instructions detected, please provide unique instructions for each customise field')
            return
          } else {
            fieldTitles.push(conditionalField.primaryTitle)
          }
        }
      }
    }

    if (self && (delivery_type === 'd2d' || delivery_type === 'seasonal')) {
      let foundSelf = false
      for (let variant of item.variants) {
        let title = variant.option1 + variant.option2 + variant.option3

        if (title && _.toLower(title).includes("pick-up") || _.toLower(title).includes("pickup") || _.toLower(title).includes("pick up") || _.toLower(title).includes("self")) {
          foundSelf = true
        }
      }
      if (!foundSelf) {
        this.setState({
          submitting: false,
        })
        toast.error(
          <span>Please create a new variant for Self Pick-up</span>,
          { duration: 5000 }
        )
        return
      }
    }

    let inventoryChanged = []
    // variants that require update inventory
    if(item.status == 'active' || item.status == 'draft'){
      for (let variant of item.variants){

        if(variant.id && variant.inventory_quantity !== variant.old_inventory_quantity){
          inventoryChanged.push({...variant})
          variant.inventory_quantity = variant.old_inventory_quantity
        }
      }
    }

    let $
    if (item.corporate) {
      $ = cheerio.load(item.lead_time_fine_prints_html || '', null, false)
    } else {
      $ = cheerio.load(item.delivery_fine_prints_html || '', null, false)
    }
    //check if $ root is a div
    if(!$('div').length){
      //if root is not a div, wrap it in a div and add id
      if (item.corporate) {
        item.delivery_fine_prints_html = `<div id="delivery_fine_prints">${item.lead_time_fine_prints_html || ''}</div>`
      } else {
        item.delivery_fine_prints_html = `<div id="delivery_fine_prints">${item.delivery_fine_prints_html || ''}</div>`
      }
    }

    item.body_html += item.delivery_fine_prints_html
    delete item.delivery_fine_prints_html
    delete item.b2bProduct
    delete item.b2cProduct

    let vendorTools = _.find(merchants, { name: item.vendor })
    if (self && (delivery_type === 'd2d' || delivery_type === 'seasonal')) {
      let pickup_info = ''
      if (country === 'MY' && vendorTools.pickup_address_postcode && vendorTools.pickup_address_city) {
        pickup_info = `<div id="pickup_info">Self pick-up is available in ${vendorTools.pickup_address_postcode}, ${vendorTools.pickup_address_city}. Full address will be provided once order is placed.</div>`
      } else if (country === 'SG' && vendorTools.pickup_address_postcode) {
        pickup_info = `<div id="pickup_info">Self pick-up is available in ${vendorTools.pickup_address_postcode}. Full address will be provided once order is placed.</div>`
      }

      if (pickup_info !== '') {
        item.body_html += pickup_info
      }
    }

    if (delivery_type !== 'seasonal') {
      let boDateRanges = [], offDays = []
      if (vendorTools.off_days) {
        boDateRanges = vendorTools.off_days.split(',')
        boDateRanges = _.filter(boDateRanges, range => {
          let offDaysSplit = range.split('-')
          return moment(offDaysSplit[1], 'DD/MM/YYYY').isAfter(moment().utcOffset(8), 'day')
        })
        for (let range of boDateRanges) {
          let offDaysSplit = range.split('-')
          let startDate = moment(offDaysSplit[0], 'DD/MM/YYYY')
          let endDate = moment(offDaysSplit[1], 'DD/MM/YYYY')

          while (startDate.isSameOrBefore(endDate, 'day')) {
            offDays.push('BO-' + startDate.format('DDMMYYYY'))
            startDate.add(1, 'days')
          }
        }

      }

      let boDatesApplied = false
      _.each(offDays, offDay => {
        if (!_.includes(item.tags, offDay)) {
          item.tags += ',' + offDay
          boDatesApplied = true
        }
      })

      if (boDatesApplied) {
        toast(
          <div>
            <span>Upcoming off days have been applied to this product:</span>
            <ul className="my-1">
              {boDateRanges.map((range, i) => (
                <li key={i}>{range}</li>
              ))}
            </ul>
          </div>
        )
      }
    }


    let body = {
      ...item,
    }

    body.delivery_type = delivery_type
    _.each(item.variants, (_, i) => {
      if (delivery_type === 'd2d' || delivery_type === 'seasonal') {
        body.variants[i].weight = '0'
      }

      if (country === 'MY' && delivery_type === 'courier') {
        body.variants[i].weight = Math.max(0.001, body.variants[i].weight)
      }

      if (country === 'SG' && delivery_type === 'courier' && !shipping_delivery_included[i]) {
        body.variants[i].weight = Math.max(0.1, body.variants[i].weight)
      }

      if (country === 'SG' && delivery_type === 'courier' && shipping_delivery_included[i]) {
        body.variants[i].weight = '0'
      }
    })

    // filter out unwanted variants and options
    if(!has_variants && !item.corporate){
      const { variants, options } = body;

      body = {
        ...body,
        variants: [{
          ...variants[0],
          option1: "Default Title",
          option2: null,
          option3: null,
        }]
      }

      if(options){
        body = {
          ...body,
          options: [{
            name: "Title",
            values: ["Default Title"],
            position: 1,
          }]
        }
      }
    }

    // recalculate selling price
    _.each(body.variants, (variant) => {
      const { base_price, metafields } = variant

      let markup = metafields.findIndex(m => m.key === 'markup')
      let markup_type = metafields.findIndex(m => m.key === 'markup_type')
      variant.metafields[markup].value = defaultMarkup
      variant.metafields[markup_type].value = defaultMarkupType

      if (defaultMarkup > 0 && defaultMarkupType === 'percentage') {
        variant.price = parseFloat(base_price * defaultMarkup / 100) + parseFloat(base_price)
        variant.price = _.ceil(variant.price, 1).toFixed(2)
      } else if (defaultMarkup > 0 && defaultMarkupType === 'fixed') {
        variant.price = parseFloat(defaultMarkup) + parseFloat(base_price)
        variant.price = _.ceil(variant.price, 1).toFixed(2)
      } else {
        variant.price = parseFloat(base_price).toFixed(2)
      }

      delete variant.new
    })

    let filterRe = /^$|^@$/i
    let tags = _.filter(_.uniq(_.split(body.tags, ',')), tag => !(filterRe.test(tag)));
    body.tags = tags

    let bia = _.find(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'bia');;
    });

    let ubia = _.find(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'ubia');;
    });

    let b2b = _.find(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'corporate_order') || _.startsWith(_.lowerCase(tag), 'cgift');
    });

    let bng = _.find(tags, function (tag) {
      return _.lowerCase(tag) === 'bng';;
    });

    let addon = _.find(tags, function (tag) {
      return _.startsWith(_.lowerCase(tag), 'is addon');;
    });

    let giftcard = item.product_type === 'Experiences' || item.vendor.includes('W Kuala Lumpur Hotel')

    if (bia) {
      body.template_suffix = 'door-to-door'
    } else if (ubia) {
      body.template_suffix = 'seasonal'
    } else if (b2b) {
      body.template_suffix = 'bulk'
    } else if (bng) {
      body.template_suffix = 'bng'
    } else if (addon) {
      body.template_suffix = 'addon'
    } else if (giftcard) {
      body.template_suffix = 'giftcard'
    } else {
      body.template_suffix = 'courier'
    }

    if(!tags.includes('logo_halal')){
      body.halal_cert = ''
    }

    body = getDeliveryTags(body)

    delete body.vendor_remarks
    delete body.has_delivery_fine_prints

    return { body, inventoryChanged }
  }

  submit = async e => {
    if (this.toastIdRef.current) {
      toast.dismiss(this.toastIdRef.current);
      this.toastIdRef.current = undefined;
    }
    e.preventDefault();
    const { item, update_variant_image, reorder_media, delete_media } = this.state;
    const { match } = this.props;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    const itemBody = this.getItemBody();
    if(!itemBody){
      return
    }
    let { body, inventoryChanged } = itemBody

    this.setState({
      submitting: true,
      loading: true,
    })

    // api for create or update product
    const edit = item.approval_status !== 'create'
    const toastLoading = toast.loading(edit ? 'Updating...' : 'Creating...');
    let result
    if(edit){
      if (isLiveProduct) {
        delete body.images;
      }

      // update product
      result = await api(`/products/${item.id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }).catch(err => {
        this.setState({
          submitting: false,
          loading: false,
        })
        toast.error(err.message)
      })

      // update media order
      if (reorder_media) {
        const media_order = _.map(item.media, "id")
        await api(`/products/reorder_images/${item.id}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify({ media_order }),
        })
      }

      if (delete_media.length > 0) {
        await api(`/products/delete_media/${item.id}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify({ delete_media }),
        })
      }

      // update variant image
      if(update_variant_image.length > 0){
        for (let v of update_variant_image){
          await api('/products/variant_image', {
            method: 'POST',
            headers: {'Content-Type': 'application/json' },
            body: JSON.stringify(v),
          })
        }
      }

      // update variant inventory
      for (let j = 0; j < inventoryChanged.length; j++){
        const { inventory_item_id, inventory_quantity } = inventoryChanged[j]

        await new Promise(r => setTimeout(r, 2000));
        await api(`/products/inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            inventory_item_id,
            available: inventory_quantity,
            product: result
          })
        })
      }
    } else {
      // create product
      body = {
        ...body,
        approval_status: 'pending'
      }
      result = await api('/products', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
    }

    toast.dismiss(toastLoading);

    if(!result){
      return
    }

    if(result.errors){
      this.setState({
        submitting: false,
        loading: false,
      })
      toast.error(edit ? 'Failed to update product.' : 'Failed to create product.')
    } else {
      this.setState({
        submitting: false,
      })
      if (edit) {
        toast.success('Product updated.');
      } else {
        window.location.href = `/products/pending/${result.productId}`;
        return
      }
      await this.fetchProduct();
      await this.setAttributes();
      this.setState({ loading: false });
    }

  }

  handleOnClickShowImagesModal = i => {
    this.setState({
      assignImageVariant: i
    }, () => {
      this.setState({ showImagesModal: true })
    })
  }

  handleOnCloseImagesModal = () => {
    this.setState({ showImagesModal: false });
  }

  handleOnSaveVariantImage = selected_image => {
    const { item, assignImageVariant: vIndex, update_variant_image } = this.state;
    const { variants } = item;

    if(item.approval_status === ''){
      this.setState({
        update_variant_image: [
          ...update_variant_image,
          {
            variant_id: variants[vIndex].id,
            image_id: selected_image.id,
          }
        ]
      })
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants.slice(0, vIndex),
        {
          ...variants[vIndex],
          selected_image
        },
        ...(variants.slice(vIndex + 1))
      ]
    })

    this.setState({ showImagesModal: false });
  }

  handleOnClickAddOption = () => {
    const { item } = this.state;
    const { options } = item

    this.setItemState({
      ...item,
      options: [
        ...options,
        {
          name: '',
          values: ['']
        }
      ]
    });
  }

  handleOnClickRemoveOption = index => {
    const { item } = this.state;
    const { options, variants } = item;

    const variantsEdited = _.map(variants, variant => {
      variant[`option${index+1}`] = ''

      if(variant.option1 === ''){
        variant = {
          ...variant,
          option1: variant.option2,
          option2: variant.option3,
          option3: ''
        }
      }

      if(variant.option2 === ''){
        variant = {
          ...variant,
          option2: variant.option3,
          option3: ''
        }
      }

      return variant
    })

    this.setItemState({
      ...item,
      options: [
        ...options.slice(0, index),
        ...options.slice(index+1)
      ],
      variants:[
        ...variantsEdited
      ]
    });
  }

  handleOnClickShowApproveModal = () => {
    this.setState({ showApproveModal: true });
  }

  handleOnCloseShowApproveModal = () => {
    this.setState({ showApproveModal: false });
  }

  handleOnClickShowDisapproveModal = () => {
    this.setState({ showDisapproveModal: true });
  }

  handleOnCloseShowDisapproveModal = () => {
    this.setState({ showDisapproveModal: false });
  }

  handleOnClickShowMoreInfoModal = () => {
    this.setState({ showMoreInfoModal: true });
  }

  handleOnCloseShowMoreInfoModal = () => {
    this.setState({ showMoreInfoModal: false });
  }

  handleProductApproval = approve => {
    this.setState({ showDisapproveModal: false, showApproveModal: false, submitting: true });
    const { item: { id, remarks } } = this.state;
    const { match } = this.props;

    const url = `/pending_products/${approve ? 'approve' : 'disapprove'}`
    const body = {
      id,
      ...(remarks && { remarks })
    }

    return api(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(async result => {
      if(result.errors){
        this.setState({
          submitting: false,
          displayProductCreateSuccess: false,
          displayProductCreateFail: true,
          errorMessage: result.errors.base || result.errors
        })
      } else {
        this.setState({
          displayProductCreateFail: false,
          displayProductCreateSuccess: true,
          successMessage: approve ? `Product approved. View the active product page <a href="/products/${result.product.id}">here</a>` : 'Product disapproved.'
        })
      }

      if(!approve){
        let pendingProduct = await api(`/products/${match.params.productId}`)

        if(pendingProduct){
          this.setState({
            item: {
              ...pendingProduct
            },
            has_variants: pendingProduct.variants.length > 1,
            pending: pendingProduct.approval_status == 'pending'
          })
        }
      }
    })
  }

  handleEditorChange = editorState => {
    const { item } = this.state;

    this.setItemState({
      ...item,
      body_html: stateToHTML(editorState.getCurrentContent())
    });
  }

  handleDeliveryFinePrintEditorChange = (editorState, deliveryFinePrintsId) => {
    const { item } = this.state;

    //split and trim tags
    let tagsSplit = item.tags.split(',')
    tagsSplit = _.map(tagsSplit, tag => _.trim(tag))
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }

    //remove all existing tags that start with dfp_
    tagsSplit = _.filter(tagsSplit, tag => !(/^dfp_/i.test(tag)))

    if(deliveryFinePrintsId && deliveryFinePrintsId.length > 0){
      //add new tag
      tagsSplit.push(`dfp_${deliveryFinePrintsId}`)
    }

    item.tags = tagsSplit.join(', ')

    this.setItemState({
      ...item,
      delivery_fine_prints_html: stateToHTML(editorState.getCurrentContent()),
    });
  }

  handleProductMoreInfo = () => {
    this.setState({ showDisapproveModal: false, showApproveModal: false, showMoreInfoModal: false, submitting: true });
    const { item: { id, remarks } } = this.state;
    const { match } = this.props;

    const url = `/pending_products/more_info`
    const body = {
      id,
      ...(remarks && { remarks })
    }

    return api(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(async result => {
      if(result.errors){
        this.setState({
          submitting: false,
        })
        toast.error(result.errors);
      } else {
        toast.success('More info required for this product.');
      }

      let pendingProduct = await api(`/products/${match.params.productId}`)

      if(pendingProduct){
        this.setState({
          item: {
            ...pendingProduct
          },
          has_variants: pendingProduct.variants.length > 1,
          pending: pendingProduct.approval_status == 'pending'
        })
      }
    })
  }

  handleOnRemarkChange = e => {
    const { item } = this.state;
    const { value : remarks } = e.target;

    this.setItemState({
      ...item,
      remarks
    })
  }

  handleOnClickShowReviewModal = () => {
    this.setState({ showReviewModal: true })
  }

  handleOnCloseReviewModal = () => {
    this.setState({ showReviewModal: false })
  }

  handleOnStartReview = async () => {
    const { item, pending } = this.state;
    const { match } = this.props;

    await api(`/pending_products/review`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json' },
      body: JSON.stringify({ id: item.id })
    }).catch(err => {
      this.setState({
        showReviewModal: false,
        displayProductCreateFail: true,
        errorMessage: err.error
      })
    })

    this.setState({
      showReviewModal: false,
    })

    if(pending){
      this.fetchProduct();
    }
  }

  updateAttrTags = async (attr = this.state.attributes) => {

    let { item, initialMetafieldAddOns, initialMetafieldInputs } = this.state;
    const { taggings: { items: predefinedTags } } = this.props;

    const { attributeTags, metafields, metafieldsToRemove } = convertAttr(attr, item, initialMetafieldAddOns, initialMetafieldInputs)
    item.tags = generateTagsString(item, predefinedTags, attributeTags)
    item.metafields = metafields
    item.metafieldsToRemove = metafieldsToRemove

    this.setState({
      attributes: {
        ...this.state.attributes,
        ...attr
      },
    })
    this.setItemState(item)
  }

  addHalalCert = cert => {
    const { item, productTemplateKey } = this.state;
    const certHTML = `<p><br></p>\n<figure><img src=\"${cert}\" height=\"300px\" width=\"auto\"/></figure>\n`
    const { body_html } = item;

    this.setState({
      productTemplateKey: productTemplateKey + 1
    })
    this.setItemState({
      ...item,
      halal_cert: cert,
      body_html: body_html + certHTML
    })
  }

  handleDismissAlert = () => {
    this.setState({
      displayProductCreateSuccess: false,
      displayProductCreateFail: false,
    })
  }

  handleOnClickDeleteProduct = () => {
    this.setState({
      showDeleteModal: true
    })
  }

  handleDeleteProduct = () => {
    const { item: { id } } = this.state;

    return api(`/products/${id}`, {
      method: 'DELETE'
    }).then(result => {
      if(result.errors){
        this.setState({
          submitting: false,
          showDeleteModal: false,
        })
        toast.error('Failed to delete product.');
      } else {
        this.setState({
          showDeleteModal: false,
          not_found: true,
        })
        toast.success('Product deleted.');
      }
    }).catch(err => {
      console.log(err);
    })
  }

  handleOnCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    })
  }

  handleImageReorder = (index, direction) => {
    const { item, productTemplateKey } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      if(index !== 0 && direction === -1){
        const img = media[index];
        media.splice(index, 1);
        media.splice(index-1, 0, img);
      }

      if(index !== media.length - 1 && direction === 1){
        const img = media[index];
        media.splice(index, 1);
        media.splice(index+1, 0, img);
      }

      this.setState({ reorder_media: true });
      this.setItemState({ ...item, media, productTemplateKey: productTemplateKey + 1 });
    } else {
      if(index !== 0 && direction === -1){
        const img = images[index];
        images.splice(index, 1);
        images.splice(index-1, 0, img);
      }

      if(index !== images.length - 1 && direction === 1){
        const img = images[index];
        images.splice(index, 1);
        images.splice(index+1, 0, img);
      }

      this.setItemState({ ...item, images, productTemplateKey: productTemplateKey + 1 });
    }
  }

  handleRemoveImage = index => {
    const { item, productTemplateKey, delete_media } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      let deleted = media.splice(index, 1);
      delete_media.push(deleted[0].id);

      this.setItemState({
        ...item,
        media,
        delete_media,
        productTemplateKey: productTemplateKey + 1
      })
    } else {
      images.splice(index, 1);

      this.setItemState({ ...item,
        images,
        productTemplateKey: productTemplateKey + 1
      })
    }
  }

  handleSellInBulk = () => {
    this.setState({ showConfirmSellInBulkModal: true })
  }

  handleOnCloseSellInBulkModal = () => {
    this.setState({ showConfirmSellInBulkModal: false })
  }

  handleCreateProductB2B = () => {
    const { item: { id, title, handle } } = this.state;

    let body = {
      id,
      title: '[Corporate Gift] ' + title,
      tags: ['cgift', `Link_${handle}`],
      template_suffix: 'bulk',
    }

    return api(`/products/duplicate/bulk`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(async (result) => {
      this.setState({
        showConfirmSellInBulkModal: false,
        displayProductCreateSuccess: true,
        successMessage: `Product created. View it <a href="/products/pending/${result.id}">here</a>`,
      })
      this.setState({ loading: true });
      await this.fetchProduct();
      await this.setAttributes();
      this.setState({ loading: false });
    })
  }

  handleLinkProduct = () => {
    this.setState({ showLinkProductModal: true })
  }

  handleOnCloseLinkProduct = () => {
    this.setState({ showLinkProductModal: false })
  }

  onClickShowLinkedPage = () => {
    const { item: { bulk_link } } = this.state;

    if (bulk_link.includes('Link_')) {

      return api(`/products/get_by_handle/${bulk_link.replace('Link_', '')}`, {
        method: 'GET'
      }, {'Accept': 'application/json'}).then(result => {
        if (!result.id) {
          return
        }

        window.open(`/products/${result.id}`, '_blank');
      })

    } else if (bulk_link.includes('pending_link_')) {

      window.open(`/products/pending/${bulk_link.replace('pending_link_', '')}`, '_blank');

    } else {
      return
    }

  }

  onClickEditVendorRemarks = () => {
    this.setState({ edit_vendor_remarks: this.state.item.vendor_remarks, showVendorRemarksModal: true });
  }

  onClickActivityLog = () => {
    this.setState({ showActivityLogModal: true });
  }

  handleOnCloseVendorRemarksModal = () => {
    this.setState({ showVendorRemarksModal: false });
  }

  handleOnVendorRemarkChange = e => {
    const { item } = this.state;
    const { value : edit_vendor_remarks } = e.target;

    this.setState({
      edit_vendor_remarks
    })
  }

  handleEditVendorRemarks = () => {
    const { item } = this.state;
    const { edit_vendor_remarks } = this.state;

    return api('/merchant/remarks', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ merchantId: item.vendor, remarks: edit_vendor_remarks })
    }).then(() => {
      toast.success('Remarks updated successfully');
      this.setState({ showVendorRemarksModal: false });
      this.fetchMerchants();
    }).catch(() => {
      toast.error('Something went wrong');
    })
  }

  onClickSetUnpublishDate = () => {
    this.setState({ showUnpublishDateModal: true });
  }

  handleOnCloseUnpublishModal = () => {
    this.setState({ showUnpublishDateModal: false });
  }

  handleClearScheduleDraft = () => {
    const { item } = this.state;
    const saveItem = { ...item };

    let tagsSplit = item.tags.split(', ')
    tagsSplit = _.filter(tagsSplit, tag => !(/^unpub_|^$/i.test(tag)))

    saveItem.tags = tagsSplit.join(', ')

    this.setItemState(saveItem)
    this.setState({ showUnpublishDateModal: false });
  }

  handleSaveScheduleDraft = (scheduleDraft, scheduleDraftTime) => {
    const { item } = this.state;
    const saveItem = { ...item };

    let tagsSplit = item.tags.split(', ')
    tagsSplit = _.filter(tagsSplit, tag => !(/^unpub_|^$/i.test(tag)))

    if (moment(scheduleDraft).isValid() && scheduleDraftTime) {
      tagsSplit.push(`UNPUB_${moment(scheduleDraft).format('D-M-YY')}_${scheduleDraftTime}`)
    }

    saveItem.tags = tagsSplit.join(', ')

    this.setItemState(saveItem)
    this.setState({ showUnpublishDateModal: false });
  }

  handleOnSavePickupAddress = () => {
    this.fetchMerchants();
  }

  onClickArchiveProduct = () => {
    this.setState({ showArchiveModal: true });
  }

  handleOnCloseArchiveModal = () => {
    this.setState({ showArchiveModal: false });
  }

  handleArchiveProduct = () => {
    const { item } = this.state

    if (item.status === 'archived') {
      return api(`/products/unarchive/${item.id}`, {
        method: 'POST',
      }).then(async () => {
        toast.success('Product unarchived successfully');
        await this.fetchProduct();
        await this.setAttributes();
      }).catch(() => {
        toast.error('Something went wrong');
      }).finally(() => {
        this.setState({ showArchiveModal: false });
      })
    }

    return api(`/products/archive/${item.id}`, {
      method: 'POST',
    }).then(async () => {
      toast.success('Product archived successfully');
      await this.fetchProduct();
      await this.setAttributes();
    }).catch(() => {
      toast.error('Something went wrong');
    }).finally(() => {
      this.setState({ showArchiveModal: false });
    })
  }

  checkForTitleDecorations = (overwriteTitle, overwriteTags) => {

    const { titleDecorations  } = this.props
    let { item: {title, tags}, prefixText, suffixText} = this.state

    if(overwriteTitle){
      title = overwriteTitle
    }

    if(overwriteTags){
      tags = overwriteTags
    }

    const tagsSplit = tags.split(', ')

    //iterate through tags to find any match in titleDecorations.tag
    let matchedTitleDecorations = titleDecorations.items.filter((titleDecoration) => {
      return tagsSplit.includes(titleDecoration.tag)
    })

    let displayProductTitle = title
    let actualProductTitle = title

    if(prefixText && prefixText.length > 0){
      displayProductTitle = _.replace(displayProductTitle, `${prefixText} `, '')
    }

    if(suffixText && suffixText.length > 0){
      displayProductTitle = _.replace(displayProductTitle, ` ${suffixText}`, '')
    }

    if(matchedTitleDecorations.length > 0){

      //remove all title decorations from the title
      _.each(matchedTitleDecorations, (titleDecoration) => {
        if(titleDecoration.type === 'suffix'){
          displayProductTitle = _.replace(displayProductTitle, ` ${titleDecoration.prefix}`, '')
        } else if(titleDecoration.type === 'prefix'){
          displayProductTitle = _.replace(displayProductTitle, `${titleDecoration.prefix} `, '')
        }
      })

      actualProductTitle = displayProductTitle.trim()
      displayProductTitle = displayProductTitle.trimStart()

      let hasSuffix = false
      let hasPrefix = false

      //add back the title decorations in the correct order
      _.each(matchedTitleDecorations, (titleDecoration) => {
        if(titleDecoration.type === 'suffix' && !hasSuffix){
          suffixText = titleDecoration.prefix
          actualProductTitle = `${actualProductTitle} ${titleDecoration.prefix}`
          hasSuffix = true
        } else if(titleDecoration.type === 'prefix' && !hasPrefix) {
          prefixText = titleDecoration.prefix
          actualProductTitle = `${titleDecoration.prefix} ${actualProductTitle}`
          hasPrefix = true
        }
      })
    } else {
      prefixText = ''
      suffixText = ''
      actualProductTitle = displayProductTitle
    }

    this.setState({
      displayProductTitle,
      actualProductTitle,
      prefixText,
      suffixText,
      matchedTitleDecorations
    })

    return { displayProductTitle, actualProductTitle, prefixText, suffixText, matchedTitleDecorations }
  }

  handleOnCloseActivityLogModal = () => {
    this.setState({ showActivityLogModal: false });
  }

  render() {
    const { user, match, titleDecorations } = this.props;
    const { uploadingFile, has_variants, item, submitting, displayProductCreateFail, displayProductCreateSuccess, 
      showImagesModal, assignImageVariant, merchants, loading, overlayLoading, showApproveModal, showDisapproveModal, 
      showMoreInfoModal, successMessage, showReviewModal, errorMessage, pending, sold_out, showDeleteModal, not_found, 
      attributes, productTemplateKey, showConfirmSellInBulkModal, shipping_delivery_included, merchantsOptions, showLinkProductModal, 
      showVendorRemarksModal, edit_vendor_remarks, showUnpublishDateModal, showSelfPickupVariantInfo, showArchiveModal,
      displayProductTitle, actualProductTitle, prefixText, suffixText, matchedTitleDecorations, showActivityLogModal } = this.state;
    const isAdmin = user.role === 'admin' || user.role === 'staff'
    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create')) || not_found

    const B2CProps = {
      key: productTemplateKey,
      uploadingFile: uploadingFile,
      has_variants: has_variants,
      item: item,
      merchants: merchants,
      merchantsOptions: merchantsOptions,
      pending: pending,
      sold_out: sold_out,
      attributes: attributes,
      match: match,
      closed: closed,
      shipping_delivery_included: shipping_delivery_included,
      isLiveProduct: isLiveProduct,
      displayProductTitle,
      actualProductTitle,
      prefixText,
      suffixText,
      matchedTitleDecorations,
      handleEditorChange: this.handleEditorChange,
      handleDeliveryFinePrintEditorChange: this.handleDeliveryFinePrintEditorChange,
      updateForm: this.updateForm,
      updateVariants: this.updateVariants,
      updateTiers: this.updateTiers,
      updateOptionsAndVariants: this.updateOptionsAndVariants,
      handleOnClickShowReviewModal: this.handleOnClickShowReviewModal,
      onDropImage: this.onDropImage,
      updateImage: this.updateImage,
      handleImageReorder: this.handleImageReorder,
      handleRemoveImage: this.handleRemoveImage,
      addHalalCert: this.addHalalCert,
      updateAttrTags: this.updateAttrTags,
      onClickHasVariants: this.onClickHasVariants,
      handleOnClickAddOption: this.handleOnClickAddOption,
      handleOnClickRemoveOption: this.handleOnClickRemoveOption,
      handleOnClickShowImagesModal: this.handleOnClickShowImagesModal,
      removeVariant: this.removeVariant,
      removeTier: this.removeTier,
      addVariant: this.addVariant,
      addTier: this.addTier,
      handleMarkup: this.handleMarkup,
      handleBasePrice: this.handleBasePrice,
      saveYTLink: this.saveYTLink,
      setLoading: (overlayLoading) => this.setState({ overlayLoading }),
      onSavePickupAddress: this.handleOnSavePickupAddress,
      onClickUnarchive: this.onClickArchiveProduct,
    }

    const B2BProps = {
      key: productTemplateKey,
      uploadingFile: uploadingFile,
      item: item,
      merchants: merchants,
      pending: pending,
      attributes: attributes,
      match: match,
      closed: closed,
      handleEditorChange: this.handleEditorChange,
      handleDeliveryFinePrintEditorChange: this.handleDeliveryFinePrintEditorChange,
      updateForm: this.updateForm,
      handleOnClickShowReviewModal: this.handleOnClickShowReviewModal,
      onDropImage: this.onDropImage,
      handleImageReorder: this.handleImageReorder,
      handleRemoveImage: this.handleRemoveImage,
      addHalalCert: this.addHalalCert,
      updateAttrTags: this.updateAttrTags,
      onClickB2B: this.onClickB2B,
      fixTierQty: this.fixTierQty,
      removeTier: this.removeTier,
      addTier: this.addTier,
    }

    return (
      <div>
        {
          loading &&
          <OverlayLoader />
        }
        {
          overlayLoading &&
          <OverlayLoader />
        }
        {
          !loading &&
          <>
          <TabHeader
            title={
              <>
                {
                  isLiveProduct ?
                    `Product Detail${item.corporate ? ' (B2B Listing)' : ''}`
                  :
                    (
                      item.approval_status == 'create' ?
                        'Create Product'
                      :
                      `Pending Product${item.corporate ? ' (B2B Listing)' : ''}${(item.approval_status == 'approved' && !isNumeric(item.id)) ? ' (Approved)' : ''}`
                    )
                }
              </>
            }
            children={
              <>
                { isAdmin &&
                <Button variant="primary" onClick={this.handleOnPreviewProduct} className="btn-br-6">Preview</Button>
                }
                { isLiveProduct && !item.corporate && !item.bulk_link &&
                  <Button
                    className="btn-br-6"
                    variant="secondary"
                    onClick={this.handleSellInBulk}
                  >Sell in Bulk</Button>
                }
                { item.bulk_link &&
                  <Button
                    className="btn-br-6"
                    variant="secondary"
                    onClick={this.onClickShowLinkedPage}
                  >{item.corporate ? 'Show B2C Page' : 'Show B2B Page'}</Button>
                }
                { isLiveProduct && !item.corporate &&
                  <LinkProductModal
                    item={item}
                    onClose={this.handleOnCloseLinkProduct}
                    deliveryType={attributes.delivery_type}
                    updateForm={this.updateForm}
                  />
                }
                { isAdmin &&
                <Button variant="primary" onClick={this.onClickEditVendorRemarks} className="btn-br-6">Edit Internal Note</Button>
                }
                { isAdmin &&
                <Button variant="primary" onClick={this.onClickActivityLog} className="btn-br-6">Activity Log</Button>
                }
                <Button
                  className="btn-br-6"
                  variant="secondary"
                  onClick={this.onClickSetUnpublishDate}
                >
                  Set Unpublish Date
                  <Badge className="mx-1" bg="danger">NEW</Badge>
                </Button>
              </>
            }
            tab_id="product_detail"
            user={user}
          />
          <div>

        {
          isAdmin && item.admin_prompt &&
            <Alert variant="warning">
              <span>{item.admin_prompt}</span>
            </Alert>
        }
        {
          item.remarks &&
            <Alert variant="warning">
              <span>More Info: {item.remarks}</span>
            </Alert>
        }
        {
          item.vendor_remarks && isAdmin &&
            <Alert variant="info" onClick={this.onClickEditVendorRemarks} className="cursor-pointer">
              <p
                style={{
                  margin: '0',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
                dangerouslySetInnerHTML={{__html: `Internal Note: ${item.vendor_remarks.replaceAll('\n', '<br/>')}`}}
              />
            </Alert>
        }
        {
          displayProductCreateSuccess &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="success" dismissible onClose={this.handleDismissAlert}>
                <span dangerouslySetInnerHTML={{__html: successMessage}}></span>
              </Alert>
            </div>
        }
        {
          displayProductCreateFail &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="danger" dismissible onClose={this.handleDismissAlert}>
                <span>{errorMessage}</span>
              </Alert>
            </div>
        }
        {
          !loading && not_found && !displayProductCreateFail && !displayProductCreateSuccess &&
            <Alert variant="warning">
              <span>Product not found or has been deleted. <Link to={{ pathname: '/products' }} >Back to all products.</Link></span>
            </Alert>
        }
        {
          !loading && !not_found && Object.entries(attributes).length > 0 &&
            <form onSubmit={this.submit} className="d-grid gap-4">
              <ProductB2C {...B2CProps}/>
            <Row className="align-items-center justify-content-center mb-5">
              {
                !(item.approval_status == 'approved' && !isNumeric(item.id)) &&
                  <>
                    {
                      item.approval_status === 'create' &&
                      <Col sm={3}>
                        <Button className="w-100 btn-br-6" variant="primary" disabled={submitting || loading} type="submit">
                          CREATE
                        </Button>
                      </Col>
                    }
                    {
                      isAdmin && (item.approval_status === 'reviewing' || item.approval_status === 'more_info') &&
                        <Row className="my-0 my-md-3 justify-content-center align-items-center">
                          <Col sm={3} className="px-0 px-sm-3 mb-3 mb-md-0">
                            <Button className="w-100 btn-br-6" variant="primary" disabled={submitting || loading} onClick={this.handleOnClickShowApproveModal}>
                              APPROVE
                            </Button>
                          </Col>
                          <Col sm={3} className="px-0 px-sm-3 mb-3 mb-md-0">
                            <Button className="w-100 btn-br-6" variant="danger" disabled={submitting || loading} onClick={this.handleOnClickShowDisapproveModal}>
                              DISAPPROVE
                            </Button>
                          </Col>
                        </Row>
                    }
                      {
                        isAdmin && item.approval_status === 'reviewing' &&
                        <Col sm={3} className="mb-3 mb-sm-0">
                          <Button className="w-100 btn-br-6" variant="warning" disabled={submitting || loading} onClick={this.handleOnClickShowMoreInfoModal}>
                            MORE INFO
                          </Button>
                        </Col>
                      }
                      {
                        !(item.approval_status == 'reviewing' && !isAdmin) && item.approval_status !== 'create' && item.status !== 'archived' &&
                        <Col sm={3} className="mb-3 mb-sm-0">
                          <Button ref={this.updateBtnRef} className="w-100 btn-br-6" variant="info" disabled={submitting || loading} type="submit">
                            UPDATE
                          </Button>
                        </Col>
                      }
                    {
                      item.approval_status == 'reviewing' && !isAdmin &&
                      <Col xs={12}>
                        <Alert variant="warning">Product is currently in review.</Alert>
                      </Col>
                    }
                  </>
              }
              {(item.status === 'draft' || item.status === 'active' || item.status === 'archived') &&
                  <Col sm={3} className="mb-3 mb-sm-0">
                <Button
                  className="btn-br-6 w-100"
                  variant="secondary"
                  onClick={this.onClickArchiveProduct}
                >
                  {item.status === 'archived' ? 'UNARCHIVE' : 'ARCHIVE'}
                </Button>
                </Col>
              }
              {
                ((item.approval_status !== 'create' && isAdmin) || (item.approval_status === 'disapproved' && !isAdmin)) &&
                  <Col sm={3} className="mb-3 mb-sm-0">
                    <Button className="w-100 btn-br-6" variant="danger-secondary" disabled={submitting || loading} onClick={this.handleOnClickDeleteProduct}>DELETE</Button>
                  </Col>
              }
            </Row>
          </form>
        }
        {
          showImagesModal &&
            <ImagesModal images={item.images} variantIndex={assignImageVariant} variants={item.variants} onClose={this.handleOnCloseImagesModal} onClickSave={this.handleOnSaveVariantImage}/>
        }
        {
          showApproveModal &&
            <ConfirmModal onClickYes={e => this.handleProductApproval(true)} onClose={this.handleOnCloseShowApproveModal} title="Approve Product">
              <p>Are you sure you want to <strong>approve</strong> this product?</p>
            </ConfirmModal>
        }
        {
          showDisapproveModal &&
            <ConfirmModal onClickYes={e => this.handleProductApproval(false)} onClose={this.handleOnCloseShowDisapproveModal} title="Disapprove Product">
              <p>Are you sure you want to <strong>disapprove</strong> this product?</p>
              <label htmlFor="remark" style={{fontWeight: 'normal'}}>Remarks</label>
              <textarea className="form-control" id="remark" name="remark" placeholder="Enter remark" value={item.remarks} onChange={this.handleOnRemarkChange} style={{resize: 'vertical'}}/>
            </ConfirmModal>
        }
        {
          showMoreInfoModal &&
            <ConfirmModal onClickYes={e => this.handleProductMoreInfo()} onClose={this.handleOnCloseShowMoreInfoModal} title="More Info Required">
              <p>More info required for this product</p>
              <label htmlFor="remark" style={{fontWeight: 'normal'}}>Remarks</label>
              <textarea className="form-control" id="remark" name="remark" placeholder="Enter remark" value={item.remarks} onChange={this.handleOnRemarkChange} style={{resize: 'vertical'}}/>
            </ConfirmModal>
        }
        {
          showReviewModal &&
            <ConfirmModal onClickYes={this.handleOnStartReview} onClose={this.handleOnCloseReviewModal} title="Review Product">
              <p>Start to review this pending product?</p>
            </ConfirmModal>
        }
        {
          showDeleteModal &&
            <ConfirmModal onClickYes={this.handleDeleteProduct} onClose={this.handleOnCloseDeleteModal} title="Delete Product">
              <p>Are you sure you want to <strong>delete</strong> {item.title}? This cannot be undone.</p>
            </ConfirmModal>
        }
        {
          showConfirmSellInBulkModal &&
            <ConfirmModal onClickYes={this.handleCreateProductB2B} onClose={this.handleOnCloseSellInBulkModal} title="Sell in Bulk">
              <p>Create B2B listing for this product?</p>
            </ConfirmModal>
        }
        {
          showVendorRemarksModal &&
            <ConfirmModal onClickYes={e => this.handleEditVendorRemarks()} onClose={this.handleOnCloseVendorRemarksModal} title={`Edit Internal Note for ${item.vendor}`} yesBtnTitle="Save" noBtnTitle="Cancel" size="lg">
              <label htmlFor="remark" style={{fontWeight: 'normal'}}>Internal Note</label>
              <textarea className="form-control" id="remark" name="remark" placeholder="Enter remark" value={edit_vendor_remarks} onChange={this.handleOnVendorRemarkChange} style={{resize: 'vertical', height: '200px'}}/>
            </ConfirmModal>
        }
        {
          showUnpublishDateModal &&
          <SetUnpublishModal onClose={this.handleOnCloseUnpublishModal} clearSchedule={this.handleClearScheduleDraft} tags={item.tags} save={this.handleSaveScheduleDraft}/>
        }
        {
          showActivityLogModal &&
          <ActivityLogModal onClose={this.handleOnCloseActivityLogModal} productId={item.id} logType="product"/>
        }
        { showArchiveModal &&
            <ConfirmModal onClickYes={this.handleArchiveProduct} onClose={this.handleOnCloseArchiveModal} title={item.status === 'archived' ? 'Unarchive Product' : 'Archive Product'}>
              { item.status === 'archived' ?
              "Unarchiving this product will change its status to draft so it will be visible on your product list again."
              :
              "This product will be hidden from your product list. You may still find them on the Archived Products page."
              }
            </ConfirmModal>
        }
      </div>
      </>
      }
    </div>
    )
  }
}

const closed = {
  mon: 'CLOSED-1',
  tue: 'CLOSED-2',
  wed: 'CLOSED-3',
  thu: 'CLOSED-4',
  fri: 'CLOSED-5',
  sat: 'CLOSED-6',
}

const dayOptions = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
]

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user,
    taggings: state.taggings,
    previewProduct: state.previewProduct,
    titleDecorations: state.titleDecorations
  };
}, {
  ...errorActionCreators,
  ...merchantsActionCreators,
  ...taggingsActionCreators,
  ...previewProductActionCreators,
  ...titleDecorationsActionCreators
})(ProductCreate);

